<template>
   <div>
      <v-row :justify="$store.state.sidebarIsOpen ? 'center' : 'center'" align="center" class="mt-5">
         <v-col cols="auto">
            <router-link to="/">
               <img v-if="!$store.state.sidebarIsOpen" src="@/assets/logo.png" style="max-height: 70px;"
                  class="d-block ma-auto" alt="amwaj-POS" />
               <img v-else src="@/assets/logo_icon.png" style="max-height: 70px;" class="d-block ma-auto mt-2"
                  alt="amwaj-POS" />
            </router-link>
         </v-col>
         <v-col cols="auto" class="pa-5 pb-0 mt-2">
            <img src="@/assets/img/svg/menu_close.svg" v-if="$store.state.sidebarIsOpen" height="40" alt=""
               @click.stop="toggleSidebar" :class="$vuetify.rtl == true ? '' : 'rotate-180'" />
            <img src="@/assets/img/svg/menu.svg" v-if="!$store.state.sidebarIsOpen" height="40" alt=""
               @click.stop="toggleSidebar" :class="$vuetify.rtl == true ? '' : 'rotate-180'" />
         </v-col>

      </v-row>
      <v-list dense class="mt-3 px-3">
         <!-- List Level 1 link -->
         <!-- Dashboard -->
         <!-- <span class="d-block text-center">
                        <v-list-item active-class="activeItem" link to="/" color="primary" class="my-1 screen_sub">
                           <v-list-item-icon>
                              <img src="@/assets/img/svg/dachborad.png" alt=""
                                 :class="$store.state.sidebarIsOpen ? '' : 'mx-n2'" />
                           </v-list-item-icon>
                           <v-list-item-title class="text-start">{{ $t('home') | capitalize }}</v-list-item-title>
                        </v-list-item>
                     </span> -->

         <span v-for="(categories, y) in menuList" :key="y" class="d-block my-0 text-center">


            <v-list-item v-if="categories.cats.length == 0" link :to="categories.url" class="px-6">
               <img v-if="$store.state.activeScreenCode == categories.screen_code"
                  src="@/assets/img/svg/screen_icon_active.svg" alt="">
               <img v-else src="@/assets/img/svg/screen_icon.svg" alt="">
               <v-list-item-title class="text-start"
                  :class="$store.state.activeScreenCode == categories.screen_code ? 'blue3--text' : 'primary--text'">
                  {{ $t(categories.title) | capitalize }}</v-list-item-title>
            </v-list-item>


            <div v-for="(cats, x) in categories.cats" :key="x">
               <!-- List Level 2 group-->
               <div>
                  <v-list-group color="primary" active-class="activeLink" v-if="cats.subs.length >= 1"
                     :value="$store.state.activeScreen.cat_id == cats.id">
                     <template v-slot:activator>
                        <img src="@/assets/img/svg/screen_icon.svg" class="mx-2" alt="">
                        <v-list-item-title>{{ cats.title | capitalize }}</v-list-item-title>
                        <v-icon size="22">mdi-chevron-down</v-icon>
                     </template>

                     <!-- List Level 3 link -->
                     <span v-for="(subs, z) in cats.subs" :key="z" class="d-block my-1">
                        <v-list-item :class="$store.state.activeScreenCode == subs.screen_code ? 'activeLink' : ''"
                           v-if="subs" link :to="subs.url">
                           <img v-if="$store.state.activeScreenCode == subs.screen_code"
                              src="@/assets/img/svg/screen_icon_active.svg" class="mx-2" alt="">
                           <img v-else src="@/assets/img/svg/screen_icon.svg" class="mx-2" alt="">
                           <v-list-item-title
                              :class="$store.state.activeScreenCode == subs.screen_code ? 'blue3--text' : 'primary--text'">
                              {{ subs.sub_title | capitalize }}</v-list-item-title>
                        </v-list-item>
                     </span>

                  </v-list-group>
               </div>
            </div>



         </span>
         <!-- <v-list-item link :to="`/settings_hr`" class="px-6">
            <img v-if="$store.state.activeScreenCode == ''" src="@/assets/img/svg/screen_icon_active.svg" alt="">
            <img v-else src="@/assets/img/svg/screen_icon.svg" alt="">
            <v-list-item-title class="text-start"
               :class="$store.state.activeScreenCode == '' ? 'blue3--text' : 'primary--text'">
               {{ $t('settings') | capitalize }}</v-list-item-title>
         </v-list-item> -->
      </v-list>
      <!-- User  -->
      <v-bottom-navigation absolute height="70" color="white" hide-on-scroll horizontal>
         <v-col cols="12" class="pa-0 py-2 position-relative">
            <v-row justify="center" align="center">
               <v-col cols="auto">
                  <v-avatar size="50" class="rounded-circle userAvatar" v-lazy-container="{ selector: 'img' }">
                     <img :data-src="$store.state.endpointURL + $store.state.userData.image" class="rounded-circle d-block"
                        alt="">
                  </v-avatar>
               </v-col>
               <v-col cols="auto" v-if="!$store.state.sidebarIsOpen">
                  <div class="subtitle-1 gray5--text font-weight-bold">{{ $store.state.userData.full_name || '-' |
                     capitalize }}</div>
                  <div class="subtitle-2  gray8--text">{{ $store.state.userData.title | capitalize }}</div>
               </v-col>
            </v-row>
         </v-col>
      </v-bottom-navigation>
      <!-- End User  -->
   </div>
</template>

<script>
export default {
   name: "Sidebar",
   mounted() {
      this.menuList = {
         0: {
            cats: [],
            id: 0,
            image: 0,
            title: 'home',
            screen_code: '00-000',
            url: '/'
         }
      }
      this.menuList = { ...this.menuList, ...this.$store.state.screens };
      console.log(this.menuList);
   },
   data: () => ({
      menuList: []
   }),
   methods: {
      toggleSidebar() {
         this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
      },
   }
};
</script>

<style scoped lang="scss">
.activeLink {
   opacity: 1 !important;
   font-weight: 500 !important;

   color: #212121;

   img:first-of-type {
      display: none;
   }

   img:last-of-type {
      display: block;
   }
}

span {
   img {
      width: 20px;
      height: 20px;
   }

   // img:last-of-type {
   //    display: none;
   // }
}

.v-list-item,
.v-list-item--link,
.v-list-item--active {
   // border-radius: 10px !important;
}

.theme--light .activeLink {
   background: transparent !important;
   color: #143e72;

   .v-list-item__title {
      font-weight: bold !important;
   }
}

.theme--light .screen_sub {
   color: #5896e1;
}

.theme--light.v-application--is-rtl .activeLink {
   background: transparent !important;
   color: #143e72;

   .v-list-item__title {
      font-weight: bold !important;
   }
}

.theme--dark .activeLink {
   border-left: 4px solid #66b4e3;
   border-right: 0px solid #66b4e3;
}

.theme--dark.v-application--is-rtl .activeLink {
   border-left: 0 solid #66b4e3;
   border-right: 4px solid #66b4e3;
   color: #66b4e3;
}

.v-list-item img {
   // filter: grayscale(100%) !important;
}

.v-list-item--active img {
   // filter: grayscale(0%) !important;
}

.v-application--is-ltr .v-list-item__icon:first-child {
   margin-right: 12px !important;
}

.v-application--is-ltr .v-navigation-drawer--mini-variant .v-list-item__icon:first-child {
   margin-right: 0 !important;
}

.v-list-group .v-list-item__title {
   text-align: initial;
   font-size: 12px;
}

.v-list-group .v-list-item--link {
   text-align: initial;
   padding: 0 2.5rem;
}

.v-list-item--link {
   //opacity: 0.7;
}

.v-application--is-rtl .v-navigation-drawer--mini-variant .v-list-item__icon:first-child {
   margin-right: 0 !important;
}

.v-application--is-rtl .v-list-item__icon:first-child {
   margin-left: 0;
   margin-right: 0;
}

.v-divider {
   opacity: 0.65 !important;
   width: 200px !important;
}

.theme--light {
   .v-list-group--active {
      background: #e6ecff !important;
      border-radius: 9px !important;
   }
}

.theme--dark {
   .v-list-item--link {
      opacity: 1;
   }

   .v-list-group--active {
      background: #191919 !important;
      border-radius: 9px !important;

      .v-list-item__title {
         color: #fff !important
      }
   }

   .activeItem .v-list-item__title {
      color: #fff !important
   }
}

.theme--dark {
   .activeItem {
      background: #191919 !important;
      color: #191919 !important
   }
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
   opacity: 0;
}

.v-list-group--sub-group .v-list-group__header.v-list-item--active .v-list-item__title {
   font-weight: bold;
   color: #143e72 !important;
}


.v-list-group--sub-group .v-list-group__header.v-list-item--active .v-icon {
   color: #143e72 !important;
   transform: rotate(180deg);
}

.activeItem {
   background: #e6ecff !important;
   border-radius: 9px !important;

   .v-list-item__title {
      font-weight: bold !important;
      color: #334D6E !important;
   }
}

.v-list-item__title {
   font-weight: bold !important;
}

.userAvatar {
   img {
      padding: 3px;
   }
}
</style>