<template>
   <section>
      <v-container fluid>

         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="validateSubmit > 0 ? true : false"
            :deleteMethod="deleteMethod" createBtnText='cashing' :viewForm="() => viewForm = true">
         </ControlHeader>
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="py-3 mt-6">
            <v-expansion-panels v-model="panel" multiple>
               <v-expansion-panel class="blue11 rounded-xl">
                  <v-expansion-panel-header expand-icon="mdi-menu-down" class="text-left">
                     <span>
                        <!-- <PrintBtn btnText="" :backValueMethod="(data) => downloadFiles(data)" :image="false"
                           classes="transparent" /> -->


                        <ExportBtnCircle />
                     </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                     <v-card class="overflow-hidden pa-5 transparent" elevation="0">
                        <v-row class="align-center">
                           <GenericInput type="date" :value="form.movement_history" @input="form.movement_history = $event"
                              paddingY="py-6" label="movement history" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 2]">
                           </GenericInput>

                           <!-- Date -->
                           <v-col cols="12" sm="6" lg="2">
                              <v-menu ref="menuDate" v-model="menuDate" :close-on-content-click="false"
                                 transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                 <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="form.accounting_month" background-color="white" dense outlined
                                       :label="$t('accounting month')" :rules="[rules.required]" append-icon="mdi-calendar"
                                       readonly :loading="pageData.editIsLoading" v-bind="attrs" v-on="on"></v-text-field>
                                 </template>

                                 <v-date-picker v-model="form.accounting_month" :locale="$vuetify.rtl ? 'ar' : 'en'"
                                    type="month" title scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="menuDate = false">
                                       Cancel
                                    </v-btn>
                                    <v-btn text color="primary" @click="$refs.menuDate.save()">
                                       OK
                                    </v-btn>
                                 </v-date-picker>
                              </v-menu>
                           </v-col>
                           <!-- Date -->

                           <GenericInput type="textarea" :required="false" :value="form.notes" @input="form.notes = $event"
                              label="description of the movement" :rowsNumber="4" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 6]">
                           </GenericInput>


                           <v-col cols="12" class="py-3">
                              <v-divider class="blue10"></v-divider>
                           </v-col>

                           <v-col cols="12">
                              <p>{{ $t("filter") }}</p>
                           </v-col>

                           <GenericInput type="select" :lookups="filterCompany" :value="company_id"
                              @input="company_id = $event" icon="mdi-chevron-down" selected_prop="id" label="company"
                              :clearable="true" selected_label="name" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 3]">
                           </GenericInput>

                           <GenericInput type="select" :lookups="filterManagement" :value="management_id" :clearable="true"
                              @input="management_id = $event" icon="mdi-chevron-down" selected_prop="id"
                              label="the management" selected_label="name" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 2]">
                           </GenericInput>

                           <GenericInput type="select" :lookups="filterDepartment" :value="department_id" :clearable="true"
                              @input="department_id = $event" icon="mdi-chevron-down" selected_prop="id" label="department"
                              selected_label="name" :isLoading="pageData.editIsLoading" :cols="[12, 6, 2]">
                           </GenericInput>

                           <GenericInput type="select" :lookups="filterEmployees" :value="employee_id" :clearable="true"
                              @input="employee_id = $event" label="choose the employee" selected_prop="id"
                              selected_label="employee_name" :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]">
                              <template v-slot:append>
                                 <v-icon>mdi-magnify</v-icon>
                              </template>
                           </GenericInput>

                           <v-col cols="12" lg="auto">
                              <v-btn @click="search" :disabled="form.accounting_month === null || btnSearchValidation"
                                 height="34" color="primary" class="white--text" elevation="0"
                                 :loading="pageData.isLoading">
                                 {{ $t("search") }}
                              </v-btn>
                           </v-col>
                        </v-row>
                     </v-card>
                  </v-expansion-panel-content>
               </v-expansion-panel>
            </v-expansion-panels>
         </v-form>
         <v-row>
            <v-col cols="12" sm="12">
               <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
                  :option="pageData.options" :pageData="pageData" :checkbox="true" :selectRow="true"
                  :checkboxMethod="checkboxMethod" :groupHeader="groupHeader" :showGroupHeader="true">
                  <template v-slot:td="{ row, header }">
                     <div v-if="header.key === 'payment_method'">
                        {{ $global.FilterArrayOfObjectElement(payment_method, "id", row.payment_method, "name") }}
                     </div>
                     <div v-if="header.key === 'total_deduction_actions'">
                        <div :class="{ 'error--text': row.total_deduction_actions > 0 }">
                           <span v-if="row.total_deduction_actions > 0">
                              {{ '-' }}
                           </span>
                           {{ row.total_deduction_actions | float }}
                        </div>
                     </div>
                     <div v-if="header.key === 'total_benefit_actions'">
                        <div :class="{ 'success--text': row.total_benefit_actions > 0 }">
                           <span v-if="row.total_benefit_actions > 0">
                              {{ '+' }}
                           </span>
                           {{ row.total_benefit_actions | float }}
                        </div>
                     </div>
                  </template>
               </DynamicTable>
            </v-col>
         </v-row>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import DynamicTable from "@/components/DynamicTable.vue";
// import PrintBtn from "@/components/ui/PrintBtn.vue";
import ExportBtnCircle from "@/components/ui/ExportBtnCircle.vue";

export default {
   name: "SalaryCalculationControl",

   data: () => ({
      pageData: {
         screen_code: "09-019",
         url: null,
         rows: [],
         tableHeader: [],
         options: {},
         controlRoute: "/transactions/salary_calculator_transaction-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
         queryParam: '?',
      },
      panel: [0, 1],
      viewForm: false,
      summery: [],
      valid: false,
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      menuDate: false,
      modal: false,
      tab: 0,
      company: [],
      company_id: "",
      management: [],
      management_id: "",
      department: [],
      department_id: "",
      employees: [],
      employee_id: "",
      openDialog: false,
      groupHeader: [],
      checkbox: [],
      payment_method: [],
      validateSubmit: 0,
      form: {
         movement_history: null,
         acount_master_year: null,
         accounting_month: null,
         notes: null,
         details: []
      },
      rules: {
         required: value => !!value || 'Required.',
      },
   }),
   components: {
      GenericInput,
      ControlHeader,
      DynamicTable,
      // PrintBtn,
      ExportBtnCircle
   },
   mounted() {
      this.queryParams();
      this.pageMainData();
      this.form.movement_history = this.$global.GetCurrentDate()
      this.getData();
   },
   computed: {
      btnSearchValidation() {
         if (this.company_id !== '' || this.management_id !== '' || this.department_id !== '' || this.employee_id !== '') {
            return false
         } else {
            return true
         }
      },
      filterCompany() {
         if (this.management_id) {
            return this.company.filter((company) =>
               this.management.filter(management => management.id === this.management_id)
                  .some(management => management.company_id === company.id));
         } else {
            return this.company;
         }
      },
      filterManagement() {
         if (this.company_id) {
            return this.management.filter(management =>
               this.company.filter(company => company.id === this.company_id)
                  .some(company => company.id === management.company_id));
         } else if (this.department_id) {
            return this.management.filter(management =>
               this.department.filter(department => department.id === this.department_id)
                  .some(department => department.management_id === management.id));
         } else {
            return this.management
         }

      },
      filterDepartment() {
         if (this.management_id) {
            return this.department.filter(department =>
               this.management.filter(management => management.id === this.management_id)
                  .some(management => management.id === department.management_id));
         } else {
            return this.department
         }
      },
      filterEmployees() {
         if (this.department_id) {
            return this.employees.filter(employees =>
               this.department.filter(department => department.id === this.department_id)
                  .some(department => department.id === employees.department_id))
         } else {
            return this.employees
         }
      },
   },
   watch: {
      'pageData.isEdit'() {
         if (this.pageData.isEdit) {
            this.pageData.tableHeader = [
               { text: "#", key: "id", type: 'text', classes: "" },
               { text: "employee name", key: "employee_name", type: 'text', classes: "" },
               { text: "basic salary", key: "salary_total", type: 'float', classes: "" },
               { text: "the number of days", key: "days_count", type: 'text', classes: "" },
               { text: "total deductions", key: "total_deduction_actions", type: 'slot', classes: "" },
               { text: "total benefits", key: "total_benefit_actions", type: 'slot', classes: "" },
               { text: "the total amount", key: "total_amount", type: 'float', classes: "" },
               { text: "exchange type", key: "payment_method", type: 'slot', classes: "" },
            ]
         }
      },
      $route() {
         this.queryParams();
         this.pageMainData();
         this.getData();
      },
   },
   methods: {
      queryParams() {
         this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
         this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
         this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
         this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
         this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
      },
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).main_title;
         this.pageData.category = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).cat_title;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.tableHeader = [
            { text: "employee serial number", key: "employee_id", type: 'text', classes: "" },
            { text: "employee name", key: "employee_name", type: 'text', classes: "" },
            { text: "workdays", key: "salary_total", type: 'float', classes: "" },
            { text: "the basic", key: "days_count", type: 'text', classes: "" },
            { text: "بدل سكن", key: "days_count", type: 'text', classes: "" },
            { text: "بدل انتقال", key: "total_deduction_actions", type: 'slot', classes: "" },
            { text: "بدل هاتف", key: "total_benefit_actions", type: 'slot', classes: "" },
            { text: "بدل اضافي", key: "total_amount", type: 'float', classes: "" },
            { text: "بدلات اخرى", key: "payment_method", type: 'slot', classes: "" },
            { text: "total additions", key: "payment_method", type: 'text', classes: "" },
            { text: "سلفة", key: "payment_method", type: 'text', classes: "" },
            { text: "استقطاع", key: "payment_method", type: 'text', classes: "" },
            { text: "Total deductions", key: "payment_method", type: 'text', classes: "" },
            { text: "the days", key: "payment_method", type: 'text', classes: "" },
            { text: "Total Deductions", key: "payment_method", type: 'text', classes: "" },
            { text: "the total", key: "payment_method", type: 'text', classes: "" },
            { text: "exchange type", key: "payment_method", type: 'text', classes: "" },

         ]
         this.groupHeader = [
            { text: " ", colspan: 5, classes: "" },
            { text: "additions", colspan: 5, type: 'text', classes: "blue11", background: '#ccd8ff' },
            { text: "", colspan: 1, type: 'text', classes: "yellow11", background: '#fff8dd' },
            { text: "Deductions", colspan: 2, type: 'text', classes: "green11", background: '#d2fbf0' },
            { text: "", colspan: 1, type: 'text', classes: "gray11", background: '#e8e8ea' },
            { text: "absence", colspan: 2, type: 'text', classes: "green4", background: '#35ebbd' },

         ];
         this.pageData.options = {
            print: this.$global.CheckAction(this.pageData.screen_code, 5),
            delete: this.$global.CheckAction(this.pageData.screen_code, 4),
            edit: this.$global.CheckAction(this.pageData.screen_code, 3),
            restore: this.$global.CheckAction(this.pageData.screen_code, 4),
            view: this.$global.CheckAction(this.pageData.screen_code, 1),
            switch: false,
            selectRow: false,
            sortBy: "id",
            tableHeader: false,
            searchInput: false,
            printButton: false,
            handleClickRow: false,
         };
      },
      getSearch(word) {
         this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
         this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`SalaryCaculator/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.company = response.data.company
                     this.form = response.data.data
                     this.form.details = response.data.details
                     this.pageData.rows = response.data.details;

                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`SalaryCaculator/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.company = response.data.company
                     this.management = response.data.managment
                     this.department = response.data.department
                     this.employees = response.data.employees
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         this.payment_method = [
            {
               id: 1,
               name: this.$i18n.t('Bank')
            },
            {
               id: 2,
               name: this.$i18n.t('cash')
            },
            {
               id: 3,
               name: this.$i18n.t('transfer')
            }
         ]


      },
      changePage(page, limit) {
         this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
         this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
      },
      deleteMethod(status) {
         this.deleteDialog = false;
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`SalaryCaculator/${this.form.id}`, { _method: "delete" }).then((response) => {
               if (response.check) {
                  this.pageData.isLoading = false
                  this.$router.push(this.pageData.url);
               }
            });
         }
      },
      restoreMethod(row) {
         this.$api.POST_METHOD(`SalaryCaculator_toggle_active/${row.id}`, null).then((response) => {
            if (response.check) {
               this.getData();
            }
         });
      },
      setClickRow() {
         // this.$router.push(`/main/SalaryCaculator/${row.id}`)
      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`SalaryCaculator/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`SalaryCaculator`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      search() {
         this.pageData.isLoading = true;
         this.$api.GET_METHOD(`all_salary_caculator_d?company=${this.company_id || ''}&accounting_month=${this.form.accounting_month || ''}&management=${this.management_id || ''}&department=${this.department_id || ''}&employee=${this.employee_id || ''}`).then((response) => {
            this.pageData.entityName =
               this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
            this.pageData.isLoading = false;
            this.pageData.rows = response.data.items;
            this.pageData.page = response.data.items.current_page;
            this.pageData.pagination = {
               page: response.data.items.current_page,
               totalPages: response.data.items.last_page,
               per_page: response.data.items.per_page,
               totalRows: response.data.items.total,
            };

            // will be romve
            this.pageData.rows = [
               {
                  id: 1,
                  employee_id: 1,
                  employee_name: this.$global.FilterArrayOfObjectElement(this.filterEmployees, "id", this.employee_id, 'employee_name') || "Employee 1",
                  salary_total: 0,
                  days_count: 0,
                  total_deduction_actions: 0,
                  total_benefit_actions: 0,
                  total_amount: 0,
                  payment_method: "0",
               }
            ]
         })


      },
      edit(row) {
         console.log("edit", row);
         this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
      },
      checkboxMethod(row) {
         console.log("row", row);
         this.validateSubmit = row.length
         this.form.details = [...row]
      },
      downloadFiles(param) {
         // this.printLoading = true;
         // const src = `${this.downloadResource}/${this.data[this.dataKey]}`;
         // const download_file_name = `${this.$i18n.t('attach')}_${this.$i18n.t(this.entityName)}_${this.data[this.dataKey]}.${this.data.attach_ext}`
         // this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
         //     this.printLoading = false;
         // })
         console.log(param);
         // this.$api.DOWNLOAD_METHOD_GET(`download_employee_document/${this.$route.params.id}`, { type: fileName }).then(() => {
         //    this.printLoading = false;
         // })
      },
      getDateMonth() {
         const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
         const d = new Date();
         let month = d.getMonth()
         console.log(months[d.getMonth()]);
         if (this.$vuetify.rtl) {
            return (month + 1) + "." + this.$i18n.t(months[d.getMonth()]);
         } else {
            return this.$i18n.t(months[d.getMonth()]) + "." + (month + 1);
         }
      }
   },

};
</script>
<style lang="scss" scoped></style>
