<template>
   <v-row justify="center">
      <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="370">
         <v-card relative :key="modalKey">
            <v-btn absolute class="ma-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
               color="grey lighten-1" text @click="closeDialog">
               <v-icon color="grey lighten-1">mdi-close</v-icon>
            </v-btn>


            <v-form ref="newItem" v-model="valid" class="pa-7">
               <v-row align="center">
                  <v-col cols="12">
                     <div class="text-h6 font-weight-bold">
                        {{ selectedNode.level == 1 ? $t('add management') : $t('add department') }}
                     </div>
                  </v-col>
                  
                  <GenericInput type="text" :value="newItem.name_ar" @input="newItem.name_ar = $event" label="name_ar"
                     :required="true" :isLoading="false" :cols="[12, 12, 12]"></GenericInput>

                  <GenericInput type="text" :value="newItem.name_en" @input="newItem.name_en = $event"
                     label="English name" :required="true" :isLoading="false" :cols="[12, 12, 12]"></GenericInput>


                  <v-col cols="12" class="d-flex justify-space-between">
                     <v-btn class="mx-2" text depressed color="red4" @click="closeDialog" :height="37" :width="120">
                        {{ $t('close') }}
                     </v-btn>
                     <v-btn class="mx-2 white--text" @click="save" depressed :loading="isLoading" color="success"
                        :disabled="!valid" :height="37" :width="120">
                        {{ $t('create') }}
                     </v-btn>
                  </v-col>

               </v-row>
            </v-form>

         </v-card>
      </v-dialog>
   </v-row>
</template>

<script>
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "AddStructureNode",
   props: {
      backValueMethod: { type: Function },
      dialog: { default: false },
      company_id: { default: null },
      employees: { default: [] },
      selectedNode: { default: Object },

   },
   computed: {

   },
   components: {
      GenericInput
   },
   watch: {

   },
   data: () => ({
      isLoading: false,
      valid: false,
      modalKey: 0,
      manager_id: null,
      newItem: {
         name_ar: null,
         name_en: null,
         company_id: null,
         manager_id: null,
         management_id: null,
      }
   }),
   methods: {
      closeDialog() {
         this.backValueMethod(false)
         this.modalKey = this.modalKey + 1;
         this.newItem = {
            name_ar: null,
            name_en: null,
            company_id: null,
            manager_id: null,
            management_id: null,
         }
      },

      save() {
         this.isLoading = true;
         switch (this.selectedNode.level) {
            case 1:
               this.newItem.company_id = this.company_id;
               // this.newItem.manager_id = this.manager_id;
               this.$api.POST_METHOD(`management`, this.newItem).then((response) => {
                  this.isLoading = false;
                  if (response.check) {
                     this.backValueMethod(true)
                     this.modalKey = this.modalKey + 1;
                     this.newItem = {
                        name_ar: null,
                        name_en: null,
                        company_id: null,
                        manager_id: null,
                        management_id: null,
                     }
                  }
               })
               break;
            case 2:
               this.newItem.company_id = this.company_id;
               this.newItem.manager_id = this.manager_id;
               this.newItem.management_id = this.selectedNode.id;
               this.$api.POST_METHOD(`department`, this.newItem).then((response) => {
                  this.isLoading = false;
                  if (response.check) {
                     this.backValueMethod(true)
                     this.modalKey = this.modalKey + 1;
                     this.newItem = {
                        name_ar: null,
                        name_en: null,
                        company_id: null,
                        manager_id: null,
                        management_id: null,
                     }
                  }
               })
               break;

            default:
               break;
         }

      },

   },
   mounted() {

   },
};
</script>
