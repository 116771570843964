<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid && validationMassage"
        :deleteMethod="deleteMethod" :editForm="pageData.isEdit && !viewForm" :viewForm="() => viewForm = true" />
      <!-- Header -->
      <!-- End Of Header -->
      <!-- Summery -->
      <ControlSummery :summery="summery" :loading="pageData.isLoading" v-if="pageData.isEdit && !viewForm">
      </ControlSummery>
      <!-- End of Summery -->
      <v-form ref="form" v-model="valid" class="pa-3" v-if="viewForm">
        <v-row class="my-5 text-capitalize">
          <v-col cols="12" sm="12">
            <h2 class="mb-5">{{ $t("vacation system") }}</h2>

            <v-row class="mb-5 align-center">
              <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]"></GenericInput>

              <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en"
                :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]"></GenericInput>

              <GenericInput type="number" :value="form.apply_months" @input="form.apply_months = $event"
                label="apply after months" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
              </GenericInput>

              <GenericInput type="select" :value="form.vacation_type" @input="form.vacation_type = $event"
                selected_prop="id" icon="mdi-chevron-down" label="vacation type" :lookups="vacation_type"
                selected_label="name" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
              </GenericInput>

              <GenericInput type="number" :value="Math.abs(form.total_vacation)" @input="form.total_vacation = $event"
                label="total vacation" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
              </GenericInput>

              <GenericInput type="switch" :value="form.transfer_vacation" @input="form.transfer_vacation = $event"
                label="transfer vacation" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
              </GenericInput>
            </v-row>

          </v-col>
          <v-col cols="12" sm="12">
            <v-divider></v-divider>
          </v-col>

          <v-scroll-x-reverse-transition>
            <v-col cols="12" v-if="form.total_vacation !== null && !validationMassage">
              <v-alert dense outlined type="warning">
                <strong>{{ $t("vacation_alert") }} {{ form.total_vacation }} {{ $t("day") }}</strong>
              </v-alert>
            </v-col>
          </v-scroll-x-reverse-transition>

          <v-col cols="12" sm="12">
            <header class="d-flex justify-space-between">
              <div class="d-flex align-center">
                <h2>{{ $t("vacation details") }}</h2>
                <v-col cols="auto" class="text-subtitle-1">
                  <span class="font-weight-bold">{{ $t("total vacation") }}</span>
                  <span>
                    {{ form.total_vacation || 0 }} {{ $t("day") }}
                    <strong class="mx-2">-</strong>
                  </span>
                  <span class="font-weight-bold">{{ $t("the rest") }}</span>
                  <span>
                    {{ calcRemainingVacation }} {{ $t("day") }}
                  </span>
                </v-col>

              </div>
              <v-btn class="primary--text gray12" @click="openDialog = true, addNewItem = true" text color="primary"
                elevation="0" :disabled="calcRemainingVacation == 0">
                {{ $t("add vacation") }}
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </header>

            <v-row class=" text-center mt-5">
              <v-col cols="12" md="auto" class="vacation_col" v-for="(vacation, i) in form.vacation_system_d" :key="i">
                <v-hover v-slot="{ hover }">
                  <v-card color="yellow10" elevation="0" class="rounded-lg pa-2 d-flex justify-center flex-column"
                    :class="{ 'on-hover primary--border shadow-md': hover }" width="280" height="120">
                    <!-- <h4 class="mb-3"></h4> -->
                    <div>
                      <!-- <p class="mb-3 font-weight-bold">{{ vacation.name }}</p> -->
                      <p class="mb-3 font-weight-bold">{{ $vuetify.rtl ? vacation.name_ar : vacation.name_en }}</p>
                      <span>{{ vacation.days_count }} {{ $t('day') }}</span>
                    </div>
                    <v-row justify="center" align="center" class="controls" v-if="hover">
                      <v-col cols="auto" class="py-0">
                        <v-btn icon small class="primary white--text" @click="update_vacation_details(vacation, i)">
                          <v-icon size="18">mdi-pencil</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="auto" class="py-0">
                        <v-btn icon small class="error white--text" @click="handelDelete(i)">
                          <v-icon size="18">mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
      <AddAnnualDialog :openDialog="openDialog"
        :closeDialog="() => (openDialog = false, addNewItem = false, dilaogIsEdit = false)"
        :vacation_details="form.vacation_system_d" :pageData_isEdit="pageData.isEdit"
        :update_details_data="update_details_data" :update_details_index="update_details_index"
        :after_update="after_update" :addNewItem="addNewItem" :calcRemainingVacation="calcRemainingVacation"
        :total_vacation="form.total_vacation" :DilaogIsEdit="dilaogIsEdit" :allVacationExceptOne="allVacationExceptOne" />
    </v-container>
  </section>
</template>

<script>
// import EntityHeader from "@/components/ui/EntityHeader.vue";
import ControlHeader from "@/components/ui/ControlHeader.vue";
import ControlSummery from "@/components/ui/ControlSummery.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import AddAnnualDialog from "./AddAnnualDialog.vue";


export default {
  name: "VacationControl",

  data: () => ({
    pageData: {
      screen_code: "09-006",
      url: null,
      controlRoute: "/codes/vacation-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isEdit: false,
      editIsLoading: false,
    },
    viewForm: false,
    summery: [],
    valid: false,
    openDialog: false,
    addNewItem: false,
    dilaogIsEdit: false,
    allVacationExceptOne: 0,
    vacation_type: [],
    vacation_details: [],
    update_details_index: null,
    update_details_data: {},
    vacation_summary_name: "",
    vacation_type_id: null,
    testArry: [],
    form: {
      name_en: null,
      name_ar: null,
      apply_months: null,
      vacation_type: null,
      total_vacation: null,
      transfer_vacation: null,
      vacation_system_d: []
    },
  }),
  components: {
    GenericInput,
    AddAnnualDialog,
    ControlHeader,
    ControlSummery
  },
  computed: {
    validationMassage() {
      return this.form.vacation_system_d.reduce((prev, current) => prev + +current.days_count, 0) == +this.form.total_vacation
    },
    calcRemainingVacation() {
      return this.form.total_vacation - this.form.vacation_system_d.reduce((prev, current) => prev + +current.days_count, 0)
    }
  },
  mounted() {
    this.pageMainData()
    this.getData()
  },
  methods: {
    // callBackMethod(data, index) {
    //   console.log("data", data, "index", index);
    //   const update_index = index
    //   if (data && update_index) {
    //     return data
    //   } else return this.testArry[update_index] = data
    // },
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
      this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
    },
    getData() {
      this.vacation_type = [
        {
          id: 1,
          name: this.$i18n.t("full year")
        },
        {
          id: 2,
          name: this.$i18n.t("from hiring date")
        },
        {
          id: 3,
          name: this.$i18n.t("after hiring days")
        }
      ]
      if (this.$route.params.id) {
        if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
          this.pageData.isEdit = true;
          this.pageData.editIsLoading = true;
          this.pageData.isLoading = true;
          this.$api.GET_METHOD(`vacation_system/${this.$route.params.id}`).then((response) => {
            this.pageData.editIsLoading = false;
            this.pageData.isLoading = false;
            if (response.check) {
              console.log("response", response);
              // this.vacation_type_id = response.data.master.vacation_type;
              // this.form.vacation_system_d = response.data.details
              this.form = { ...response.data.master, vacation_system_d: response.data.details };
              console.log("form", this.form);


              this.summery = [
                {
                  label: 'name_ar',
                  value: response.data.master.name_ar || '-',
                  class: '',
                  cols: null, sm: null, md: 6, lg: 6, xl: null,
                },
                {
                  label: 'name_en',
                  value: response.data.master.name_en || '-',
                  class: '',
                  cols: null, sm: null, md: 6, lg: 6, xl: null,
                },
                {
                  label: 'type of vacation',
                  value: this.$global.FilterArrayOfObjectElement(this.vacation_type, "id", +response.data.master.vacation_type, "name") || '-',
                  class: '',
                  cols: null, sm: null, md: 6, lg: 6, xl: null,
                },
                {
                  label: 'total vacation',
                  value: `${response.data.master.total_vacation} ${this.$i18n.t('days')}` || '-',
                  class: '',
                  cols: null, sm: null, md: 6, lg: 6, xl: null,
                },

              ]

              this.viewSummery = true;

              // this.$refs.form.validate();
            }
          })
        } else {
          this.$router.push('/')
        }
      }
      else {
        if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

          this.pageData.isLoading = true;
          this.viewForm = true;
          this.$api.GET_METHOD(`vacation_system/create`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              // this.form.vacation_system_d = response.data.qualifications;
            }
          })
        } else {
          this.$router.push('/')
        }

      }


    },
    save() {
      if (this.$refs.form.validate()) {
        this.pageData.isLoading = true;
        if (this.pageData.isEdit == true) {
          this.form._method = 'PUT';
          this.$api.POST_METHOD(`vacation_system/${this.form.id}`, this.form).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
            }
          })
        }
        else {
          this.$api.POST_METHOD(`vacation_system`, this.form).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.$router.push(this.pageData.url)
            }
          })
        }
      }
    },
    deleteMethod(status) {
      if (status == true) {
        this.pageData.isLoading = true
        this.$api.POST_METHOD(`vacation_system/${this.form.id}`, { _method: 'delete' }).then(() => {
          this.$router.push(this.pageData.url);
          this.pageData.isLoading = false
        })
      }
    },
    handelDelete(index) {
      this.form.vacation_system_d.splice(index, 1)
    },
    update_vacation_details(data, index) {
      this.dilaogIsEdit = true
      this.openDialog = true
      this.update_details_data = data
      this.update_details_index = index
      this.allVacationExceptOne = this.form.vacation_system_d.filter((vacation, i) => i !== index).reduce((prev, current) => prev + +current.days_count, 0)
      console.log('allVacationExceptOne', this.allVacationExceptOne);
      // allVacationExceptOne.reduce((prev, current) => prev + +current.days_count, 0)
      console.log('allVacationExceptOne', this.allVacationExceptOne);
    },
    after_update(data, index) {
      this.form.vacation_system_d[index] = { ...data }
      this.form.vacation_system_d = [...this.form.vacation_system_d]
    }
  },
  watch: {


  },
  updated() {
    // console.log(Object.keys(this.update_details_data).length === 0);
  }
};


</script>

<style lang="scss" scoped>
.vacation_col {
  position: relative;

  .controls {
    position: absolute;
    left: 50%;
    transform: translateX(-35%);
    bottom: 0;
  }

}
</style>