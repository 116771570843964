<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader v-if="!openProfileForm" :pageData="pageData" :form="form" :save="save"
            :valid="pageData.isEdit ? valid && !allRemainLoanIsPaid : valid" :deleteMethod="deleteMethod"
            :actionBtn="viewForm && pageData.isEdit" actionBtnClass="white--text" :showDeleted="!allRemainLoanIsPaid"
            :actionBtnClick="() => openDialog = true" :actionBtnValid="!allRemainLoanIsPaid" :actionBtnOutline="true"
            actionBtnText="reschedule the advance" :editForm="pageData.isEdit && !viewForm"
            :viewForm="() => viewForm = true">
            <template v-slot:leftSide>
               <v-btn @click="printMethod" v-if="pageData.isEdit" class="mx-2" :loading="pageData.printLoading.loading" color="primary"
                  depressed height="40">{{$t('print') }}</v-btn>
            </template>
         </ControlHeader>
         <!-- End Of Header -->

         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading"
            v-if="pageData.isEdit && !viewForm && !openProfileForm">
            <template v-slot:summerySlot="{ item }">
               <div v-if="item.key == 'radio'">
                  <v-icon color="blue4">mdi-check</v-icon>
                  {{ item.value }}
               </div>
               <div v-if="item.key == 'loan'">
                  <span class="gray11 pa-4 rounded-lg my-5 d-inline-block">{{ item.value || 0 | tafqeet }}</span>
               </div>
            </template>
         </ControlSummery>
         <!-- End of Summery -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3 mt-5" v-if="viewForm || openProfileForm">
            <v-card class="overflow-hidden transparent py-5 px-1" elevation="0">
               <v-row class="align-center">
                  <GenericInput v-if="!pageData.isEdit" type="date" :value="form.created_date = $global.GetCurrentDate()"
                     @input="form.created_date = $event" label="date" :required="true" :disabled="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="select" v-if="!openProfileForm" :value="form.employee_id"
                     @input="form.employee_id = $event" label="choose the employee" :lookups="employees" selected_prop="id"
                     selected_label="employee_name" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 5]">
                     <template v-slot:append>
                        <v-icon>mdi-magnify</v-icon>
                     </template>
                  </GenericInput>

                  <GenericInput type="select" :value="form.advance_type" @input="form.advance_type = $event"
                     label="movement type" :lookups="advance_types" selected_prop="id" selected_label="name"
                     :required="true" :isLoading="pageData.editIsLoading"
                     :cols="openProfileForm ? [12, 6, 5] : [12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="number" :value="form.amount" @input="form.amount = $event" label="loan amount"
                     :required="true" :isLoading="pageData.editIsLoading"
                     :cols="openProfileForm  ? [12, 6, 4] : [12, 6, 5]">
                  </GenericInput>

                  <GenericInput type="number" :value="form.previously_deducted" @input="form.previously_deducted = $event"
                     label="previously deducted" :required="false" :isLoading="pageData.editIsLoading" :cols="openProfileForm  ? [12, 6, 5] : [12, 6, 5]">
                  </GenericInput>

                  <GenericInput type="radiogroup" :lookups="radio_loan_type" :required="true" selected_label="name"
                     selected_prop="id" :value="form.loan_type" @input="form.loan_type = $event" label=""
                     :isLoading="false" :cols="[12, 6, 'auto']" />

                  <GenericInput type="textarea" :value="form.notes" @input="form.notes = $event" label="notes"
                     :required="false" :isLoading="false" :cols="[12, 9, 9]">
                  </GenericInput>

                  <v-col cols="12" md="6" lg="12" class="py-2">
                     <span class="tafqeet_span gray11 pa-4 rounded-lg my-1">
                        {{ form.amount || '' | tafqeet }}
                     </span>
                  </v-col>

                  <GenericInput type="number" :value="form.monthly_deducted" @input="form.monthly_deducted = $event"
                     label="monthly deduction" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <v-col cols="6">
                     <span class="rounded-lg d-inline-block">
                        {{ $t('number of installments') }} :{{ handelMonthlyInstallment }}</span>
                  </v-col>

                  <!-- loan start -->
                  <v-col cols="12" md="6" lg="3">
                     <v-menu ref="menuStartDate" v-model="menuStartDate" :close-on-content-click="false"
                        transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                           <v-text-field v-model="form.starting_loan" background-color="white" dense outlined
                              :label="$t('loan start')" :rules="[rules.required]" append-icon="mdi-calendar" readonly
                              :loading="pageData.editIsLoading" v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="form.starting_loan" :locale="$vuetify.rtl ? 'ar' : 'en'" type="month" title
                           scrollable>
                           <v-spacer></v-spacer>
                           <v-btn text color="primary" @click="menuStartDate = false">
                              Cancel
                           </v-btn>
                           <v-btn text color="primary" @click="$refs.menuStartDate.save()">
                              OK
                           </v-btn>
                        </v-date-picker>
                     </v-menu>
                  </v-col>
                  <!-- loan start -->

                  <!-- calculation start -->
                  <v-col cols="12" md="6" lg="3">
                     <v-menu ref="menuDate" v-model="menuDate" :close-on-content-click="false"
                        transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                           <v-text-field v-model="start_calculation" background-color="white" dense outlined
                              :label="$t('calculation start')" :rules="[rules.required]" append-icon="mdi-calendar"
                              readonly :loading="pageData.editIsLoading" v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="start_calculation" :min="form.starting_loan"
                           :locale="$vuetify.rtl ? 'ar' : 'en'" type="month" title scrollable>
                           <v-spacer></v-spacer>
                           <v-btn text color="primary" @click="menuDate = false">
                              Cancel
                           </v-btn>
                           <v-btn text color="primary" @click="$refs.menuDate.save()">
                              OK
                           </v-btn>
                        </v-date-picker>
                     </v-menu>
                  </v-col>
                  <!-- calculation start -->

                  <GenericInput type="date" :disabled="true" :value="end_calculation" @input="end_calculation"
                     label="calculation end" icon="mdi-chevron-down" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 3]">
                  </GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <RecalculateLoanDialog v-if="viewForm" :openDialog="openDialog" :closeDialog="() => openDialog = false"
            :remainLoanAmount="remainLoanAmount" :updateRemainLoan="updateRemainLoan" />
         <EmployeesLoanTransactions v-show="viewForm && pageData.isEdit" :loansTransactionDetails="loansTransactionDetails"
            :updateRemainLoan="updateRemainLoan" />

         <LoanTransactionPrint :printAction="() => print = false" :print="print" :printData="printData"
            :printStaticData="printStaticData" />
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlSummery from "@/components/ui/ControlSummery.vue";
import RecalculateLoanDialog from "./RecalculateLoanDialog.vue";
import EmployeesLoanTransactions from "./EmployeesLoanTransactions.vue";
import LoanTransactionPrint from "./LoanTransactionPrint.vue";

export default {
   name: "LoanControl",

   data: () => ({
      pageData: {
         screen_code: "09-016",
         url: null,
         controlRoute: "/transactions/loan_transaction-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
         printLoading: {
            loading: false,
            id: null,
            key: 'id'
         },
      },
      start_calculation: '',
      end_calculation: '',
      radio_loan_type: [],
      radio_loan_name: "",
      viewForm: false,
      summery: [],
      valid: false,
      employees: [],
      tab: 0,
      sales_areas: [],
      sales_agents: [],
      account_trees: [],
      currencies: [],
      countries: [],
      cities: [],
      purchases_vendor_types: [],
      openDialog: false,
      attachmentDialog: false,
      menuStartDate: false,
      menuDate: false,
      employee_profile_id: "",
      loansTransactionDetails: [],
      advance_types: [],
      form: {
         employee_id: null,
         previously_deducted: null,
         monthly_deducted: null,
         amount: null,
         installments_count: null,
         starting_loan: null,
         start_date: null,
         end_date: null,
         loan_type: null,
         advance_type: 1,
         notes: null,
      },
      rules: {
         required: value => !!value || 'Required.',
      },
      printData: [],
      printStaticData: Object,
      print: false,
      printResponse: {},
   }),
   components: {
      GenericInput,
      ControlHeader,
      ControlSummery,
      RecalculateLoanDialog,
      LoanTransactionPrint,
      EmployeesLoanTransactions
   },
   computed: {
      handelMonthlyInstallment() {
         return Math.ceil(this.form.monthly_deducted ? (this.form.amount / this.form.monthly_deducted) : "")
      },
      saveEmployeeDataControl() {
         if (this.openProfileForm) {
            return this.saveEmployeeData(this.form, this.valid, this.handelMonthlyInstallment, this.start_calculation, this.end_calculation)
         } else {
            return false
         }
      },
      remainLoanAmount() {
         return this.form.amount - this.loansTransactionDetails.filter((loan) => loan.is_paid == 1).reduce((prev, current) => prev + current.amount, 0)
      },
      allRemainLoanIsPaid() {
         return this.loansTransactionDetails.every((loan) => loan.is_paid == 1)
      }

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id && !this.openProfileForm) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`advance_action/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.printResponse = response.data;
                     this.loansTransactionDetails = response.data.details
                     this.employees = response.data.employees;
                     this.advance_types = response.data.advance_types;
                     !this.openProfileForm ? this.radio_loan_name = this.$global.FilterArrayOfObjectElement(this.radio_loan_type, "id", response.data.data.loan_type, "name") : ""
                     !this.openProfileForm ? this.form = response.data.data : ""
                     this.start_calculation = this.form.start_date
                     this.end_calculation = this.form.end_date
                     if (this.openProfileForm) {
                        this.form.employee_id = +this.$route.params.id
                     }
                     !this.openProfileForm ? this.summery = [
                        {
                           label: 'date',
                           value: response.data.data.add_date || '-',
                           class: '',
                           cols: null, sm: null, md: 5, lg: 5, xl: 5,
                        },
                        {
                           label: 'employee',
                           value: response.data.data.employee_name || '-',
                           class: '',
                           cols: null, sm: null, md: 5, lg: 5, xl: 5,
                        },
                        {
                           label: 'loan amount',
                           value: response.data.data.amount || '-',
                           class: '',
                           cols: 12, sm: null, md: 5, lg: 5, xl: 5,
                        },
                        {
                           label: 'previously deducted',
                           value: response.data.data.previously_deducted || '-',
                           class: '',
                           cols: 12, sm: null, md: 3, lg: 3, xl: 3,
                        },
                        {
                           label: 'radio_checked',
                           value: this.radio_loan_name || '-',
                           type: 'slot',
                           key: "radio",
                           class: '',
                           cols: 12, sm: null, md: 4, lg: 4, xl: 4,
                        },
                        {
                           label: 'loan mount',
                           value: response.data.data.amount || '-',
                           type: 'slot',
                           key: "loan",
                           class: '',
                           cols: 12, sm: null, md: 12, lg: 12, xl: 12,
                        },
                        {
                           label: 'monthly deduction',
                           value: response.data.data.monthly_deducted || '-',
                           class: '',
                           cols: 12, sm: null, md: 5, lg: 5, xl: 5,
                        },
                        {
                           label: 'number of installments',
                           value: response.data.data.installments_count || '-',
                           class: '',
                           cols: 12, sm: null, md: 5, lg: 5, xl: 5,
                        },
                        {
                           label: 'loan start',
                           value: response.data.data.starting_loan || '-',
                           class: '',
                           cols: 12, sm: null, md: 4, lg: 4, xl: 4,
                        },
                        {
                           label: 'calculation start',
                           value: response.data.data.start_date || '-',
                           class: '',
                           cols: 12, sm: null, md: 4, lg: 4, xl: 4,
                        },
                        {
                           label: 'calculation end',
                           value: response.data.data.end_date || '-',
                           class: '',
                           cols: 12, sm: null, md: 4, lg: 4, xl: 4,
                        }


                     ] : ""

                     this.viewSummery = true;
                     // this.$refs.form.validate();
                  }

               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`advance_action/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.advance_types = response.data.advance_types;
                     this.employees = response.data.employees;

                  }
               })
            } else {
               this.$router.push('/')
            }

         }
         this.radio_loan_type = [
            { id: 1, name: this.$i18n.t('create a payment voucher') },
            { id: 2, name: this.$i18n.t('deducted from salary') }
         ]

      },
      save() {
         this.form.installments_count = this.handelMonthlyInstallment;
         this.form.start_date = this.start_calculation;
         this.form.end_date = this.end_calculation;
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`advance_action/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`advance_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      save_employee() {
         this.form.installments_count = this.handelMonthlyInstallment;
         this.form.start_date = this.start_calculation;
         this.form.end_date = this.end_calculation;
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.$api.POST_METHOD(`advance_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     // this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                     // this.saveData = false;
                     this.closeProfileForm()
                     this.refreshTable()
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`advance_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  // this.saveData = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`advance_action/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      GetYearMonth(param) {
         var d = new Date(param),
            year = d.getFullYear(),
            month = '' + (d.getMonth() + 1);

         return year + '-' + month;
      },
      updateRemainLoan(amount) {
         this.loansTransactionDetails = amount;
      },
      printMethod() {
         console.log(this.printResponse.details);
         this.pageData.printLoading.loading = true;
         this.pageData.printLoading.id = this.$route.params.id;
         this.printData = [this.printResponse.data];
         this.printStaticData.master = this.printResponse.data;
         this.printStaticData.details = this.printResponse.details;
         console.log(this.printStaticData.details);
         this.print = true;
         setTimeout(() => {
            this.printData = [];
            this.printStaticData = Object;
            this.print = false;
            this.pageData.printLoading.loading = false;
         }, 1000);
      },
   },
   watch: {
      'form.starting_loan'() {
         return this.start_calculation = this.form.starting_loan ? this.form.starting_loan : ''
      },
      start_calculation() {
         this.end_calculation = this.start_calculation ? this.GetYearMonth(this.$global.AddToDate(this.start_calculation, this.handelMonthlyInstallment * 30)) : ""
      },
      handelMonthlyInstallment() {
         this.end_calculation = this.form.starting_loan ? this.GetYearMonth(this.$global.AddToDate(this.start_calculation, this.handelMonthlyInstallment * 30)) : ""
      },

   },
   updated() {
      this.saveEmployeeDataControl
   },
   props: {
      openProfileForm: { default: false },
      saveEmployeeData: { type: Function },
   }

};
</script>

<style lang="scss" scoped>
div#divider_ver {
   height: 200px !important;
}

.tafqeet_span {
   max-width: fit-content !important;
   display: block !important;
}
</style>
