<template>
    <section>
        <v-container fluid>
            <v-row>
                <v-col cols="12" sm="12" v-if="!openForm">
                    <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
                        :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
                        :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod">
                        <template v-slot:th="{ header }">
                            {{ $t(header.text) }}
                        </template>
                        <template v-slot:td="{ header, row }">
                            <div v-show="header.key === 'pay'">
                                <v-btn v-if="row.is_paid !== 1" class="blue4 white--text"
                                    @click="handelInstallment(row, 'pay')" small width="136" text elevation="0">
                                    {{ $t("pay the installment") }}
                                </v-btn>
                                <v-btn v-if="row.is_paid === 1" class="success white--text" small width="136" text
                                    elevation="0">
                                    {{ $t("installment was paid") }}</v-btn>
                            </div>
                            <div v-show="header.key === 'deferred'">
                                <v-btn v-if="row.is_paid !== 1 && row.delay_count === 0" class="warning white--text"
                                    @click="handelInstallment(row, 'delay')" small width="136" text elevation="0">
                                    {{ $t("Installment delay") }}
                                </v-btn>
                                <v-btn v-if="row.delay_count > 0 && row.is_paid === 0" class="gray10 black--text" small
                                    @click="handelInstallment(row, 'delay')" width="136" text elevation="0">
                                    {{ $t("Installment delayed") }}</v-btn>
                            </div>

                        </template>
                    </DynamicTable>
                </v-col>
            </v-row>
            <!-- <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
              <v-col cols="12" sm="12" >
                  <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber"
                      :backValueMethod="changePage" :isLoading="pageData.isLoading" v-if="!openForm"></Pagination>
              </v-col>
          </v-row> -->
        </v-container>
        <VacationTransactionControl v-if="openForm" :openProfileForm="openForm" />

        <ManegeInstallmentDialog :openInstallment="openInstallmentDialog"
            :closeInstallment="() => (openInstallmentDialog = false, rowInstallmentData = {})"
            :rowInstallmentData="rowInstallmentData" :callBackMethod="callBackMethod" :callGetData="callGetData"
            :rowID="rowID" :loansTransactionDetails="loansTransactionDetails" />

    </section>
</template>

<script>
// import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import ManegeInstallmentDialog from "./ManegeInstallmentDialog.vue";
import VacationTransactionControl from "../../Transactions/VacationTransaction/VacationTransactionControl.vue";
export default {
    name: "EmployeesLoanTransactions",

    data: () => ({
        pageData: {
            screen_code: "04-002",
            url: null,
            // controlRoute: "code/ex2-control",
            entityName: null,
            main: null,
            category: null,
            isLoading: true,
            isTrashed: 1,
            rowsNumber: 15,
            tableHeader: [],
            search: "",
            rows: [],
            options: {},
            page: 1,
            pagination: {},
            queryParam: '?'
        },
        openForm: false,
        openInstallmentDialog: false,
        DelayInstallmentDialog: false,
        rowInstallmentData: {}
    }),
    components: {
        // Pagination,
        DynamicTable,
        ManegeInstallmentDialog,
        VacationTransactionControl
    },
    props: {
        loansTransactionDetails: { default: Array },
        callGetData: { type: Function },
        updateRemainLoan: { type: Function },
        rowID: { type: Number }
    },
    computed: {},
    mounted() {
        this.queryParams();
        this.pageMainData();
        this.rowID && this.getData();
    },
    watch: {
        $route() {
            this.pageData.queryParam = '?';
            this.queryParams();
            this.pageMainData();
            this.getData();
        },
        rowID() {
            console.log("getData");
            this.getData();
        },
        loansTransactionDetails() {
            console.log("1", this.loansTransactionDetails);
            this.pageData.isLoading = false;
            if (this.loansTransactionDetails.length > 1) {
                console.log("2");
                this.pageData.rows = this.loansTransactionDetails;
            }
        }
    },
    methods: {
        queryParams() {
            this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
            this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
            this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
            this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
            this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
        },
        pageMainData() {
            this.pageData.tableHeader = [
                { text: "serial", key: "id", type: 'text', classes: "" },
                { text: "Installment due date", key: "deduction_date", type: 'text', classes: "" },
                { text: "value of the installment", key: "amount", type: 'text', classes: "" },
                { text: "discount date", key: "payment_date", type: 'text', classes: "" },
                { text: "postponement number", key: "delay_count", type: 'text', classes: "" },
                { text: "pay the installment", key: "pay", type: 'slot', min_width: "10", classes: "" },
                { text: "Installment delay", key: "deferred", type: 'slot', min_width: "10", classes: "" },
            ];
            this.pageData.options = {
                print: this.$global.CheckAction(this.pageData.screen_code, 5),
                delete: this.$global.CheckAction(this.pageData.screen_code, 4),
                edit: this.$global.CheckAction(this.pageData.screen_code, 3),
                restore: this.$global.CheckAction(this.pageData.screen_code, 4),
                view: this.$global.CheckAction(this.pageData.screen_code, 1),
                switch: false,
                selectRow: false,
                sortBy: "id",
                tableHeader: false,
                searchInput: false,
                printButton: false,
                handleClickRow: false,
            };
        },
        getSearch(word) {
            this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
            this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
        },
        getData() {
            this.pageData.isLoading = true;
            this.$api
                .GET_METHOD(`get_emp_advance_details/${this.rowID}`)
                .then((response) => {
                    this.pageData.isLoading = false;
                    if (response.check) {
                        this.pageData.entityName =
                            this.pageData.isTrashed == 0
                                ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                                : this.$store.state.activeScreen.sub_title;
                        this.pageData.rows = response.data;
                        // this.pageData.page = response.data.items.current_page;
                        // this.pageData.pagination = {
                        //     page: response.data.items.current_page,
                        //     totalPages: response.data.items.last_page,
                        //     per_page: response.data.items.per_page,
                        //     totalRows: response.data.items.total,
                        // };

                    }
                });

        },
        changePage(page, limit) {
            this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
            this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
        },
        deleteMethod(id) {
            console.log(id);
            this.$api.POST_METHOD(`purchases_vendor/${id}`, { _method: "delete" }).then((response) => {
                if (response.check) {
                    this.getData();
                }
            });
        },
        restoreMethod(row) {
            this.$api.POST_METHOD(`purchases_vendor_toggle_active/${row.id}`, null).then((response) => {
                if (response.check) {
                    this.getData();
                }
            });
        },
        setClickRow() {
            // this.$router.push(`/main/purchases_vendor/${row.id}`)
        },
        edit(row) {
            console.log("edit", row);
            this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
        },
        handelInstallment(row, action) {
            console.log("payInstallment", row);
            this.rowInstallmentData = { ...row, key: action }
            this.openInstallmentDialog = true
        },
        callBackMethod(row) {
            this.pageData.rows = row
            this.updateRemainLoan(row)
        }

    },
};
</script>
