<template>
    <v-dialog v-model="openDialogAttach" persistent max-width="650">
        <v-card class="pa-1" :key="form.request_type++">
            <v-card-actions>
                <v-card-text class="font-weight-bold text-h6 text-capitalize">
                    {{ $t("attachments") }}
                </v-card-text>
                <v-btn absolute class="mt-2 pa-5" :style="$vuetify.rtl ? 'left: 10px' : 'right: 10px'" small fab
                    color="red5" text @click="closeDialog">
                    <v-icon color="red5">mdi-close</v-icon>
                </v-btn>
            </v-card-actions>

            <v-card-text>
                <v-row class="text-center justify-end ">
                    <GenericInput type="dropzone" :value="form.attachment" @input="form.attachment = $event" label="image"
                        :multi="false" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                    </GenericInput>

                    <v-col cols="auto">
                        <v-btn class="success white--text" @click="save" width="136" text elevation="0"
                            :loading="isLoading">
                            {{ $t("save") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "FollowRequestAttach",
    data() {
        return {
            form: {
                attachment: null
            },
            isLoading: false
        };
    },

    methods: {
        save() {
            this.form._method = 'PUT';
            this.form.request_type = this.request_type;
            this.isLoading = true;
            this.$api.POST_METHOD(`update_attachment_request/${this.rowDialogData.id}`, this.form).then((response) => {
                this.isLoading = false;
                if (response.check) {
                    console.log("response", response);
                    this.closeDialog();
                    this.callBackMethod();
                    this.$refs.form.reset()
                }
            })
        },
    },
    watch: {
        openDialogAttach() {
            if (this.openDialogAttach) {
                this.form.attachment = this.rowDialogData.attachment
            }
        }
    },
    props: {
        openDialogAttach: { default: false },
        closeDialog: { default: Function },
        rowDialogData: { default: Object },
        request_type: { default: Number },
        callBackMethod: { default: Function },
    },

}
</script>

<style lang="scss" scoped></style>
