<template>
  <section>
    <v-container fluid class="pa-5">
      <v-row class="font-weight-bold" align="center">
        <v-col cols="6">{{ $t('current financial year') }}</v-col>
        <v-col cols="6" class="text-end">.
          <v-btn depressed color="blue11" class="primary--text mx-2" width="105" :to="pageData.controlRoute" >{{ $t('edit') }}</v-btn>
          <v-btn depressed color="error" class="white--text" width="147" @click="cancellationDialog = true">{{ $t('cancel the year') }}</v-btn>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" class="text-body-2">{{ $t('current year') }}</v-col>
        <v-col cols="6" class="d-flex align-center">
          <span class="card_year">
            <div class="error--text">السبت</div>
            <div class="font-weight-bold text-h5">01</div>
            <div>يناير</div>
            <div class="gray6--text">2022</div>
          </span>
          <span class="mx-6">-</span>
          <span class="card_year">
            <div class="error--text">الاحد</div>
            <div class="font-weight-bold text-h5">31</div>
            <div>ديسمبر</div>
            <div class="gray6--text">2022</div>
          </span>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">.
          <div class="mx-7">
            <span class="text-caption">{{ $t('number of working days') }}</span>
            <div class="font-weight-bold text-h5 mt-2">264</div>
          </div>
          <div>
            <span class="text-caption">{{ $t('number of vacation days') }}</span>
            <div class="font-weight-bold text-h5 mt-2">21</div>
          </div>
        </v-col>
      </v-row>

      <v-divider class="mt-10"></v-divider>

      <v-row align="center">
        <v-col cols="12" class="text-body-2 mt-5">{{ $t('current year') }}</v-col>
        <v-col cols="12" class="d-flex justify-center">
          <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
            :option="pageData.options" :pageData="pageData" >
          </DynamicTable>
        </v-col>

      </v-row>
    </v-container>
    <FinancialYeaCancellationDialog :openDialog="cancellationDialog"  :closeDialog="() => cancellationDialog = false" />
  </section>
</template>

<script>
import FinancialYeaCancellationDialog from './FinancialYeaCancellationDialog.vue';
export default {
  name: "FinancialYearEntity",

  data: () => ({
    pageData: {
      screen_code: "01-006",
      url: null,
      controlRoute: "/settings_hr/financial_year-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?'
    },
    openForm: false,
    valid: false,
    loading: false,
    cancellationDialog: false,
    form: {},
  }),
  components: {
    FinancialYeaCancellationDialog
  },
  computed: {},
  mounted() {
    this.queryParams();
    // this.pageMainData();
    this.pageData.isLoading = false
    // this.getData();
    this.pageData.tableHeader = [
        { text: "date", key: "add_date", type: 'text', classes: "" },
        { text: "employee name", key: "employee_name", type: 'text', classes: "" },
      ] 
  },
  watch: {
    $route() {
      this.queryParams();
      // this.pageMainData();
      this.pageData.isLoading = false
      // this.getData();
    },
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [
        { text: "date", key: "add_date", type: 'text', classes: "" },
        { text: "employee name", key: "employee_name", type: 'text', classes: "" },
      ] 
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`vacation_action${this.pageData.queryParam}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
  },
  props: {

  }
};
</script>

<style lang="scss" scoped>
span.card_year {
  display: flex;
  min-width: 122px;
  border: 1px solid #BFC4CB;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}
</style>
