<template>
    <v-dialog v-model="dialog" overlay-opacity="0.75" persistent max-width="450">
      <v-card relative class="backgroundW">
        <v-btn
          v-if="showCloseIcon"
          absolute
          class="mt-2"
          :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'"
          small
          fab
          color="grey lighten-1"
          text
          @click="closeDialog"
        >
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>
        <v-card-title class="text-h6 font-weight-bold">
          <img v-if="dialogContent.icon == 'info'" src="../../assets/img/png/watchOut.png" height="30" class="mx-2" alt="erp" />
          <h5 v-if="dialogContent.title">{{ $t(dialogContent.title) }}</h5>
        </v-card-title>

        <v-col cols="12" sm="12" v-if="dialogContent.details">
          <div class="body-2 px-5" >
            <p>{{ $t(dialogContent.details) }}</p>
          </div>
        </v-col>

        <v-col cols="12" class="py-4">
          <v-row justify="end">
            <v-col cols="auto">
              <v-btn
              :loading="saveDataLoading"
                @click="buttonTwoMethod"
                class="shadow"
                :color="dialogContent.buttonTwoColor"
                style="width: 100%; text-align: start"
              >{{ $t(dialogContent.buttonTwoTitle) }}</v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
              :loading="saveDataLoading"
                :color="dialogContent.buttonOneColor"
                @click="buttonOneMethod"
                class="shadow"
                style="width: 100%; text-align: start"
              >{{ $t(dialogContent.buttonOneTitle) }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
</template>



<style scoped lang="scss">
</style>



<script>

export default {
  name: "CustomModal",
  props: {
    buttonOneMethod: { type: Function },
    buttonTwoMethod: { type: Function },
    saveDataLoading: { default: false },
    showCloseIcon: { default: true },
    dialog: { default: false },
    dialogContent: {
      default: {
        icon: '',
        title: '',
        details: '',
        buttonOneTitle: '',
        buttonOneColor: 'primary',
        buttonTwoTitle: '',
        buttonTwoColor: 'error',
      }
    },

  },
  computed: {},
  data() {
    return {
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit("close", false);
    },

  },
  mounted() {
  }
};
</script>
