
import FinancialYearEntity from "../views/HRModule/SettingsHR/FinancialYear/FinancialYearEntity.vue"
import FinancialYearControl from "../views/HRModule/SettingsHR/FinancialYear/FinancialYearControl.vue"
import AccountsHRsettings from "../views/HRModule/SettingsHR/Accounts/AccountsHRsettings.vue"
import PersonalAccount from "../views/HRModule/SettingsHR/PersonalAccount/PersonalAccount.vue"
import PersonalAccountControl from "../views/HRModule/SettingsHR/PersonalAccount/PersonalAccountControl.vue"
import Notifications from "../views/HRModule/SettingsHR/Notifications/Notifications.vue"
import RequestsSettings from "../views/HRModule/SettingsHR/Requests/RequestsSettings.vue"
import PublicHolidaysEntity from "../views/HRModule/SettingsHR/PublicHolidays/PublicHolidaysEntity.vue"
import PublicHolidaysControl from "../views/HRModule/SettingsHR/PublicHolidays/PublicHolidaysControl.vue"

// Setting Module HR Routes:-

export const SettingsHrModuleRoutes = [
  
  // FinancialYear
  {
    path: "/settings_hr",
    name: "FinancialYearEntity",
    component: FinancialYearEntity,
    meta: {
      screen_code: "01-006",
    },
  },
  {
    path: "/settings_hr/financial_year-control",
    name: "FinancialYearControl",
    component: FinancialYearControl,
    meta: {
      screen_code: "01-006",
    },
  },
  // Accounts
  {
    path: "/settings_hr/accounts",
    name: "AccountsHRsettings",
    component: AccountsHRsettings,
    meta: {
      screen_code: "01-007",
    },
  },
  // PersonalAccount
  {
    path: "/settings_hr/personal_account",
    name: "PersonalAccount",
    component: PersonalAccount,
    meta: {
      screen_code: "01-007",
    },
  },
  {
    path: "/settings_hr/personal_account-control",
    name: "PersonalAccountControl",
    component: PersonalAccountControl,
    meta: {
      screen_code: "01-007",
    },
  },
    // Notifications
    {
      path: "/settings_hr/notifications",
      name: "Notifications",
      component: Notifications,
      meta: {
        screen_code: "01-007",
      },
    },
    // RequestsSettings
    {
      path: "/settings_hr/requests_settings",
      name: "RequestsSettings",
      component: RequestsSettings,
      meta: {
        screen_code: "01-007",
      },
    },
      // PublicHolidays
  {
    path: "/settings_hr/public_holidays",
    name: "PublicHolidaysEntity",
    component: PublicHolidaysEntity,
    meta: {
      screen_code: "01-007",
    },
  },
  {
    path: "/settings_hr/public_holidays-control",
    name: "PublicHolidaysControl",
    component: PublicHolidaysControl,
    meta: {
      screen_code: "01-007",
    },
  },
  {
    path: "/settings_hr/public_holidays-control/:id",
    name: "PublicHolidaysControlEdit",
    component: PublicHolidaysControl,
    meta: {
      screen_code: "01-007",
    },
  },
];
  
 

