<template>
    <v-dialog v-model="openDialog" persistent class="v-dialog_radios">
        <AnimatedLoading classes="white ma-auto loading_component" :hideText="false" :isLoading="isLoading"
            v-if="isLoading" />

        <v-card v-if="!isLoading" min-height="90vh">
            <v-card-actions class="d-flex justify-end pt-6">
                <v-btn class="error--text" :width="120" depressed @click="closeDialog" v-if="!isLoading">{{
                    $t("cancel")
                }}</v-btn>
                <v-btn color="success" depressed :width="120" @click="save"
                    class="rounded-lg font-weight-bold white--text " :loading="saveDataLoading"
                    :style="$vuetify.rtl ? 'margin-right: 50px' : 'margin-left: 50px'" v-if="!isLoading"
                    v-show="main_allowances.length > 0">
                    <!-- {{isEdit ? $t('save') : $t('save')}} -->
                    {{ $t('save') }}
                </v-btn>
            </v-card-actions>

            <!-- Main allowances -->
            <div class="row">

                <span class="main-allowances" v-show="main_allowances.length > 0"
                    :style="$vuetify.rtl ? 'left:14px' : 'right:10px'"> {{ $t("main allowances") }}
                    <img src="@/assets/img/svg/Vector_allowance.svg" alt="vector_allowances"
                        :style="$vuetify.rtl ? 'transform: rotate(90deg); padding-left:9px' : 'transform: rotate(90deg); position: absolute;right: -20px;top: 5.5px;'">
                </span>
                <div :class="main_allowances.length < 4 ? 'col-12 heigh' : 'col-12'">
                    <v-row justify="center" :class="main_allowances.length == 0 ? 'drop-here-parent' : ''">
                        <v-col v-show="main_allowances.length < 1" cols="12" md="3">
                            <div class=" text-center  rounded-lg rounded-br-0 drop-here-child mt-2">
                                <!-- {{$t("drop here") | capitalize}} -->
                                <img src="@/assets/img/svg/no_data.svg" height="150" width="100%" alt="no data" />
                                <div class="text-h6   gray8--text">
                                    {{ $t("there are no data") | capitalize }}
                                </div>

                            </div>
                        </v-col>
                    </v-row>
                    <v-simple-table v-show="main_allowances.length > 0">
                        <thead>
                            <tr>
                                <th style="width: 35%; border-bottom:1px solid #DDE1E4 !important;">
                                    {{ $t("serial") | capitalize }}
                                </th>

                                <th style="width: 25%; border-bottom:1px solid #DDE1E4 !important;">
                                    {{ $t("name") | capitalize }}
                                </th>
                                <th style="width: 35%; border-bottom:1px solid #DDE1E4 !important;">
                                    {{ $t("added by") | capitalize }}
                                </th>
                            </tr>
                        </thead>
                        <!-- draggable for swap between two arrays -->
                        <draggable tag="tbody" data-list="main_allowances" :list="main_allowances" group="filter"
                            :move="handleMoveItem" @end="handleDragEndItem" :options="{ animation: 500 }"
                            :class="main_allowances.length <= 4 ? 'allowances-height' : ''" cellspacing="5">
                            <tr v-for="main in main_allowances" :key="main.id">
                                <td>{{ main.id }}</td>
                                <td>{{ main.name }}</td>
                                <td>{{ main.added_by }}</td>
                            </tr>


                        </draggable>
                        <!-- draggable for swap between two arrays -->


                        <!-- <draggable v-model="main_allowances" tag="tbody" group="filter"
                            :class="main_allowances.length <= 4  ? 'allowances-height' : '' ">
                            <tr v-for="main in main_allowances" :key="main.id">
                                <td>{{ main.id }}</td>
                                <td>{{ main.name }}</td>
                                <td>{{ main.added_by }}</td>

                            </tr>
                        </draggable> -->
                    </v-simple-table>
                </div>

            </div>
            <!-- Main allowances -->

            <!-- hr between main & other -->
            <v-divider class="blue4" v-if="!isLoading" v-show="other_allowances.length >= 1"></v-divider>

            <!-- Loading for waiting response -->
            <!-- <AnimatedLoading class="loading_component" :isLoading='isLoading' /> -->


            <!-- Other allowances -->
            <div class="row">
                <span class="other-allowances" v-show="other_allowances.length > 0"> {{ $t("other allowances") }}</span>

                <div :class="other_allowances.length < 4 ? 'col-12 heigh' : 'col-12'">

                    <!-- <v-row justify="center" :class="other_allowances.length == 0 ? 'drop-here-parent' : ''  ">
                        <v-col v-show="other_allowances.length < 1" cols="12" md="3">
                            <div class="pa-4 text-center  rounded-lg rounded-br-0 drop-here-child">
                                <img src="@/assets/img/svg/no_data.svg" height="60" alt="" />

                                {{$t("drop here") | capitalize}}
                            </div>
                        </v-col>
                    </v-row> -->

                    <v-simple-table>
                        <thead class="head">
                            <tr>
                                <th style="width: 35%">
                                    {{ $t("serial") }}

                                </th>
                                <th style="width: 25%">
                                    {{ $t("name") }}

                                </th>
                                <th style="width: 35%">
                                    {{ $t("added by") }}

                                </th>
                            </tr>
                        </thead>

                        <!-- draggable for swap between two arrays -->

                        <draggable tag="tbody" data-list="other_allowances" :list="other_allowances" group="filter"
                            :move="handleMoveItem" @end="handleDragEndItem" :options="{ animation: 500 }"
                            :class="other_allowances <= 4 ? 'allowances-height' : ''">

                            <tr v-for="other in other_allowances" :key="other.id" class="list-group-item">
                                <td>{{ other.id }}</td>
                                <td>{{ other.name }}</td>
                                <td>{{ other.added_by }}</td>

                            </tr>
                        </draggable>

                        <!-- draggable for swap between two arrays -->

                        <!-- 
                        <draggable v-model="other_allowances" tag="tbody" group="filter"
                            :class="other_allowances.length <= 4  ? 'other_allowances-height' : '' ">
                            <tr v-for="other in other_allowances" :key="other.id">
                                <td>{{ other.id }}</td>
                                <td>{{ other.name }}</td>
                                <td>{{ other.added_by }}</td>


                            </tr>
                        </draggable> -->

                    </v-simple-table>
                </div>

            </div>
            <!-- Other allowances -->


        </v-card>
    </v-dialog>
</template>


<script>

import draggable from 'vuedraggable';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';

export default {
    name: "AllowancesDialog",

    data() {
        return {

            // valid: false,
            getDataLoading: false,
            saveDataLoading: false,
            isLoading: false,
            main_allowances: [],
            other_allowances: [],
            requestBody: {}
        }
    },

    methods: {

        // START.. Swap between arrays main & other

        handleDragEndItem() {
            if (this.originalList === this.futureList) {
                this.movingItem = this[this.futureList][this.originalIndex];
                this.futureItem = this[this.futureList][this.futureIndex];

                if (this.movingItem && this.futureItem) {
                    let _list = Object.assign([], this[this.futureList]);
                    _list[this.futureIndex] = this.movingItem;
                    _list[this.originalIndex] = this.futureItem;
                    this[this.futureList] = _list;
                }
            } else {
                this.movingItem = this[this.originalList][this.originalIndex];
                this.futureItem = this[this.futureList][this.futureIndex];

                if (this.movingItem && this.futureItem) {
                    let _listFrom = Object.assign([], this[this.originalList]);
                    let _listTo = Object.assign([], this[this.futureList]);
                    _listTo[this.futureIndex] = this.movingItem;
                    _listFrom[this.originalIndex] = this.futureItem;
                    this[this.originalList] = _listFrom;
                    this[this.futureList] = _listTo;
                }
            }
            document
                .querySelectorAll('.list-group-item')
        },
        handleMoveItem(event) {
            document
                .querySelectorAll('.list-group-item')
            const { index, futureIndex } = event.draggedContext;
            this.originalIndex = index;
            this.futureIndex = futureIndex;
            this.originalList = event.from.getAttribute('data-list');
            console.log(this.originalList)
            this.futureList = event.to.getAttribute('data-list');
            return false; // disable sort
        },

        // END.. Swap between Arrays Main & Other


        getData() {
            // this.main_allowances = []
            // this.other_allowances = []
            this.isLoading = true;
            this.$api.GET_METHOD(`allowance?type=1`).then((response) => {
                console.log("response.data.items", response.data.items);
                this.main_allowances = response.data.items.slice(0, 4)
                this.other_allowances = response.data.items.splice(4)
                // response.data.items.forEach(element => {
                // this.main_allowances.push(element)
                // switch (element.main) {
                //     case 1:
                //         console.log("main_allowances", [element]);
                //         this.main_allowances.push(element)
                //         break;
                //     case 2:
                //         console.log("other_allowances", [element]);
                //         this.other_allowances.push(element)
                //         break;
                // }
                // })
                this.isLoading = false;
                if (response.check) {
                    console.log("response", response);
                }
            })
        },

        save() {
            this.isLoading = true
            console.log("this.items", this.items);
            this.requestBody = {
                items: [... this.main_allowances, ...this.other_allowances]
            }
            this.$api.POST_METHOD(`allowance_sort`, this.requestBody).then((response) => {
                // this.saveDataLoading = false;
                this.isLoading = false
                if (response.check) {
                    console.log("this.form", this.form);
                    console.log("res post", response);
                    this.closeDialog();
                    this.backValueMethod();
                    // this.$refs.form.reset();
                    // this.$router.push("/codes/shift");
                    // this.$router.go(-1)
                }
            })
        },
    },

    mounted() {
    },

    watch: {
        openDialog() {
            if (this.openDialog == true) {
                this.getData()
            }
        }
    },

    props: {
        openDialog: { default: false },
        closeDialog: { type: Function },
        backValueMethod: { type: Function },
    },
    components: { draggable, AnimatedLoading }
}

</script>


<style lang="scss" scoped>
.v-sheet.v-card {
    border-radius: 0px !important;
}

.head {
    visibility: collapse;
}

.row {
    margin: 0;
}

.drop-here-parent {
    margin-top: -3rem;
}

.drop-here-child {
    // border: 1px dashed #1e5eab;
    z-index: 1;
    position: relative;
    top: 10.3rem
}

.main-allowances {
    transform: rotate(-90deg);
    // writing-mode: vertical-lr;
    position: relative;
    top: 11rem;
    z-index: 10;
    color: #1E5EAB;
    font-weight: bold;
}

.other-allowances {
    transform: rotate(-90deg);
    // writing-mode: vertical-lr;
    position: relative;
    top: 7rem;
    right: 0.7rem;
    z-index: 10;
    color: #1E5EAB;
    font-weight: bold;
}

.allowances-height {
    height: 97px;
    z-index: 1000;
}

.heigh {
    height: 245px;
}


td {
    cursor: grab;
}

.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.loading_component {
    padding-top: 13rem !important;
    height: 90vh !important
}
</style>