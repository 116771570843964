<template>
    <v-dialog v-model="openDialog" persistent max-width="500">
        <AnimatedLoading classes="white ma-auto loading_component" :hideText="false" :isLoading="isLoading"
            v-if="isLoading" />

        <v-form ref="form">

            <v-card v-if="!isLoading">
                <v-card-actions>
                    <v-card-text class="font-weight-bold text-h5 text-capitalize">
                        {{ $t("shift move") }}
                    </v-card-text>
                    <v-btn absolute class="mt-2" :style="$vuetify.rtl ? 'left: 10px' : 'right: 10px'" small fab color="red5"
                        text @click="closeDialog">
                        <v-icon color="red5">mdi-close</v-icon>
                    </v-btn>
                </v-card-actions>
                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <div class="text-h6 pa-4 blue1--text font-weight-bold">{{ $t("Choose workers from") }}</div>

                            <GenericInput type="select" :lookups="shifts"
                                :disableItem="{ key: 'id', value: form.second_shift }" :value="form.first_shift"
                                @input="form.first_shift = $event" label="Choose Shift" :multi="false" :required="true"
                                selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]" />

                            <div class="text-h6 pa-4 blue1--text font-weight-bold">{{ $t("Choose workers to") }}</div>

                            <GenericInput type="select" :lookups="shifts" :value="form.second_shift"
                                :disableItem="{ key: 'id', value: form.first_shift }" @input="form.second_shift = $event"
                                label="Choose Shift" :multi="false" :required="true" selected_label="name"
                                selected_prop="id" :isLoading="false" :cols="[12, 12, 12]" />

                            <v-col class="d-flex justify-end">
                                <v-btn class="error--text " :width="120" depressed @click="closeDialog">{{ $t("back") }}
                                </v-btn>
                                <v-btn color="success" depressed :width="120" :disabled="!valid" @click="save"
                                    class="rounded-lg font-weight-bold white--text" :loading="saveDataLoading"
                                    :style="$vuetify.rtl ? 'margin-right: 16px' : 'margin-left: 16px'">
                                    {{ $t('move') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';

export default {
    name: "ShiftDialog",
    data() {
        return {
            shifts: [],
            valid: false,
            getDataLoading: false,
            isLoading: false,
            saveDataLoading: false,
            form: {
                first_shift: null,
                second_shift: null
            },
        };
    },

    methods: {
        getData() {
            this.isLoading = true;
            this.$api.GET_METHOD(`shift/create`).then((response) => {
                this.isLoading = false;
                if (response.check) {
                    console.log("response", response);
                    this.shifts = response.data.shifts;
                }
            })
        },
        save() {
            this.isLoading = true
            this.$api.POST_METHOD(`change_shift`, this.form).then((response) => {
                // this.saveDataLoading = false;
                this.isLoading = false
                if (response.check) {
                    this.closeDialog();
                    this.backValueMethod();
                }
            })
        },
    },

    watch: {
        openDialog() {
            this.getData()
            if (!this.openDialog) {
                this.$refs.form.reset();
            }
        },
    },

    mounted() {
        // this.getData()
    },
    props: {
        openDialog: { default: false },
        closeDialog: { type: Function },
        backValueMethod: { type: Function },
    },
    components: { GenericInput, AnimatedLoading }
}
</script>

<style lang="scss" scoped>
.loading_component {
    padding-top: 6.5rem !important;
    height: 360px !important
}
</style>
