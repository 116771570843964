<template>
  <v-row>
    <v-col cols="3">
        <v-card class="mx-auto rounded-lg" width="256" tile>
          <v-navigation-drawer permanent>
            <v-list nav dense>
              <v-list-item-group mandatory v-model="selectedItem" color="primary">
                <v-list-item v-for="(item, i) in items" :key="i" exact :to="item.link">
                  <v-list-item-content>
                    <v-list-item-title >{{ $t(item.text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
        </v-card>
    </v-col>
    <v-col cols="9">
      <v-card class="mx-auto rounded-lg">
        <router-view></router-view>
      </v-card>
    </v-col>
  </v-row>
</template>


<script>
export default {
  name: "SettingsHrModule",
  components: {},
  data: () => ({
    path: null,
    selectedItem: 0,
    items: [
      { text: 'financial year', link: '/settings_hr' },
      { text: 'accounts', link: '/settings_hr/accounts' },
      { text: 'Personal Account', link: '/settings_hr/personal_account' },
      { text: 'notifications', link: '/settings_hr/notifications' },
      { text: 'the requests', link: '/settings_hr/requests_settings' },
      { text: 'public holidays', link: '/settings_hr/public_holidays' },
    ],
  }),
};
</script>
