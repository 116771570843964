<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <GenericInput type="text" :value="form.title_name_ar" @input="form.title_name_ar = $event"
                     label="name_ar" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.title_name_en" @input="form.title_name_en = $event"
                     label="name_en" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>
                  <!-- <GenericInput type="switch" :value="form.all_users" @input="form.all_users = $event" label="apply on all users"
                     :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                  </GenericInput> -->
               </v-row>
            </v-card>

            <!-- Tabs -->




            <!-- Table -->
            <v-card elevation="0" class="mt-5 overflow-hidden" v-if="!pageData.isLoading && pageData.isEdit">
               <v-col cols="12" class="d-flex justify-center align-center pt-3 pa-5">
                  <v-tabs active-class="indigo darken-2 white--text" color="indigo darken-2" v-model="tab"
                     v-if="!pageData.isLoading">
                     <v-tabs-slider></v-tabs-slider>

                     <v-tab v-for="item in items" :key="item" class="font-weight-bold" @click="activeTab = item">{{ item
                     }}
                     </v-tab>
                  </v-tabs>
               </v-col>
               <PermissionsDataTableVue :isLoading="pageData.isLoading" :options="options"
                  :data="rows.filter((row) => row.main_title == activeTab)" :activeTab="activeTab"
                  v-if="activeTab !== 'الكل'" :header="header" :backValueMethod="setChanges" />

               <PermissionsDataTableVue :isLoading="pageData.isLoading" :options="options" :activeTab="activeTab"
                  :data="rows" v-if="activeTab == 'الكل' && !search" :header="header" :backValueMethod="setChanges" />

               <PermissionsDataTableVue :isLoading="pageData.isLoading" :options="options" :activeTab="activeTab" :data="
                  rows.filter(
                     (row) =>
                        row.sub_title.toLowerCase().indexOf(search) === 0 ||
                        row.cat_title.toLowerCase().indexOf(search) === 0
                  )
               " v-if="activeTab == 'الكل' && search" :header="header" :backValueMethod="setChanges" />
            </v-card>


         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
import PermissionsDataTableVue from "./PermissionsDataTable.vue";
export default {
   name: "JobTitleControl",
   data: () => ({
      pageData: {
         screen_code: "01-016",
         url: null,
         controlRoute: "/main/title-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      all_screens: [],
      categories: [],
      headerData: {},
      header: [],
      rows: [],
      options: {},
      search: null,
      tab: null,
      activeTab: "الكل",
      items: ["الكل"],
      requestBody: [],
      modules: [],
      form: {
         title_name_en: null,
         title_name_ar: null,
         all_users: 1,
         permissions: null,
      },
   }),
   watch: {
      $route() {
         this.getData()
      }
   },
   components: {
      ControlHeader,
      GenericInput,
      PermissionsDataTableVue
   },
   computed: {
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`title/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.form = response.data.data;
                     response.data.all_permissions.forEach((row) => {
                        row.actions = row.actions.split(",").map((i) => Number(i));
                        row.id = row.sub_id;
                        row.view = row.permissions.view == true ? true : null;
                        row.add = row.permissions.add == true ? true : null;
                        row.edit = row.permissions.edit == true ? true : null;
                        row.delete = row.permissions.delete == true ? true : null;
                        row.print = row.permissions.print == true ? true : null;
                        row.forceDelete = row.permissions.forceDelete == true ? true : null;
                        row.availableActions = Object.keys(row.permissions);
                        Object.keys(row.permissions).every((action) => {
                           if (row.permissions[action] == true) {
                              row.allSelected = true;
                              return true;
                           } else {
                              row.allSelected = false;
                              return false;
                           }
                        });
                        console.log('modules', this.modules);
                        if (
                           this.$global.FilterArrayOfObject(this.modules, "main_id", row.main_id)
                              .length == 0
                        ) {
                           this.items.push(row.main_title);
                           this.modules.push({
                              main_id: row.main_id,
                              main_title: row.main_title,
                           });
                        }
                        if (
                           this.$global.FilterArrayOfObject(this.categories, "cat_id", row.cat_id)
                              .length == 0
                        ) {
                           this.categories.push({
                              main_id: row.main_id,
                              cat_id: row.cat_id,
                              cat_title: row.cat_title,
                              main_title: row.main_title,
                           });
                        }
                        this.rows.push(row);
                        // console.log("row.availableActions", row.allSelected);
                     });
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`title/create`).then(() => {
                  this.pageData.isLoading = false;
                  // if (response.check) {
                  //    response.data.all_permissions.forEach((row) => {
                  //       row.actions = row.actions.split(",").map((i) => Number(i));
                  //       row.id = row.sub_id;
                  //       row.view = row.permissions.view == true ? true : null;
                  //       row.add = row.permissions.add == true ? true : null;
                  //       row.edit = row.permissions.edit == true ? true : null;
                  //       row.delete = row.permissions.delete == true ? true : null;
                  //       row.print = row.permissions.print == true ? true : null;
                  //       row.forceDelete = row.permissions.forceDelete == true ? true : null;
                  //       row.availableActions = Object.keys(row.permissions);
                  //       Object.keys(row.permissions).every((action) => {
                  //          if (row.permissions[action] == true) {
                  //             row.allSelected = true;
                  //             return true;
                  //          } else {
                  //             row.allSelected = false;
                  //             return false;
                  //          }
                  //       });
                  //       if (
                  //          this.$global.FilterArrayOfObject(this.modules, "main_id", row.main_id)
                  //             .length == 0
                  //       ) {
                  //          this.items.push(row.main_title);
                  //          this.modules.push({
                  //             main_id: row.main_id,
                  //             main_title: row.main_title,
                  //          });
                  //       }
                  //       if (
                  //          this.$global.FilterArrayOfObject(this.categories, "cat_id", row.cat_id)
                  //             .length == 0
                  //       ) {
                  //          this.categories.push({
                  //             main_id: row.main_id,
                  //             cat_id: row.cat_id,
                  //             cat_title: row.cat_title,
                  //             main_title: row.main_title,
                  //          });
                  //       }
                  //       this.rows.push(row);
                  //       // console.log("row.availableActions", row.allSelected);
                  //    });
                  // }
               })
            } else {
               this.$router.push('/')
            }

         }

      },



      setChanges(permissions) {
         this.allChanges = permissions;
         // console.log("--------------------------------------------------");
         console.log("allChanges", permissions);
         permissions.forEach((permission) => {
            let prem = {
               sub_id: permission.sub_id,
               title_permission: [],
            };
            permission.view == true ? prem.title_permission.push(1) : null;
            permission.add == true ? prem.title_permission.push(2) : null;
            permission.edit == true ? prem.title_permission.push(3) : null;
            permission.delete == true ? prem.title_permission.push(4) : null;
            permission.print == true ? prem.title_permission.push(5) : null;
            permission.forceDelete == true ? prem.title_permission.push(6) : null;
            this.checkEditablePremissions(prem);
         });
      },
      checkEditablePremissions(permission) {
         if (!this.requestBody.some((prem) => prem.sub_id === permission.sub_id)) {
            // console.log('set');
            this.requestBody.push(permission);
         } else {
            // console.log('update');
            var objIndex = this.requestBody.findIndex(
               (obj) => obj.sub_id == permission.sub_id
            );
            this.requestBody[objIndex] = permission;
         }
      },

      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.form.permissions = this.requestBody
               console.log("Save", this.form);
               this.$api.POST_METHOD(`title/${this.form.id}`, this.form).then((response) => {
                  if (response.check) {
                     this.pageData.isLoading = false;
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`title`, this.form).then((response) => {
                  if (response.check) {
                     this.pageData.isLoading = false;
                     this.$router.push('/main/title-control/' + response.data.id)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`title/${this.form.title_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
