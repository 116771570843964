<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader v-if="!openProfileForm" :pageData="pageData" :form="form" :save="save" :valid="valid"
            :deleteMethod="deleteMethod" :actionBtn="false" actionBtnClass="light text-decoration-underline blue5--text"
            :actionBtnClick="() => attachmentDialog = true" :actionBtnValid="true" actionBtnText="attachments"
            :editForm="pageData.isEdit && !viewForm" :viewForm="() => viewForm = true">
            <template v-slot:leftSide>
               <v-btn @click="printMethod" v-if="pageData.isEdit" :loading="pageData.printLoading.loading" color="primary"
                  depressed height="40">{{
                     $t('print') }}</v-btn>
            </template>
         </ControlHeader>
         <!-- End Of Header -->

         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading"
            v-if="pageData.isEdit && !viewForm && !openProfileForm">
            <template v-slot:summerySlot="{ item }">
               <div v-if="item.key == 'slotKey'">
                  <span class="font-weight-bold">{{ $t(item.label) }} :</span>
                  <span class="px-2" v-if="!vacation_summary_name">
                     <v-progress-circular :width="2" indeterminate color="primary"></v-progress-circular>
                  </span>
                  <span class="px-2">{{ vacation_summary_name }}</span>
               </div>
            </template>
         </ControlSummery>
         <!-- End of Summery -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3 mt-1" v-if="viewForm || openProfileForm">
            <v-card class="transparent overflow-hidden py-5 px-1" elevation="0">
               <v-row>

                  <v-slide-x-transition>
                     <v-col cols="12" class="mb-3" v-if="employee_data.length > 0">
                        <v-card class="transparent" elevation="0">
                           <v-row class="justify-start">
                              <v-col cols="auto" color="blue12">
                                 <h4 class="mb-1 text-button">{{ $t("total number of vacations") }}</h4>
                                 <span class="font-weight-bold">
                                    {{ calculatorNum(employee_data, "total_days") }}
                                    {{ $t("day") }}
                                 </span>
                                 /
                                 <span :class="styleTotalCount(employee_data)">
                                    {{ calculatorRemain(employee_data) }}
                                    {{ $t("available") }}
                                 </span>
                              </v-col>
                              <v-col cols="auto" v-for="vacation in employee_data" :key="vacation.vacation_system_d_id">
                                 <h4 class="mb-1 text-button">{{ vacation.vacation_name }}</h4>
                                 <span class="font-weight-bold">{{ vacation.total_days }} {{ $t("day") }}</span>
                                 /
                                 <span
                                    :class="+vacation.total_days - +vacation.used_days > 0 ? 'font-weight-bold success--text' : 'font-weight-bold error--text'">
                                    {{ +vacation.total_days - +vacation.used_days }} {{ $t("available") }}
                                 </span>
                              </v-col>
                           </v-row>
                        </v-card>
                     </v-col>
                  </v-slide-x-transition>

                  <GenericInput v-if="!pageData.isEdit || openProfileForm" type="date" :value="created_date = $global.GetCurrentDate()"
                     @input="created_date = $event" label="date" :required="true" :disabled="true"
                     :isLoading="pageData.isLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="select" v-if="!openProfileForm" :value="form.employee_id"
                     @input="form.employee_id = $event" label="choose the employee" :lookups="employees" selected_prop="id"
                     selected_label="employee_name" :required="true" :isLoading="pageData.isLoading" :cols="[12, 6, 4]">
                     <template v-slot:append>
                        <v-icon>mdi-magnify</v-icon>
                     </template>
                  </GenericInput>

                  <GenericInput type="date" :value="form.start_date" @input="form.start_date = $event" label="start date"
                     :required="true" :isLoading="pageData.isLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="select" :value="form.vacation_system_d_id"
                     @input="form.vacation_system_d_id = $event" icon="mdi-chevron-down"
                     selected_prop="vacation_system_d_id" label="the type of holiday" :lookups="employee_data"
                     selected_label="vacation_name" :required="true" :isLoading="pageData.isLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="number" :value="form.days_count" @input="form.days_count = $event"
                     label="the number of days" :required="true" :isLoading="pageData.isLoading"
                     :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="textarea" :value="form.notes" @input="form.notes = $event" label="details"
                     :rowsNumber="4" :required="false" :isLoading="pageData.isLoading" :cols="[12, 6, 12]">
                  </GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>

      <VacationTransactionPrint :printAction="() => print = false" :print="print" :printData="printData"
         :printStaticData="printStaticData" />
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlSummery from "@/components/ui/ControlSummery.vue";
import VacationTransactionPrint from "./VacationTransactionPrint.vue";

export default {
   name: "VacationTransactionControl",

   data: () => ({
      pageData: {
         screen_code: "09-015",
         url: null,
         controlRoute: "/transactions/vacation_transaction-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
         printLoading: {
            loading: false,
            id: null,
            key: 'id'
         },
      },
      viewForm: false,
      summery: [],
      valid: false,
      employees: [],
      vacations: [],
      employee_data: [],
      openDialog: false,
      attachmentDialog: false,
      vacation_summary_name: "",
      employee_profile_id: "",
      created_date: null,
      form: {
         employee_id: null,
         start_date: null,
         vacation_system_d_id: null,
         days_count: null,
         notes: null,
      },
      printData: [],
      printStaticData: Object,
      print: false,
      printResponse: {},
   }),
   components: {
      GenericInput,
      ControlHeader,
      ControlSummery,
      VacationTransactionPrint
   },
   computed: {
      calculatorNum() {
         return (array, key) => array.reduce((prev, current) => Number(prev) + Number(current[key]), 0)
      },
      calculatorRemain() {
         return (array) => array.reduce((prev, current) => Number(prev) + Number(current.total_days), 0) - array.reduce((prev, current) => Number(prev) + Number(current.used_days), 0)
      },
      styleTotalCount() {
         return (employee_data) => {
            if (this.calculatorRemain(employee_data) > 0) {
               return `font-weight-bold success--text`
            } else return `font-weight-bold error--text`
         }
      }
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id && !this.openProfileForm) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`vacation_action/${this.$route.params.id}`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.printResponse = response.data;
                     this.employees = response.data.employees;
                     this.vacations = response.data.vacations;
                     this.form = response.data.data

                     this.summery = [
                        {
                           label: 'name',
                           value: response.data.data.employee_name || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'start date',
                           value: response.data.data.start_date || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'the type of holiday',
                           value: response.data.data.vacation_system_id || '-',
                           class: '',
                           key: 'slotKey',
                           type: 'slot',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'the number of days',
                           value: response.data.data.days_count || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'details',
                           value: response.data.data.notes || '-',
                           class: '',
                           cols: 12, sm: null, md: null, lg: 7, xl: null,
                        },


                     ]
                     this.viewSummery = true;
                     // this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`vacation_action/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     if (this.openProfileForm) {
                        this.form.employee_id = +this.$route.params.id
                     } 
                     this.employees = response.data.employees;
                     this.vacations = response.data.vacations;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`vacation_action/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`vacation_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      save_employee() {
         this.form.amount = this.form.increase_type == 2 ? this.$global.ConvertFromPercentage(this.increase_value, this.form.salary_fixed) : this.increase_value
         this.form.salary_after_increase = this.totalAmount
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.$api.POST_METHOD(`vacation_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     // this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                     this.closeProfileForm()
                     this.refreshTable()
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`vacation_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`vacation_action/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      printMethod() {
         this.pageData.printLoading.loading = true;
         this.pageData.printLoading.id = this.$route.params.id;
         this.printData = [this.printResponse.data];
         this.printStaticData.master = this.printResponse.data;
         this.printStaticData.master.vacation_type = this.vacation_summary_name;
         this.printStaticData.details = this.employee_data;
         this.print = true;
         setTimeout(() => {
            this.printData = [];
            this.printStaticData = Object;
            this.print = false;
            this.pageData.printLoading.loading = false;
         }, 1000);
      },

   },
   watch: {
      "form.employee_id"() {
         this.employee_data = this.employees.find(employee => employee.id === this.form.employee_id).employee_vacations
         this.vacation_summary_name = this.$global.FilterArrayOfObjectElement(this.employee_data, 'vacation_system_d_id',
            this.form.vacation_system_d_id, 'vacation_name')
      },
      valid() {
         if (this.openProfileForm) {
            return this.saveEmployeeData(this.form, this.valid)
         }
      }
   }
   ,
   props: {
      openProfileForm: { default: false },
      saveEmployeeData: { type: Function }
   }
};
</script>

<style lang="scss" scoped>
div#divider_ver {
   height: 200px !important;
}
</style>
