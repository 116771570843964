<template>
  <v-btn @click="print" width="30" height="30" v-if="$global.CheckAction(screenCode, 5)" :loading="loading" x-small icon
    fab>
    <img src="@/assets/img/svg_icons/fi_printer.svg" height="16" />
  </v-btn>
</template>


<script>
export default {
  name: "PrintInvoice",
  props: {
    printMethod: { type: Function },
    endpoint: { default: null },
    btnText: { default: null },
    id: { default: null },
    type: { default: 'print' },
    screenCode: { default: null }
  },
  data: () => ({
    loading: false
  }),
  computed: {

  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    print() {
      if (this.type == 'print') {
        const src = `${this.$api.serverUrl}${this.endpoint}/${this.id}?type=${this.type}&clientID=${this.$store.state.clientID}&lang=${this.$store.state.Settings.language}`;
        this.$api.PrintInvoiceURL(src, this.id)
      }
      if (this.type == 'image') {
        const src = `src="${this.$api.serverUrl}${this.endpoint}/${this.id}?type=${this.type}&clientID=${this.$store.state.clientID}&lang=${this.$store.state.Settings.language}"`;
        this.$api.PrintInvoice(src, this.id);
      }
    }
  },
};
</script>



<style scoped lang="scss">

</style>
