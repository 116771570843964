<template>
  <v-container fluid class="backgroundW " id="appBar">
    <v-row justify="end" align="center">
      <v-col cols="12" lg="8" md="auto" sm="12" class="py-0">
        <v-row align="center" justify="end">
          <v-col cols="3" md="auto" sm="auto">
            <v-btn v-on:click="changeLanguage" x-small fab  class="bg-white d-block ma-auto" elevation="0">
              <img src="@/assets/img/svg/USA.svg" height="25" v-if="$vuetify.rtl == true" alt="" />
              <img src="@/assets/img/svg/KSA.svg" height="25" v-if="$vuetify.rtl == false" alt="" />
            </v-btn>
          </v-col>
          <v-col cols="3" md="auto" sm="auto">
            <v-btn x-small fab @click="darkTheme" depressed>
              <v-icon v-if="$store.state.Settings.darkTheme">mdi-white-balance-sunny</v-icon>
              <v-icon v-if="!$store.state.Settings.darkTheme">mdi-weather-night</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="pa-0">
            <v-divider vertical style="height: 40px"></v-divider>
          </v-col>
          <v-col cols="auto">
            <JobTitlesMenu />
          </v-col>
          <v-col cols="auto" class="pa-0">
            <v-divider vertical style="height: 40px"></v-divider>
          </v-col>
          <v-col cols="auto">
            <CompanyMenu/>
          </v-col>
          <v-col cols="auto" class="pa-0">
            <v-divider vertical style="height: 40px"></v-divider>
          </v-col>




          <v-col cols="3" md="auto" sm="auto">
            <UserMenu></UserMenu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserMenu from "./UserMenu.vue";
import CompanyMenu from "./CompanyMenu.vue";
import JobTitlesMenu from "./JobTitlesMenu.vue";

export default {
  name: "Appbar",
  computed: {
    options() {
      return {
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen;
        },
        target: ".directive-fullscreen-wrapper",
        pageOnly: this.pageOnly,
        teleport: this.teleport,
      };
    },
  },
  data() {
    return {
      fullscreen: false,
    };
  },
  components: {
    UserMenu,
    CompanyMenu,
    JobTitlesMenu
},
  methods: {
    darkTheme() {
      this.$store.dispatch("Settings/darkTheme");
    },
    toggleSidebar() {
      this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
  },
};
</script>

<style lang="scss" scoped>
#appBar {
  position: fixed;
  height: 60px;
  z-index: 55;
  width: 100vw !important;
  left: 0 !important;
  right: 0 !important;
  display: flex;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}
</style>