<template>
   <v-row justify="center">
      <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="addEmployee || isEdit ? 600 : 900">
         <v-card relative :key="modalKey">
            <v-btn absolute class="ma-2" v-if="!addEmployee" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small
               fab color="error" text @click="closeDialog">
               <v-icon color="error">mdi-close</v-icon>
            </v-btn>

            <v-form ref="update" v-model="valid" class="pa-7 pt-12">
               <v-row align="center">
                  <v-col cols="9">
                     <div class="subtitle-1 font-weight-bold" v-if="!addEmployee && !isEdit">
                        <span v-if="selectedNode.level == 2"> {{ $t('management') | capitalize }} /</span>
                        <span v-else-if="selectedNode.level == 3"> {{ $t('department') | capitalize }} /</span>
                        <span v-else> {{ $t('employee') | capitalize }} /</span>
                        {{ selectedNode.name | capitalize }} .
                        <span class="subtitle-1 font-weight-regular">
                           {{ selectedNode.manager_name | capitalize }}
                           <span class="caption primary--text cursor_pointer" @click="isEdit = true">{{ $t('edit')
                           }}</span>
                        </span>
                     </div>
                     <div class="subtitle-1 font-weight-bold" v-if="addEmployee || isEdit">
                        <span v-if="selectedNode.level == 2"> {{ $t('management') | capitalize }} / {{ selectedNode.name
                           | capitalize
                        }} </span>
                        <span v-if="selectedNode.level == 3"> {{ $t('department') | capitalize }} / {{ selectedNode.name
                           | capitalize
                        }} </span>
                     </div>
                  </v-col>
                  <v-col cols="3" v-if="selectedNode.level == 3 && !addEmployee && !isEdit">
                     <v-btn color="primary" @click="addEmployee = true" class="d-block mi-start-auto" depressed>
                        {{ $t('add employee') }}</v-btn>
                  </v-col>
                  <v-col cols="12" v-if="!addEmployee && !isEdit && !isEdit">
                     <DynamicTable :isLoading="isLoading" :data="computedNodeEmployees" :header="tableHeader"
                        :noDataText="selectedNode.level == 3 ? 'You havent any employee in this department' : 'You havent any employee in this department'">

                        <template v-slot:td="{ row, header }">
                           <div v-if="header.key == 'employee_name'">
                              {{ row.employee_name + ' ' + row.father_name + ' ' + row.father_name }}
                           </div>
                           <div v-if="header.key == 'manager'" class="d-flex">
                              <img src="@/assets/img/svg/manager.svg" v-if="selectedNode.manager_id == row.id" alt="">
                           </div>
                        </template>

                     </DynamicTable>
                  </v-col>
                  <v-col cols="12" v-if="addEmployee" class="pa-0 my-7">
                     <v-row class="align-center">
                        <GenericInput type="select" :lookups="employees" :value="Number(employee_id)"
                           @input="employee_id = $event" label="select employee" :multi="false" :required="false"
                           selected_label="employee_name" selected_prop="id" :isLoading="false" :cols="[12, 6, 6]" />
                        <v-col cols="12" md="6" class="d-flex justify-end">
                           <v-btn color="primary" @click="saveEmployeeUpdate" class="mx-1" :disabled="!(employee_id)"
                              :loading="isLoading" depressed>{{ $t('add employee') }}
                           </v-btn>
                           <v-btn color="error" text @click="addEmployee = false" class="mx-1" depressed>
                              {{ $t('cancel') }}</v-btn>
                        </v-col>
                     </v-row>
                  </v-col>
                  <v-col cols="12" v-if="isEdit" class="pa-0 my-7">
                     <v-row class="align-center">
                        <GenericInput type="select" :lookups="employees" :value="Number(update.manager_id)"
                           @input="update.manager_id = $event" label="select manager" :multi="false" :required="true"
                           selected_label="employee_name" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]" />

                        <GenericInput type="text" :value="update.name_ar" @input="update.name_ar = $event"
                           label="اسم بالعربي" :required="false" :isLoading="false" :cols="[12, 6, 6]" />

                        <GenericInput type="text" :value="update.name_en" @input="update.name_en = $event"
                           label="Name in English" :required="false" :isLoading="false" :cols="[12, 6, 6]" />

                        <v-col cols="12" class="d-flex justify-end">
                           <v-btn color="error" text @click="isEdit = false" :min-width="100" class="mx-1" depressed>
                              {{ $t('cancel') }}</v-btn>
                           <v-btn color="primary" :min-width="100" @click="save" class="mx-1" :disabled="!(valid)"
                              :loading="isLoading" depressed>{{ $t('save') }}
                           </v-btn>
                        </v-col>
                     </v-row>
                  </v-col>
               </v-row>
            </v-form>
         </v-card>
      </v-dialog>
   </v-row>
</template>

<script>
import DynamicTable from '@/components/DynamicTable.vue';
import GenericInput from '@/components/ui/GenericInput.vue';
export default {
   name: "EditStructureNode",
   props: {
      backValueMethod: { type: Function },
      dialog: { default: false },
      selectedNode: { default: Object },
      company_id: { default: null },
   },
   computed: {
      computedNodeEmployees() {
         if (this.employees.length > 0) {
            return this.$global.FilterArrayOfObject(this.employees, this.selectedNode.level == 2 ? 'management_id' : 'department_id', Number(this.selectedNode.id))
         } else {
            return []
         }
      }
   },
   components: {
      DynamicTable,
      GenericInput
   },
   watch: {
   },
   data: () => ({
      isLoading: false,
      valid: false,
      addEmployee: false,
      isEdit: false,
      modalKey: 0,
      employee_id: null,
      tableHeader: [],
      employees: [],
      update: {
         name_ar: null,
         name_en: null,
         manager_en: null,
      }
   }),
   methods: {
      closeDialog() {
         this.addEmployee = false;
         this.employee_id = null;
         this.isEdit = false;
         this.modalKey = this.modalKey + 1;
         this.backValueMethod(false, true)
      },

      saveEmployeeUpdate() {
         this.isLoading = true;
         const requestBody = {
            employee_id: this.employee_id,
            management_id: this.selectedNode.management_id,
            department_id: this.selectedNode.id,
         }
         this.$api.POST_METHOD(`update_employee_department`, requestBody).then((response) => {
            this.isLoading = false;
            if (response.check) {
               this.backValueMethod(true, true)
               this.addEmployee = false;
               this.employee_id = null;
               this.isEdit = false;
            }
         })
      },
      getData() {
         this.isLoading = true;
         this.$api.GET_METHOD(`create_company_structure?company_id=${this.company_id}`).then((response) => {
            this.isLoading = false;
            if (response.check) {
               this.employees = response.data.employess || [];
               this.update.name_ar = this.selectedNode.name_ar ?? null;
               this.update.name_en = this.selectedNode.name_en ?? null;
               this.update.manager_id = this.selectedNode.manager_id ?? null;
            }
         })
      },
      save() {
         this.isLoading = true;
         var requestBody = Object;
         var endPoint = String;
         switch (this.selectedNode.level) {
            case 2:
               endPoint = 'update_management';
               requestBody = {
                  manager_id: this.update.manager_id,
                  management_id: this.selectedNode.id,
                  name_ar: this.update.name_ar,
                  name_en: this.update.name_en
               }
               break;
            case 3:
               endPoint = 'update_department';
               requestBody = {
                  manager_id: this.update.manager_id,
                  department_id: this.selectedNode.id,
                  name_ar: this.update.name_ar,
                  name_en: this.update.name_en
               }
               break;

            default:
               this.isLoading = false;
               break;
         }

         this.$api.POST_METHOD(endPoint, requestBody).then((response) => {
            this.isLoading = false;
            if (response.check) {
               this.backValueMethod(true, true)
               this.addEmployee = false;
               this.isEdit = false;
               this.employee_id = null;
               this.modalKey = this.modalKey + 1;
            }
         })
      },

   },
   mounted() {
      this.tableHeader = [
         { text: "#", key: "id", type: 'text', classes: "" },
         { text: "name", key: "employee_name", type: 'slot', classes: "" },
         { text: "job", key: "job_name", type: 'text', classes: "" },
         { text: "", key: "manager", type: 'slot', classes: "" },
      ];
      this.getData()
   },
};
</script>
