<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader v-if="!openProfileForm" :pageData="pageData" :form="form" :save="save" :valid="valid"
            :deleteMethod="deleteMethod" :actionBtn="false" actionBtnClass="light text-decoration-underline blue5--text"
            :actionBtnClick="() => attachmentDialog = true" :actionBtnValid="true" actionBtnText="attachments"
            :editForm="pageData.isEdit && !viewForm" :viewForm="() => viewForm = true">
            <template v-slot:leftSide>
               <v-btn @click="printMethod" v-if="pageData.isEdit" :loading="pageData.printLoading.loading" color="primary"
                  depressed height="40">{{
                     $t('print') }}</v-btn>
            </template>
         </ControlHeader>
         <!-- End Of Header -->

         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading"
            v-if="pageData.isEdit && !viewForm && !openProfileForm">
            <template v-slot:summerySlot="{ item }">
               <div v-if="item.key === 'benefits_type'">
                  <span class="font-weight-bold">{{ $t(item.label) }}:</span>
                  {{ $global.FilterArrayOfObjectElement(benefits_type, "id", item.value, 'name') }} 
               </div>
               <div v-if="item.key === 'increase_type'">
                  <span class="font-weight-bold">{{ $t(item.label) }}:</span>
                  {{ $global.FilterArrayOfObjectElement(value_type, "id", item.value, 'name') }} 
               </div>
            </template>
         </ControlSummery>
         <!-- End of Summery -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="mt-5" v-if="viewForm || openProfileForm">
            <v-card class="transparent  overflow-hidden py-5" elevation="0">
               <v-row class="align-center">

                  <GenericInput v-if="!pageData.isEdit || openProfileForm" type="date"
                     :value="current_date = $global.GetCurrentDate()" @input="current_date = $event" label="date"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]" :disabled="true">
                  </GenericInput>

                  <GenericInput type="select" v-if="!openProfileForm" :value="form.employee_id"
                     @input="form.employee_id = $event" label="choose the employee" :lookups="employee"
                     selected_label="employee_name" selected_prop="id" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 4]">
                     <template v-slot:append>
                        <v-icon>mdi-magnify</v-icon>
                     </template>
                  </GenericInput>

                  <GenericInput type="date" :value="form.action_month" @input="form.action_month = $event"
                     label="date of starting" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="select" :value="form.salary_effect_id" @input="form.salary_effect_id = $event"
                     icon="mdi-chevron-down" label="benefit name" :lookups="benefits_name" selected_label="name"
                     selected_prop="id" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="select" :value="form.one_time" @input="form.one_time = $event"
                     icon="mdi-chevron-down" label="benefits type" :lookups="benefits_type" selected_label="name"
                     selected_prop="id" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="select" :value="form.value_type" @input="form.value_type = $event"
                     icon="mdi-chevron-down" label="increase type" :lookups="value_type" selected_label="name"
                     selected_prop="id" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="number" :value="form.value" @input="form.value = $event" label="value"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="textarea" :value="form.notes" @input="form.notes = $event" label="details"
                     :rowsNumber="4" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                  </GenericInput>
               </v-row>
            </v-card>
         </v-form>

      </v-container>

      <BenefitsTransactionPrint :printAction="() => print = false" :print="print" :printData="printData"
         :printStaticData="printStaticData" />
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlSummery from "@/components/ui/ControlSummery.vue";
import BenefitsTransactionPrint from "./BenefitsTransactionPrint.vue";

export default {
   name: "BenefitsTransactionsControl",

   data: () => ({
      pageData: {
         screen_code: "09-014",
         url: null,
         controlRoute: "/transactions/benefit_transaction-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
         printLoading: {
            loading: false,
            id: null,
            key: 'id'
         },
      },
      viewForm: false,
      summery: [],
      benefits_name: [],
      benefits_type: [],
      valid: false,
      tab: 0,
      openDialog: false,
      attachmentDialog: false,
      employee: [],
      value_type: [],
      name: [],
      employee_profile_id: "",
      current_date: null,
      form: {
         notes: null,
         value: null,
         value_type: null,
         action_month: null,
         employee_id: null,
         salary_effect_id: null,
         one_time: 1,
      },
      printData: [],
      printStaticData: Object,
      print: false,
      printResponse: {},
   }),
   components: {
      GenericInput,
      ControlHeader,
      ControlSummery,
      BenefitsTransactionPrint
   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id && !this.openProfileForm) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`benefit_actions/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.employee = response.data.employees;
                     this.printResponse = response.data;
                     this.benefits_name = response.data.benefits;
                     !this.openProfileForm ? this.form = response.data.data : ""

                     if (this.openProfileForm) {
                        this.form.employee_id = +this.$route.params.id
                     }
                     console.log("form", this.form);
                     this.summery = [
                        {
                           label: 'name',
                           value: response.data.data.employee_name || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'date',
                           value: response.data.data.add_date || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'benefit name',
                           value: response.data.data.salary_effect_name || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'benefits type',
                           value: response.data.data.one_time,
                           type: 'slot',
                           key: 'benefits_type',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'increase type',
                           value: response.data.data.value_type || '-',
                           type: 'slot',
                           key: 'increase_type',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'value',
                           value: response.data.data.value || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'execution date of the benefit',
                           value: response.data.data.action_month || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'details',
                           value: response.data.data.notes || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },


                     ]
                     this.viewSummery = true;
                     // this.$refs.form.validate();

                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`benefit_actions/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {

                     this.employee = response.data.employees;
                     this.benefits_name = response.data.benefits;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }
         this.value_type = [
            {
               id: 1,
               name: this.$i18n.t('hour')
            },
            {
               id: 2,
               name: this.$i18n.t('work days')
            },
            {
               id: 3,
               name: this.$i18n.t('value')
            },
         ]
         this.benefits_type = [
            {
               id: 0,
               name: this.$i18n.t('monthly')
            },
            {
               id: 1,
               name: this.$i18n.t('one time')
            },
         ]
      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`benefit_actions/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`benefit_actions`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`benefit_actions/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      printMethod() {
         this.pageData.printLoading.loading = true;
         this.pageData.printLoading.id = this.$route.params.id;
         this.printData = [this.printResponse.data];
         this.printStaticData.master = this.printResponse.data;
         this.printStaticData.benefits = this.value_type;
         this.print = true;
         setTimeout(() => {
            this.printData = [];
            this.printStaticData = Object;
            this.print = false;
            this.pageData.printLoading.loading = false;
         }, 1000);
      },
   },
   props: {
      openProfileForm: { default: false },
      saveEmployeeData: { type: Function }
   },
   watch: {
      valid() {
         if (this.openProfileForm) {
            return this.saveEmployeeData(this.form, this.valid)
         }
      }
   }
};
</script>

<style lang="scss" scoped>
div#divider_ver {
   height: 200px !important;
}
</style>
