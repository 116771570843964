<template>
    <v-dialog v-model="openDialog" persistent>
        <v-card>
            <v-card-actions>
                <v-card-text class="font-weight-bold text-h6 text-capitalize">
                    {{ $t("employees") }}
                </v-card-text>
                <!-- Search -->
                <v-col cols="12" lg="auto" sm="auto" class="py-0">
                    <v-text-field v-model="searchWord" @keydown.enter="(e) => getSearch(e.target.value)"
                        @click:clear="(e) => getSearch(e.target.value)" class="rounded-pill" background-color="#eceef0"
                        @click:append="getSearch(searchWord)" append-icon="mdi-magnify" :placeholder="pageData.isTrashed == 0 ? $i18n.t('Search in deleted') : $i18n.t('Search')
                            " single-line clearable hide-details solo dense :height="37"></v-text-field>
                </v-col>
                <!-- End Of Search -->
                <v-btn class="" small fab color="red5" text @click="closeDialog">
                    <v-icon color="red5">mdi-close</v-icon>
                </v-btn>
            </v-card-actions>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="12">
                        <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
                            :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
                            :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod" />
                    </v-col>
                </v-row>
                <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
                    <v-col cols="12" sm="12">
                        <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber"
                            :backValueMethod="changePage" :isLoading="pageData.isLoading"></Pagination>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import DynamicTable from '@/components/DynamicTable.vue';
import Pagination from '@/components/ui/Pagination.vue';
export default {
    name: "CompanyEmployeeNumbers",
    data: () => ({
        pageData: {
            screen_code: "09-022",
            url: null,
            controlRoute: "codes/company-control",
            entityName: null,
            main: null,
            category: null,
            isLoading: true,
            isTrashed: 1,
            rowsNumber: 15,
            tableHeader: [],
            search: "",
            rows: [],
            options: {},
            page: 1,
            pagination: {},
            queryParam: '?'
        },
        searchWord: ""
    }),
    computed: {},
    mounted() {
        this.pageMainData();
        this.getData();
    },
    watch: {
        $route() {
            this.pageData.queryParam = '?';
            this.pageMainData();
            this.getData();
        },
        openDialog() {
            if (this.openDialog) {
                this.getData();
            }
        }
    },
    methods: {
        pageMainData() {
            this.pageData.main = this.$global.FilterPermissions(
                this.pageData.screen_code
            ).main_title;
            this.pageData.category = this.$global.FilterPermissions(
                this.pageData.screen_code
            ).cat_title;
            this.pageData.entityName = this.$global.FilterPermissions(
                this.pageData.screen_code
            ).sub_title;
            this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
            this.pageData.tableHeader = [
                { text: "code", key: "id", type: 'text', classes: "" },
                { text: "employee name", key: "employee_name", type: 'text', classes: "" },
                { text: "administration", key: "management_name", type: 'text', width: '200', classes: "" },
                { text: "nationality", key: "nationality_name", type: 'text', classes: "" },
                { text: "position", key: "job_name", type: 'text', classes: "" },
            ];
            this.pageData.options = {
                print: this.$global.CheckAction(this.pageData.screen_code, 5),
                delete: this.$global.CheckAction(this.pageData.screen_code, 4),
                edit: this.$global.CheckAction(this.pageData.screen_code, 3),
                restore: this.$global.CheckAction(this.pageData.screen_code, 4),
                view: this.$global.CheckAction(this.pageData.screen_code, 1),
            };
        },
        getSearch(searchWord) {
            this.searchWord = searchWord
            this.getData()
        },
        getData() {
            if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
                this.pageData.isLoading = true;
                this.$api
                    .GET_METHOD(`get_company_employees/${this.row_id}${`?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.searchWord || ''}`}`)
                    .then((response) => {
                        this.pageData.isLoading = false;
                        if (response.check) {
                            this.pageData.entityName =
                                this.pageData.isTrashed == 0
                                    ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                                    : this.$store.state.activeScreen.sub_title;
                            this.pageData.rows = response.data.item.data;
                            this.pageData.page = response.data.item.current_page;
                            this.pageData.pagination = {
                                page: response.data.item.current_page,
                                totalPages: response.data.item.last_page,
                                per_page: response.data.item.per_page,
                                totalRows: response.data.item.total,
                            };
                        }
                    });
            }
        },
        changePage(page, limit) {
            this.pageData.page = page;
            this.pageData.rowsNumber = limit;
            this.getData()
        },
        deleteMethod(id) {
            console.log(id);
            this.$api.POST_METHOD(`get_company_employees/${id}`, { _method: "delete" }).then((response) => {
                if (response.check) {
                    this.getData();
                }
            });
        },
        restoreMethod(row) {
            this.$api.POST_METHOD(`get_company_employees_toggle_active/${row.id}`, null).then((response) => {
                if (response.check) {
                    this.getData();
                }
            });
        },
        setClickRow() {
            // this.$router.push(`/main/purchases_vendor/${row.id}`)
        },
        edit(row) {
            console.log("edit", row);
            this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
        },
    },
    props: {
        openDialog: { default: false },
        closeDialog: { type: Function },
        row_id: { type: Number },
    },
    components: {
        DynamicTable,
        Pagination
    }
}
</script>

<style lang="scss" scoped>
.v-card__actions {
    position: sticky;
    top: 0;
    z-index: 10000;
    background: white;
    height: 68px;
}
</style>
