<template>
    <v-container fluid>
        <!-- {{ employeesContract }} -->

        <div v-if="!isLoading">
            <div v-if="employeesContract.items.length === 0 && updatedData.length === 0">
                {{ $t("there is no contract for this employee") }}
                <v-btn @click="renewalContract = true" class="secondary--text" elevation="0" color="blue12">
                    {{ $t("create contract") }} </v-btn>
            </div>

            <v-row :class="doc.active !== 1 ? 'id_number align-center red5--text' : 'id_number align-center'"
                v-for="(doc, i) in updatedData.length !== 0 ? updatedData : employeesContract.items" :key="doc.id">
                <v-col cols="auto" class="font-weight-bold">{{ $t("contract") }}</v-col>
                <v-col cols="auto">{{ $t("start date") }} {{ doc.start_date }} </v-col>
                <v-col cols="auto">{{ $t("end date") }} {{ doc.end_date }} </v-col>
                <v-col cols="auto" class="d-flex" @click="downloadAttachment(doc)">
                    <a class="px-3">{{ doc.contract_file.slice(doc.contract_file.length - 10) }}</a>
                    <img src="@/assets/img/svg/doc.svg" alt="" srcset="">
                </v-col>
                <v-col cols="auto" class="black--text">{{ $t("added by") }} {{ doc.added_by }} </v-col>
                <v-col cols="auto" v-if="doc.active !== 1">{{ $t("expired") }}</v-col>
                <v-col cols="auto" v-if="i === 0">
                    <v-btn @click="renewalContract = true" class="secondary--text" elevation="0" color="blue12">
                        {{ $t("renewal") }} </v-btn>
                </v-col>
            </v-row>
        </div>

        <RenewalContractDialog v-if="!isLoading" :openRenewalContract="renewalContract" :pushNewData="pushNewData"
            :closeRenewalContract="() => renewalContract = false" :employeesContract="employeesContract" />

        <AnimatedLoading :isLoading="isLoading" />
    </v-container>
</template>

<script>

import AnimatedLoading from "@/components/ui/AnimatedLoading.vue";
import RenewalContractDialog from "./RenewalContractDialog.vue";
export default {
    name: "EmployeesContracts",
    data: () => ({
        updatedData: [],
        renewalContract: false,
        isLoading: true,
        file_name: null
    }),

    methods: {
        downloadAttachment(row) {
            // this.printLoading = true;
            // const src = `${this.downloadResource}/${this.data[this.dataKey]}`;
            // const download_file_name = `${this.$i18n.t('attach')}_${this.$i18n.t(this.entityName)}_${this.data[this.dataKey]}.${this.data.attach_ext}`
            // this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
            //     this.printLoading = false;
            // })
            console.log(row);
            this.$api.DOWNLOAD_METHOD_GET(`download_contract_document/${row.id}`, row.contract_file).then(() => {
                // this.printLoading = false;
            })
        },
        pushNewData(data) {
            this.updatedData = data
        }

    },
    computed: {

    },
    watch: {
        employeesContract() {
            if (Object.keys(this.employeesContract).length > 0) {
                this.isLoading = false;
            }
        }
    },
    props: {
        employeesContract: { default: Object },
    },
    components: { RenewalContractDialog, AnimatedLoading }
}
</script>


<style lang="scss" scoped></style>