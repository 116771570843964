<template>
    <v-dialog v-model="openDialog" max-width="500" persistent>
        <v-card relative class="pa-9">
            <v-row align="center">
                <v-col cols="12">
                    <span class="font-weight-bold text-h6">{{ $t("do you want to exit") }}</span>
                    <v-btn absolute class="mt-0" :style="$vuetify.rtl ? 'left: 10px' : 'right: 10px'" small fab color="red5"
                        text @click="closeDialog">
                        <v-icon color="gray6">mdi-close</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <p>{{ $t("all data will be lost") }}</p>
                </v-col>
                <v-col cols="12">
                    <v-btn depressed text @click="cancelControl" width="100%" height="44"
                        class="rounded-lg font-weight-bold white--text error">
                        {{ $t('exit') }}
                    </v-btn>
                </v-col>

                <v-col cols="12">
                    <v-btn color="blue5" depressed width="100%" height="44" @click="closeDialog"
                        class="rounded-lg font-weight-bold white--text">
                        {{ $t('stay here') }}
                    </v-btn>
                </v-col>
            </v-row>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ExitConfirmationModal",
    props: {
        openDialog: { default: false },
        pageData: { default: null },
        closeDialog: { type: Function },
        route: { default: null },

    },
    methods: {
        backRoute() {
            this.$router.go(-1)
        },
        cancelControl() {
            if (!this.route) {
                if (this.pageData.isEdit) {
                    this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                } else {
                    this.$router.push(this.$store.state.activeScreen.url)
                }
            }else{
                this.backRoute()
            }

        },
    }
}
</script>

<style lang="scss" scoped></style>
