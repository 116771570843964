<template>
  <div class="text-center" id="appBarMenu">
    <v-menu :close-on-content-click="false"  offset-y transition="slide-y-transition" bottom
      max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex align-center" v-bind="attrs" v-on="on">
          <img src="@/assets/img/svg/git-branch.svg" class="mx-2" height="25" alt="">

          <div class="text-start">
            <span class="font-weight-bold body-2">{{ $t("company") }} <v-icon small>mdi-chevron-down</v-icon></span>
            <span class="text--disabled d-none d-lg-block body-2">{{ activeCompany }}</span>
          </div>
        </div>
      </template>

      <v-card  width="300" class="py-0 mt-5 rounded-lg">
        <v-list-item-content class="justify-center">
          <div>
            <v-btn block depressed rounded-sm text v-for="(company, index) in $store.state.companies"
              :color="company.id == $store.state.current_company ? 'primary' : 'secondary'" :key="index"
              @click="setActiveCompany(company.id)">
              {{ company.name }}
              <v-icon v-if="company.id == $store.state.current_company" small right>mdi-check</v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
  </div>
</template>
<style scoped>
.v-menu__content {
  box-shadow: 0px 21px 32px rgba(0, 0, 0, 0.05) !important;
  top: 3rem !important;
  border-radius: 0px 0px 8px 8px;
}

#appBarMenu .v-menu__content {
  box-shadow: none !important;
  top: 64px !important;
  box-shadow: none !important;
}

#appBarMenu.v-menu__content .v-card {
  background-color: #fafafa !important;
}
</style>

<script>
export default {
  name: "CompanyMenu",
  computed: {},
  data: () => ({
    activeCompany: null,
  }),
  mounted() {
    this.getActiveCompany();
  },

  methods: {
    getActiveCompany() {
      console.log('companies',this.$store.state.companies);
      this.$store.state.companies.forEach((company) => {
        if (company.id == this.$store.state.current_company) {
          this.activeCompany = company.name;
        }
      });
    },
    setActiveCompany(id) {
      localStorage.setItem("companyid", id);
      this.$store.state.current_company = id;
      this.$api.SET_USER_DEFAULTS()
      this.getActiveCompany();
    },
  },
};
</script>
