<template>
    <v-dialog v-model="openDialogReason" persistent max-width="500">


        <AnimatedLoading style="height:290px" classes="white pt-16 ma-auto " :hideText="false" :isLoading="loading"
            v-if="loading" />



        <v-form ref="form" v-model="valid">
            <v-card v-if="!loading">
                <v-card-actions>



                    <v-btn absolute class="mt-10" :style="$vuetify.rtl ? 'left: 10px' : 'right: 10px'" small fab
                        color="red5" text @click="closeDialog">
                        <v-icon color="red5">mdi-close</v-icon>
                    </v-btn>
                </v-card-actions>
                <v-card-text>
                    <v-row class="text-center">
                        <div class="text-h6 pa-4 mb-5 blue1--text font-weight-bold">
                            {{ $t('the reason for not agreeing') }}
                        </div>

                        <GenericInput type="textarea" :rowsNumber="6" :value="form.refuse_reason"
                            @input="form.refuse_reason = $event" label="notes" :multi="false" :required="false"
                            selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]">
                        </GenericInput>


                        <v-card-actions class="d-block mi-start-auto">
                            <v-btn class="error--text mr-10 ml-10" depressed @click="closeDialog">{{ $t("back") }}
                            </v-btn>

                            <v-btn color="success" depressed :width="120" :disabled="!valid"
                                class="rounded-lg font-weight-bold white--text" @click="save">
                                {{ isEdit ? $t('save') : $t('send') }}

                                <!-- {{$t('send')}} -->
                            </v-btn>

                        </v-card-actions>
                    </v-row>
                </v-card-text>
            </v-card>

        </v-form>

    </v-dialog>
</template>

<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';


export default {
    name: "RejectedReasonDialog",
    data() {

        return {
            loading: false,


            form: {

                refuse_reason: null


            },


        };



    },
    watch: {
        closeDialog() {

            this.$refs.form.reset();

        },
    },
    methods: {

        // reloadFollowRequestPage() {
        //     this.backValueMethod()   
        // },

        save() {

            this.loading = true

            let endPoint = '';

            switch (this.dialog_id) {

                case 0:
                    endPoint = 'decline_advance_request';


                    break;
                case 9:
                    endPoint = 'decline_vacation_request';


                    break;


                case 11:
                    endPoint = 'decline_increase_request';
                    break;




                case 3:
                    endPoint = 'decline_allowance_request';
                    break;


                case 4:
                    endPoint = 'decline_permit_request';
                    break;

                case 5:
                    endPoint = 'decline_permit_request';

                    break;
                case 6:
                    endPoint = 'decline_resignation_request';

                    break;
                case 7:
                    endPoint = 'decline_termination_request';

                    break;


                default:
                    break;


            }




            this.$api.POST_METHOD(`${endPoint}/${this.rowDialogData.id}`, this.form).then((response) => {
                // this.saveDataLoading = false;
                this.loading = false
                if (response.check) {
                    console.log("this.form", this.form);
                    console.log("res post", response);

                    this.closeDialog();
                    this.backValueMethod()
                    // this.$refs.form.reset();

                    // this.$router.push("/codes/shift");
                    // this.$router.go(-1)

                }
            })

        },
    },
    props: {
        openDialogReason: { default: false },
        closeDialog: { type: Function },
        title: { type: String },
        dialog_id: { type: Number },
        tab: { type: Number },
        status: { type: Number },
        rowDialogData: { type: Object },
        backValueMethod: { type: Function },
    },
    components: { GenericInput, AnimatedLoading }
}
</script>

<style lang="scss" scoped>

</style>
