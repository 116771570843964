<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" :showSearch="false"
        :showDeleted="false" :createBtn="false">
        <template v-slot:rightSide>
          <GenericInput type="select" :lookups="selectData" :value="selectData_id" icon="mdi-chevron-down"
            @input="selectData_id = $event" label="" :multi="false" selected_label="name" selected_prop="id"
            :isLoading="false" :cols="[2, 2, 5]">
          </GenericInput>

        </template>
      </EntityHeader>
      <!-- End Of Header -->
      <v-form ref="form" v-model="valid" class="py-3 mt-6">
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel class="blue11 rounded-xl">
            <v-expansion-panel-header expand-icon="mdi-menu-down" class="text-left">

            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="overflow-hidden pa-5 transparent" elevation="0">
                <v-row class="align-center">

                  <GenericInput type="date" :value="movement_history" @input="movement_history = $event"
                    label="movement history" :clearable="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="select" :value="form.choose_exchange" @input="form.choose_exchange = $event"
                    icon="mdi-chevron-down" selected_prop="id" label="choose exchange" :clearable="true"
                    :lookups="choose_exchange" selected_label="name" :isLoading="pageData.editIsLoading"
                    :cols="[12, 6, 5]">
                  </GenericInput>

                  <v-col cols="12">
                    <p class="ma-0">{{ $t("filter") }}</p>
                  </v-col>

                  <GenericInput type="select" :value="company_id" @input="company_id = $event" icon="mdi-chevron-down"
                    selected_prop="id" label="company" :clearable="true" :lookups="filterCompany" selected_label="name"
                    :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>

                  <GenericInput type="select" :value="management_id" @input="management_id = $event"
                    icon="mdi-chevron-down" selected_prop="id" :clearable="true" label="the management"
                    :lookups="filterManagement" selected_label="name" :isLoading="pageData.editIsLoading"
                    :cols="[12, 6, 3]">
                  </GenericInput>

                  <GenericInput type="select" :value="department_id" @input="department_id = $event"
                    icon="mdi-chevron-down" selected_prop="id" :clearable="true" label="department"
                    :lookups="filterDepartment" selected_label="name" :isLoading="pageData.editIsLoading"
                    :cols="[12, 6, 3]">
                  </GenericInput>

                  <v-col cols="12" lg="3" class="pt-1 d-flex justify-end">
                    <v-btn @click="search" :disabled="btnSearchValidation" :loading="pageData.isLoading" height="34"
                      width="148" color="primary" class="white--text" elevation="0">
                      {{ $t("search") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
      <v-row>
        <v-col cols="12" sm="12">
          <v-tabs color="secondary" active-class="blue12" v-model="tab" background-color="transparent" class="mb-n2">
            <v-tab class="font-weight-bold transparent"><strong>{{ $t('exchange available') }}</strong></v-tab>
            <v-tab class="font-weight-bold transparent"><strong>{{ $t('previous exchange') }}</strong></v-tab>
          </v-tabs>
          <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
            :option="pageData.options" :pageData="pageData" :rowClicked="redirect">

            <template v-slot:actions="{ row }">

              <!-- Profile -->
              <span>
                <v-menu offset-y open-on-hover transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="gray"
                      class="cursor_pointer mx-1">mdi-account-circle-outline</v-icon>
                  </template>
                  <v-list min-width="220">
                    <v-list-item class="flex-wrap text-center">
                      <v-list-item-title class="text-start text-capitalize">
                        <div>{{ $t("done by") }}: {{ row.username }}</div>
                        <div class="mt-3">{{ $t("job title") }}: {{ row.title_name }}</div>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </span>

            </template>

            <template v-slot:td="{ row, header }">

              <!-- company name && management && department -->
              <div class="count_me" v-if="header.key === 'company_name'">
                <v-menu offset-y open-on-hover transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </div>
                  </template>
                  <v-list color="gray12" class="rounded-lg">
                    <v-list-item class="list_container flex-wrap text-center flex-column align-center pa-2">
                      <v-list-item-title class="mb-1">
                        {{ $t("company") }}: {{ row.company_name }}
                      </v-list-item-title>
                      <v-list-item-title class="mb-1">
                        {{ $t("management") }}: {{ row.department_name }}
                      </v-list-item-title>
                      <v-list-item-title class="mb-1">
                        {{ $t("department") }}: {{ row.management_name }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>

            </template>

          </DynamicTable>
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import Pagination from "@/components/ui/Pagination.vue";
export default {
  name: "DisbursementEntity",

  data: () => ({
    pageData: {
      screen_code: "09-020",
      url: null,
      controlRoute: "/transactions/disbursement_transaction-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?'
    },
    form: {

    },
    company: [],
    department: [],
    management: [],
    movement_history: "",
    company_id: "",
    department_id: "",
    management_id: "",
    choose_exchange: [],
    selectData_id: 1,
    selectData: [],
    panel: [0, 1],
    tab: 0,
    valid: false,

  }),
  components: {
    EntityHeader,
    DynamicTable,
    GenericInput,
    Pagination,
  },
  computed: {
    btnSearchValidation() {
      if (this.movement_history !== "" || this.company_id !== "" || this.department_id !== "" || this.management_id !== "") {
        return false
      } else {
        return true
      }
    },
    filterCompany() {
      if (this.management_id) {
        return this.company.filter((company) =>
          this.management.filter(management => management.id === this.management_id)
            .some(management => management.company_id === company.id));
      } else {
        return this.company;
      }
    },
    filterManagement() {
      if (this.company_id) {
        return this.management.filter(management =>
          this.company.filter(company => company.id === this.company_id)
            .some(company => company.id === management.company_id));
      } else if (this.department_id) {
        return this.management.filter(management =>
          this.department.filter(department => department.id === this.department_id)
            .some(department => department.management_id === management.id));
      } else {
        return this.management
      }

    },
    filterDepartment() {
      if (this.management_id) {
        return this.department.filter(department =>
          this.management.filter(management => management.id === this.management_id)
            .some(management => management.id === department.management_id));
      } else {
        return this.department
      }
    },

  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    tab() {
      switch (this.tab) {
        case 0:
          this.pageData.rows = []
          switch (true) {
            case this.movement_history !== '':
            case this.company_id !== '':
            case this.department_id !== '':
            case this.management_id !== '':
              this.search()
              break;
          }
          this.pageMainData()

          break;
        case 1:
          this.pageData.tableHeader = [
            { text: "serial", key: "id", type: 'text', classes: "cursor_pointer" },
            { text: "date", key: "add_date", type: 'text', classes: "cursor_pointer" },
            { text: "month calculator", key: "movement_history", type: 'text', classes: "cursor_pointer" },
            { text: "description", key: "notes", type: 'text', width: '200', classes: "cursor_pointer" },
            { text: "value", key: "total_amount_master", type: 'float', classes: "cursor_pointer" },
            { text: "number of employees", key: "employee_count", type: 'text', classes: "cursor_pointer" },
          ];
          this.pageData.isLoading = true;
          this.$api.GET_METHOD(`payment_salary${this.pageData.queryParam}&previous_fund=1`).then((response) => {
            this.pageData.entityName =
              this.pageData.isTrashed == 0
                ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                : this.$store.state.activeScreen.sub_title;
            this.pageData.isLoading = false;
            this.pageData.rows = response.data.items.data;
            this.pageData.page = response.data.items.current_page;
            this.pageData.pagination = {
              page: response.data.items.current_page,
              totalPages: response.data.items.last_page,
              per_page: response.data.items.per_page,
              totalRows: response.data.items.total,
            };

          })
          break;

      }
    },
    management_id() {
      !this.management_id ? this.department_id = '' : null
    }
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [
        { text: "serial", key: "id", type: 'text', classes: "cursor_pointer" },
        { text: "date", key: "add_date", type: 'text', classes: "cursor_pointer" },
        { text: "month calculator", key: "movement_history", type: 'text', classes: "cursor_pointer" },
        { text: "description", key: "notes", type: 'text', classes: "cursor_pointer" },
        { text: "count me", key: "company_name", type: 'slot', classes: "cursor_pointer" },
        { text: "value", key: "total_amount_master", type: 'float', classes: "cursor_pointer" },
        { text: "number of employees", key: "employee_count", type: 'text', classes: "cursor_pointer" },
        { text: "", key: "id", type: 'actions', classes: "cursor_pointer", width: '30' },
      ];
      this.selectData = [
        {
          id: 1,
          name: this.$i18n.t('view all')
        }
      ]
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: false,
        edit: false,
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: false,
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
        this.pageData.isLoading = true;
        this.viewForm = true;
        this.$api.GET_METHOD(`payment_salary`).then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.company = response.data.company
            this.management = response.data.managment
            this.department = response.data.department
          }
        })

      }

      this.choose_exchange = [
        {
          id: 1,
          name: this.$i18n.t('salaries')
        }
      ]
    },
    search() {
      this.pageData.isLoading = true;
      this.$api.GET_METHOD(`payment_salary${this.pageData.queryParam}&movement_history=${this.movement_history || ''}&company=${this.company_id || ''}&management=${this.management_id || ''}&department=${this.department_id || ''}`).then((response) => {
        this.pageData.entityName =
          this.pageData.isTrashed == 0
            ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
            : this.$store.state.activeScreen.sub_title;
        this.pageData.isLoading = false;
        this.pageData.rows = response.data.items.data;
        this.pageData.page = response.data.items.current_page;
        this.pageData.pagination = {
          page: response.data.items.current_page,
          totalPages: response.data.items.last_page,
          per_page: response.data.items.per_page,
          totalRows: response.data.items.total,
        };

      })
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`payment_salary/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`payment_salary_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/payment_salary/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
    redirect(row) {
      console.log(row);
      // pageData.controlRoute + '/' + row.id
      this.$router.push(this.pageData.controlRoute + '/' + row.id)
      // this.$router.push({ path: `${this.pageData.controlRoute}/${row.id}`, params: { history: userId } });
      // this.$router.push({ path: "/transactions/disbursement_transaction-control" + '/' + row.id, params: { history: userId } });

    }

  },
};
</script>

<style lang="scss" scoped>
.list_container,
.v-list-item {
  max-height: 103px !important;
  min-height: auto !important;
}
</style>