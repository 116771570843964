<template>
   <v-container fluid class="d-flex justify-center align-center backgroundW"
      style="height: 100vh;position: fixed; top: 0;left:0; width:100vw ; z-index: 1000;">

      <div>
         <AnimatedLogo height="100" classes="justify-center d-flex mb-5" />
         <!-- <v-progress-circular :size="40" :width="5" style="margin: auto; " class="d-block" color="primary"
            indeterminate></v-progress-circular> -->
         <AnimatedLoading :isLoading="true" :hideText="true" :height="70" />
      </div>

   </v-container>
</template>
<style  scoped>

</style>


<script>
import AnimatedLoading from './AnimatedLoading.vue';
import AnimatedLogo from './AnimatedLogo.vue';

export default {
   name: "SplashLoader",
   computed: {

   },
   components: { AnimatedLogo, AnimatedLoading },
   data: () => ({

   }),
   mounted() {

   },

   methods: {

   },
};
</script>
