<template>
    <section>
        <v-container fluid>
            <div class="container_table">
                <table>
                    <thead>
                        <tr>
                            <td rowspan="2">{{ $t('Serial') }}</td>
                            <td rowspan="2">{{ $t('Employee number') }}</td>
                            <td colspan="3" rowspan="2">{{ $t('Employee name') }}</td>
                            <td colspan="2" rowspan="2">{{ $t('The Basic salary') }}</td>
                            <td rowspan="2">{{ $t('workdays') }}</td>
                            <td :colspan="allowanceArray.length">{{ $t('allowance') }}</td>
                            <td colspan="2" rowspan="2">{{ $t('total Salary') }}</td>
                            <td :colspan="deductionsArray.length">{{ $t('Deductions') }}</td>
                            <td colspan="2" rowspan="2">{{ $t('Total Deductions') }}</td>
                            <td colspan="2" rowspan="2">{{ $t('Net Salary') }}</td>
                            <td colspan="3" rowspan="2">{{ $t('notes') }}</td>
                        </tr>
                        <tr style="background-color:  transparent !important ;">
                            <td v-for="item in allowanceArray" :key="item.type" >{{ item.type}}</td>
                            <td v-for="item in deductionsArray" :key="item.type" >{{ item.type}}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="result_row">
                            <td>1</td>
                            <td>{{ '0'}}</td>
                            <td colspan="3">{{ employeeTempName }}</td>
                            <td colspan="2" class="bak_effect">{{ 0 }}</td>
                            <td>{{ 0 | float }}</td>
                            <td v-for="item in allowanceArray" :key="item.name">{{ item.amount | float }}</td>
                            <td colspan="2" class="bak_effect">{{ 0 }}</td>
                            <td v-for="item in deductionsArray" :key="item.name">{{ item.amount | float }}</td>
                            <td colspan="2" class="bak_effect">{{ 0 | float }}</td>
                            <td colspan="2" class="bak_effect">{{ 0 | float }}</td>
                            <td colspan="3">-</td>
                        </tr>
                        <tr class="total">
                            <td colspan="2">-</td>
                            <td colspan="2">{{ $t('the total') }}</td>
                            <td colspan="4" class="bak_effect">{{ 0}}</td>
                            <td v-for="(item,i) in allowanceArray" :key="i">0</td>
                            <td colspan="2" class="bak_effect">{{ 0 | float }}</td>
                            <td v-for="(item,i) in deductionsArray" :key="i">0</td>
                            <td colspan="2" class="bak_effect">{{ 0 | float }}</td>
                            <td colspan="2" class="bak_effect">{{ 0 | float }}</td>
                            <td colspan="3">-</td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <!-- <div class="container_table">
                <table>
                    <thead>
                        <tr>
                            <td rowspan="2">{{ $t('Serial') }}</td>
                            <td rowspan="2">{{ $t('Employee number') }}</td>
                            <td colspan="3" rowspan="2">{{ $t('Employee name') }}</td>
                            <td colspan="2" rowspan="2">{{ $t('The Basic salary') }}</td>
                            <td rowspan="2">{{ $t('workdays') }}</td>
                            <td :colspan="allowanceArray.length">{{ $t('allowance') }}</td>
                            <td colspan="2" rowspan="2">{{ $t('total Salary') }}</td>
                            <td :colspan="deductionsArray.length">{{ $t('Deductions') }}</td>
                            <td colspan="2" rowspan="2">{{ $t('Total Deductions') }}</td>
                            <td colspan="2" rowspan="2">{{ $t('Net Salary') }}</td>
                            <td colspan="3" rowspan="2">{{ $t('notes') }}</td>
                        </tr>
                        <tr style="background-color:  transparent !important ;">
                            <td v-for="item in allowanceArray" :key="item.type" >{{ item.type}}</td>
                            <td v-for="item in deductionsArray" :key="item.type" >{{ item.type}}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="result_row">
                            <td>1</td>
                            <td>{{ employeeData.id }}</td>
                            <td colspan="3">{{ `${this.employeeData.employee_name_ar} ${this.employeeData.family_name_ar}`
                            }}</td>
                            <td colspan="2" class="bak_effect">{{ employeeData.salary_fixed }}</td>
                            <td>{{ 0 | float }}</td>
                            <td v-for="item in allowanceArray" :key="item">{{ item.amount | float }}</td>
                            <td colspan="2" class="bak_effect">{{ employeeData.salary_total }}</td>
                            <td v-for="item in deductionsArray" :key="item">{{ item.amount | float }}</td>
                            <td colspan="2" class="bak_effect">{{ 0 | float }}</td>
                            <td colspan="2" class="bak_effect">{{ 0 | float }}</td>
                            <td colspan="3">-</td>
                        </tr>
                        <tr class="total">
                            <td colspan="2">-</td>
                            <td colspan="2">{{ $t('the total') }}</td>
                            <td colspan="4" class="bak_effect">{{ employeeData.salary_total }}</td>
                            <td v-for="(item,i) in allowanceArray" :key="i">0</td>
                            <td colspan="2" class="bak_effect">{{ 0 | float }}</td>
                            <td v-for="(item,i) in deductionsArray" :key="i">0</td>
                            <td colspan="2" class="bak_effect">{{ 0 | float }}</td>
                            <td colspan="2" class="bak_effect">{{ 0 | float }}</td>
                            <td colspan="3">-</td>
                        </tr>
                    </tbody>
                </table>
            </div> -->
        </v-container>
    </section>
</template>

<script>

export default {
    name: "SalaryReportTable",

    data: () => ({
        allowanceArray: [],
        deductionsArray: []
    }),
    props: {
        employeeData: { default: Object },
        employeeTempName: { default: String }
    },
    components: {

    },

    computed: {


    },
    watch: {

    },
    mounted() {
        this.allowanceArray = [
            {
                "type": "راتب اساسي",
                "amount": 0
            },
            {
                "type": "بدل سكن",
                "amount": 0
            },
            {
                "type": "بدل انتقال",
                "amount": 0
            },
            {
                "type": "بدل هاتف",
                "amount": 0
            },
            {
                "type": "بدل اضافي",
                "amount": 0
            },

        ]
        this.deductionsArray = [
            {
                "type": "جزاءات",
                "amount": 0
            },
            {
                "type": "غياب",
                "amount": 0
            },
            {
                "type": "سلفة",
                "amount": 0
            },
            {
                "type": "تسوية مدينة حوادث",
                "amount": 0
            },
        ]
    },
    methods: {


    },
};
</script>

<style lang="scss" scoped>
.container_table {
    thead td {
        background-color: #dcdcdc !important;
    }

    tr:nth-child(even) {
        background-color: white;
    }

    .bak_effect {
        background-color: #dcdcdc;
    }

    table {
        border: 2px solid black !important;
    }

    table {
        text-align: center;
        margin: auto;
        width: 100%;
        border-spacing: 0;
        overflow: hidden;
    }

    td {
        padding: 2px;
        border: 0.1px solid #000000ba !important;
    }

    tbody .result_row:nth-child(1) td {
        border-top: 3px solid black !important;        
    }

    @media print {

        html,
        body {
            width: 210mm;
            height: 297mm;
        }
    }
}
</style>