<template>
    <v-dialog v-model="openDialog" persistent width="550">
        <AnimatedLoading style="padding-top:10rem; height:475px" classes="white  ma-auto " :hideText="false"
            :isLoading="loading" v-if="loading" />
        <v-form ref="form" v-model="valid">
            <v-card v-if="!loading" class="pa-4">
                <v-card-actions class="mb-7">
                    <v-card-text class="font-weight-bold text-h6 text-capitalize pa-0">
                        {{ $t('reschedule the advance') }}
                    </v-card-text>

                    <v-btn small icon color="backgroundW" @click="close">
                        <v-icon color="red5">mdi-close</v-icon>
                    </v-btn>
                </v-card-actions>

                <v-row class="text-center">
                    <v-col cols="12" class="d-flex justify-start">
                        <h4> {{ $t('the remaining advance amount') }} : {{ remainLoanAmount | float }} {{ $t("ryal") }}</h4>
                    </v-col>


                    <GenericInput type="number" :value="form.installments_count" @input="form.installments_count = $event"
                        label="number of installments" :multi="false" selected_label="name" selected_prop="id"
                        :isLoading="false" :required="true" :cols="[12, 12, 12]">
                    </GenericInput>

                    <!-- calculation start -->
                    <v-col cols="12" sm="12" lg="12" class="py-1">
                        <v-menu ref="menuDate" v-model="menuDate" :close-on-content-click="false"
                            transition="scale-transition" offset-y max-width="290px" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="form.start_date" background-color="white" dense outlined
                                    :label="$t('calculation start')" :rules="[rules.required]" append-icon="mdi-calendar"
                                    readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="form.start_date" :locale="$vuetify.rtl ? 'ar' : 'en'" type="month" title
                                scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menuDate = false">
                                    Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.menuDate.save()">
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <!-- calculation start -->

                    <v-col cols="12" class="d-flex justify-start" v-if="form.installments_count">
                        <h5>
                            {{ $t('monthly deduction') }} : {{ monthlyDeduction | float }} {{ $t("ryal") }}
                        </h5>
                    </v-col>

                    <v-col class="text-end">
                        <v-btn class="error--text " :width="120" depressed @click="close">
                            {{ $t("back") | capitalize }}
                        </v-btn>
                        <v-btn color="success" depressed :width="120" :disabled="!valid" @click="save"
                            class="rounded-lg font-weight-bold white--text " :loading="loading"
                            :style="$vuetify.rtl ? 'margin-right: 30px' : 'margin-left: 30px'">
                            {{ $t('save') }}
                        </v-btn>
                    </v-col>
                </v-row>

            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import GenericInput from '@/components/ui/GenericInput.vue';
export default {
    name: "RecalculateLoanDialog",

    data: () => ({
        form: {
            rest_amount: null,
            start_date: null,
            installments_count: null
        },
        rules: {
            required: value => !!value || 'Required.',
        },
        menuDate: false,
        valid: false,
        loading: false,
    }),

    methods: {
        save() {
            this.form.rest_amount = this.remainLoanAmount
            this.loading = true
            this.form._method = 'PUT'
            this.$api.POST_METHOD(`re_installment/${this.$route.params.id}`, this.form).then((response) => {
                this.loading = false
                if (response.check) {
                    console.log("this.form", this.form);
                    console.log("res post", response);
                    this.updateRemainLoan(response.data);
                    this.close()
                }
            })
        },
        close() {
            this.closeDialog();
            this.$refs.form.reset();
            this.form.rest_amount = null
            this.form.start_date = null
            this.form.installments_count = null
        }
    },
    computed: {
        monthlyDeduction() {
            return this.form.installments_count && this.remainLoanAmount / this.form.installments_count
        }
    },



    props: {
        openDialog: { default: false },
        closeDialog: { type: Function },
        updateRemainLoan: { type: Function },
        remainLoanAmount: { default: Number },
    },
    components: { GenericInput, AnimatedLoading }
}


</script>

<style lang="scss" scoped>
.choose-employee {
    width: 100%;
    justify-content: space-between;
    position: relative;
}

.numberOfDays {
    margin-left: -10px;
}

.label {
    // width: 13rem;
    padding-top: 6px;
}

.return-time {
    width: 100%
}

.checkOut {
    border: 1px solid #ECEEF0;
    ;
    padding: 8px 30px;
    border-radius: 6px;
    margin-left: 1rem;
    font-size: 19px !important
}

.mr-n11 {
    left: 1rem !important;
    // right:30px
}
</style>
