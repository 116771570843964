<template>
    <v-dialog v-model="openDialog" persistent max-width="700">
        <v-card>
            <v-card-actions>
                <v-card-text class="font-weight-bold text-h6 text-capitalize">
                    {{ $t("add a new employee") }}
                </v-card-text>
                <v-btn absolute class="mt-2" :style="$vuetify.rtl ? 'left: 10px' : 'right: 10px'" small fab color="red5"
                    text @click="closeDialog">
                    <v-icon color="red5">mdi-close</v-icon>
                </v-btn>
            </v-card-actions>
            <v-card-text>
                <v-row class="text-center pa-5">
                    <v-col cols="6">
                        <v-btn @click="redirect(1)"
                            class="rounded-pill px-16 py-16 primary--text font-weight-bold text-h6" width="240"
                            elevation="0">
                            {{ $t("citizen") }}
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn @click="redirect(2)"
                            class="rounded-pill px-16 py-16 primary--text font-weight-bold text-h6" width="240"
                            elevation="0">{{ $t("resident") }}</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "EmployeesTypeDialog",
    data: () => ({
    }),
    methods: {
        redirect(id) {
            this.$router.push({ name: 'EmployeesControlAdd', params: { data: id } });
        },
    },
    props: {
        openDialog: { default: false },
        closeDialog: { type: Function },
        employee_type: { type: String },
    }


}
</script>

<style lang="scss" scoped>

</style>
