<template>
    <v-dialog v-model="openDialog" max-width="630" persistent>
        <v-card relative class="pa-3">
            <v-form ref="form" v-model="valid" @submit="save">
                <div>
                    <v-row class="text-h6  font-weight-bold align-top my-2">
                        <v-col cols="auto" class="font-weight-bold text-h6 text-capitalize">
                            {{ $t("add vacation") }}
                        </v-col>
                        <v-col cols="auto" class="text-subtitle-1">
                            <span class="font-weight-bold">{{ $t("total vacation") }}</span>
                            <span>
                                {{ total_vacation || 0 }} {{ $t("day") }}
                                <strong class="mx-2">-</strong>
                            </span>
                            <span class="font-weight-bold">{{ $t("the rest") }}</span>
                            <span v-if="!DilaogIsEdit" :class="{'error--text': calcRemainingVacation - form.days_count < 0}">
                                {{ calcRemainingVacation - form.days_count }} {{ $t("day") }}
                            </span>
                            <span v-else :class="{'error--text': (total_vacation - allVacationExceptOne) - form.days_count < 0}"> 
                                {{ (total_vacation - allVacationExceptOne) - form.days_count}} {{ $t("day") }}
                            </span>
                        </v-col>

                        <v-col cols="auto">
                            <v-btn absolute class="mt-0" :style="$vuetify.rtl ? 'left: 10px' : 'right: 10px'" small fab
                                color="red5" text @click="close">
                                <v-icon color="red5">mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-col cols="12" class="py-2">
                        <v-row align="center">
                            <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                                :required="true" :cols="[12, 6, 6]">
                            </GenericInput>

                            <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en"
                                :required="true" :cols="[12, 6, 6]">
                            </GenericInput>

                            <GenericInput type="select" :value="form.salary_calculator"
                                @input="form.salary_calculator = $event" selected_prop="id" icon="mdi-chevron-down"
                                label="accounting method" :lookups="salary_calculator" selected_label="name"
                                :required="true" :cols="[12, 6, 6]">
                            </GenericInput>

                            <GenericInput type="number" :value="Math.abs(form.days_count)" @input="form.days_count = $event"
                                label="the number of days" :required="true" :cols="[12, 6, 6]">
                            </GenericInput>
                        </v-row>

                        <v-row align="center" justify="end">
                            <v-col cols="auto">
                                <v-btn depressed text @click="close" :width="120"
                                    class="rounded-lg font-weight-bold white--text" color="error"
                                    :loading="saveDataLoading">
                                    {{ $t('cancel') }}
                                </v-btn>
                            </v-col>

                            <v-col cols="auto">
                                <v-btn color="success" depressed :width="120" type="submit" :disabled="!valid || alertMassege" @click="save"
                                    class="rounded-lg font-weight-bold white--text" :loading="saveDataLoading">
                                    {{ $t('save') }}
                                </v-btn>
                            </v-col>

                            <v-col cols="12">
                                <v-fade-transition>
                                    <v-alert type="error" dense outlined v-if="alertMassege" class="text-caption">
                                        {{ $t('vacation_details_alert') }} {{ calcRemainingVacation }} {{ $t("day") }}
                                    </v-alert>
                                </v-fade-transition>
                            </v-col>
                        </v-row>
                    </v-col>
                </div>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import GenericInput from '@/components/ui/GenericInput.vue'
export default {
    name: "AddAnnualDialog",
    data: () => ({
        date: null,
        valid: false,
        saveDataLoading: false,
        salary_calculator: [],
        controlRoute: "/code/qualify-control",
        form: {
            salary_calculator: null,
            name_en: null,
            name_ar: null,
            days_count: null,
        }
    }),
    computed: {
        alertMassege() {
            if (this.DilaogIsEdit) {
                // Add New Item
                return (this.form.days_count + this.allVacationExceptOne) > this.total_vacation ? true : false
            } else if (!this.DilaogIsEdit) {
                // Update Item 
                return this.form.days_count > this.calcRemainingVacation ? true : false
            } else return false


        }
    },
    methods: {
        save() {
            this.$refs.form.validate()
            if (this.$refs.form.validate() && !this.alertMassege) {
                if (!this.DilaogIsEdit && !this.pageData_isEdit) {
                    // Add New Item
                    console.log("Add New Item");
                    this.vacation_details.push({ ...this.form })
                }
                if (!this.DilaogIsEdit && this.pageData_isEdit) {
                    // Add New Item in Edit 
                    console.log("Add New Item in Edit");
                    // this.vacation_details.push({ id: 0, ...this.form })
                    this.form.id = 0;
                    this.vacation_details.push({ ...this.form })
                }
                if (this.DilaogIsEdit) {
                    // Update Item with same Position
                    console.log("Update Item with same Position");
                    this.after_update(this.form, this.update_details_index)
                }
                this.close()
            }
        },
        close() {
            this.$refs.form.reset()
            this.closeDialog()
        }

    },
    mounted() {
        this.salary_calculator = [
            {
                id: 1,
                name: this.$i18n.t("all salary")
            },
            {
                id: 2,
                name: this.$i18n.t("main salary")
            },
            {
                id: 3,
                name: this.$i18n.t("without salary")
            }
        ]
    },
    watch: {
        DilaogIsEdit() {
            if (this.DilaogIsEdit) {
                this.form = { ...this.update_details_data }
            } else {
                this.$refs.form.reset()
            }
        }
    },
    props: {
        openDialog: { default: false },
        pageData_isEdit: { default: false },
        closeDialog: { type: Function },
        calcRemainingVacation: { type: Number },
        after_update: { type: Function },
        vacation_details: { type: Array },
        update_details_data: { type: Object },
        update_details_index: { type: Number },
        total_vacation: { default: Number },
        DilaogIsEdit: { type: Boolean },
        allVacationExceptOne: { default: Number },
    },
    components: { GenericInput }
}
</script>

<style lang="scss" scoped></style>
