<template>
  <section>
    <v-container fluid class="d-flex  justify-space-around">

    </v-container>
  </section>
</template>

<script>

export default {
  name: "MainPage",

  data: () => ({

  }),
  components: {

  },
  watch: {

  },
  computed: {

  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>

</style>