<template>
   <div>
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
         :paginate-elements-by-height="$store.state.Settings.windowSize.x" :filename="'multi_voucher'" :pdf-quality="2"
         :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait"
         :pdf-content-width="$store.state.Settings.windowSize.y" ref="html2Pdf">
         <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">
            <PrintPDF :header="tableHeader" :maxPDFRows="25" :data="printData || [{}]" :header_slot="false"
               :content_slot="false" :footer_slot="false" :showGroupHeader="false" :groupHeader="false" :footerData="[]"
               :customize_header_footer="false" :printStaticData="Object" >
               <template v-slot:pdf_content="{ page }">
                  <!-- table_data, isLastPage -->

                  <div id="title" v-if="page == 1">
                     {{ $t('') }}
                  </div>
                  <!-- Title -->
                  <!-- <div class="salary_reportPrint_center" v-if="page == 1">
                     <h1>{{ $t('salary report') }}</h1>
                  </div> -->

                  <!-- <div class="reportPrint_employee_info" v-if="page == 1">
                     <span><strong>{{ $t('employee number') }}: </strong> {{ printStaticData.master.employee.id }}</span>
                     <span><strong>{{ $t('name') }}: </strong>
                        {{ printStaticData.master.employee.employee_name_ar + " " +
                           printStaticData.master.employee.father_name_ar + " " +
                           printStaticData.master.employee.grand_father_name_ar + " " +
                           printStaticData.master.employee.family_name_ar }}
                     </span>
                     <span><strong>{{ $t('position') }}: </strong>
                        {{ printStaticData.master.employee.job_name }}
                     </span>
                     <span><strong>{{ $t('the company') }}: </strong>
                        {{ printStaticData.master.employee.company_name }}
                     </span>
                     <span><strong>{{ $t('the management') }}: </strong>
                        {{ printStaticData.master.employee.management_name }}
                     </span>
                     <span><strong>{{ $t('the department') }}: </strong>
                        {{ printStaticData.master.employee.department_name }}
                     </span>
                  </div>

                  <table width="100%" class="salary_reportPrint_table">
                     <tr>
                        <td>{{ $t('benefits') }} </td>
                        <td>{{ $t('deductions') }} </td>
                     </tr>
                     <tr v-for="(salary, index) in table_data" :key="index">
                        <td>{{ salary.benefits_amount | float }} {{ salary.benefits_type }} </td>
                        <td>{{ salary.deductions_amount | float }} {{ salary.deductions_type }} </td>
                     </tr>
                     <tr v-if="isLastPage">
                        <td>
                           {{ total_benefits | float}}
                           {{ $t('total benefits') }}
                        </td>
                        <td>
                           {{ total_deductions | float }}
                           {{ $t('total deductions') }}
                        </td>
                     </tr>
                  </table>

                  <div class="reportPrint_employee_total" v-if="isLastPage">
                     <span>
                        <strong>{{ $t('net salary') }}: </strong>
                        {{ total_benefits - total_deductions | float }} <br>
                        {{ total_benefits - total_deductions | tafqeet }}
                     </span>
                     <span><strong>{{ $t('exchange method') }}: </strong>
                        {{ $global.FilterArrayOfObjectElement(payment_method, "id",
                           printStaticData.master.employee.payment_method, "name") }}
                     </span>
                  </div>

                  <div class="reportPrint_footer" v-if="isLastPage">
                     <span><strong>{{ $t('receive') }}: </strong><br>
                        <hr>
                     </span>
                     <span><strong>{{ $t('HR') }}: </strong><br>
                        <hr>
                     </span>
                  </div> -->
                  <SalaryReportTable :employeeData="employeeData" />

               </template>
            </PrintPDF>
         </section>
      </vue-html2pdf>


   </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import SalaryReportTable from './SalaryReportTable.vue';
export default {
   name: "SalaryReportPrint",
   props: {
      printAction: { type: Function },
      print: { default: false },
      printData: { default: [] },
      printStaticData: { default: Object },
      employeeData: { default: Object }
   },
   computed: {

   },
   components: {
    VueHtml2pdf,
    SalaryReportTable
},
   watch: {
      print() {
         if (this.print) {
            this.$refs.html2Pdf.generatePdf();
            this.total_benefits = this.printData.reduce((prev, current) => prev + +current.benefits_amount, 0)
            this.total_deductions = this.printData.reduce((prev, current) => prev + +current.deductions_amount, 0)
            this.payment_method = [
               {
                  id: 1,
                  name: this.$i18n.t('my bank')
               },
               {
                  id: 2,
                  name: this.$i18n.t('cash')
               },
               {
                  id: 3,
                  name: this.$i18n.t('the transfer')
               }
            ]
         }

      },
   },
   data: () => ({
      tableHeader: [],
      payment_method: [],
      total_benefits: 0,
      total_deductions: 0,
   }),
   methods: {

   },
   mounted() {

   },


};
</script>

<style lang="scss" >
// .salary_reportPrint_table {
//    margin: 25px 0;
//    -webkit-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
//    -moz-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
//    box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
//    border-collapse: collapse;
//    border: 1px solid #e9e8ee;
//    border-bottom: 8px solid #28aae1;
//    // border-right: 8px solid #28aae1;

//    tr:nth-child(1) {
//       background: #d8f1ff;
//       height: 60px !important;
//       font-weight: 900 !important;
//    }

//    tr:last-child {
//       height: 60px !important;
//       font-weight: 900 !important;
//    }

//    tr:nth-of-type(even) {
//       background-color: unset;
//    }


//    tr {
//       border-bottom: 1px solid #ddd !important;

//       td {
//          padding: 5px !important;
//          font-weight: 600;

//          &:nth-of-type(odd) {
//             text-align: center !important;
//             border-right: 0 !important;
//             border-left: 1px solid #ddd !important;
//             width: 50%;
//             padding: 5px 10px !important;
//             border: 1px;
//          }

//          &:nth-of-type(even) {
//             text-align: center !important;
//          }

//          &:last-of-type {
//             border-left: 0 !important;
//          }
//       }

//       &:last-of-type {
//          border-bottom: 0 !important;

//          td {
//             border-bottom: 0 !important;
//          }
//       }

//    }
// }

// .salary_reportPrint_center {
//    text-align: center;
//    width: 300px;
//    margin: auto;
//    border-bottom: 3px solid #80ceff;
//    text-align: center;
//    padding-bottom: 10px;
// }

// .salary_reportPrint_center h1 {
//    color: #094278;
// }

// .reportPrint_employee_info {
//    display: flex;
//    justify-content: space-between;
//    flex-wrap: wrap;
//    margin-top: 25px;
//    column-gap: 40px;
//    row-gap: 5px;
// }

// .reportPrint_employee_total {
//    display: flex;
//    justify-content: space-between;
//    flex-wrap: wrap;
//    align-items: center;
// }

// .reportPrint_footer {
//    display: flex;
//    justify-content: space-between;
//    flex-wrap: wrap;
//    margin-top: 90px;
//    margin-bottom: 10px;
//    text-align: center;
// }

// .reportPrint_footer span hr {
//    width: 200px;
//    height: 0.5px !important;
// }

// .reportPrint_footer span strong {
//    display: block;
// }


</style>