<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :showSearch="false" :createBtn="false" :showDeleted="false" :getData="getData"
        :getSearch="getSearch">
        <template slot="leftSide">
          <v-col cols="12" md="6" class="pa-0 d-flex align-center">
            <GenericInput type="select" :lookups="companies" :value="Number(company_id)" @input="company_id = $event"
              label="select company" :multi="false" :required="false" selected_label="name" selected_prop="id"
              :isLoading="pageData.isLoading" :cols="[11, 11, 11]">
              <template v-slot:append>
                <v-icon>mdi-magnify</v-icon>
              </template>
            </GenericInput>
          </v-col>
        </template>
        <template slot="rightSide">
          <v-col cols="12" md="auto" class="py-0 d-flex align-center cursor_pointer"
            @click="show_employees = show_employees == 1 ? 0 : 1">
            <img src="@/assets/img/svg/employee_show.svg" v-if="show_employees == 0" height="20" alt="" />
            <img src="@/assets/img/svg/employee_hidden.svg" v-if="show_employees == 1" height="20" alt="" />
            <div class="body-2 px-2">{{ show_employees == 0 ? $t('view employees') : $t('hide employees') }}</div>
          </v-col>
        </template>

      </EntityHeader>
      <!-- End Of Header -->


      <v-col cols="12" class="mt-5">
        <div v-if="isStructureLoading" class="py-16">
          <AnimatedLoading :height="100" :hideText="false" :isLoading="isStructureLoading" />
        </div>
        <organization-chart :datasource="dataSource || {}" class="overflow-y-auto" id="dragScreen" :style="{
          height: $store.state.Settings.windowSize.y - 155 + 'px',
          border: 'none !important;',
        }" :class="{ 'd-none': isStructureLoading }">
          <template slot-scope="{ nodeData }">
            <v-hover v-slot="{ hover }">
              <v-card  class="backgroundW rounded-lg primary--border  pa-5" width="250"
                :class="hover ? 'primary--border shadow-md' : 'shadow-sm'">
                <div class="subtitle-1 font-weight-bold text-center">
                  <span v-if="nodeData.level == 1"> {{ $t('company') | capitalize }} /</span>
                  <span v-else-if="nodeData.level == 2"> {{ $t('management') | capitalize }} /</span>
                  <span v-else-if="nodeData.level == 3"> {{ $t('department') | capitalize }} /</span>
                  <span v-else> {{ $t('employee') }} /</span>
                  {{ nodeData.name || nodeData.job_name || '-' | capitalize }}
                </div>
                <div class="body-2 text-center">
                  {{ nodeData.manager_name || nodeData.employee_name || '-' | capitalize }}</div>
                <div class="caption text-center primary--text">
                  <span v-if="nodeData.level == 1"> {{ nodeData.children.length + ' ' + $t('managements') |
                    capitalize }}</span>
                  <span v-else-if="nodeData.level == 2"> {{ nodeData.children.length + ' ' + $t('departments') |
                    capitalize }}</span>
                  <span v-else-if="nodeData.level == 3 && nodeData.children.length > 0">
                    {{ nodeData.children.length + ' ' + $t('employees') | capitalize }}</span>
                  <span v-else>
                    <v-avatar v-if="!nodeData.level" size="40" class="rounded-circle "
                      v-lazy-container="{ selector: 'img' }">
                      <img :data-src="$store.state.endpointURL + nodeData.image"
                        class="rounded-circle pa-2 d-block ma-auto" alt="">
                    </v-avatar>
                  </span>
                </div>
                <v-row justify="center" align="center" class="controls mt-2" v-if="hover">
                  <v-col cols="auto" class="py-0">
                    <v-btn icon small @click="editNode(nodeData)" class="primary white--text">
                      <v-icon size="18">mdi-pencil</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto" class="py-0" v-if="nodeData.level == 1 || nodeData.level == 2">
                    <v-btn icon small @click="addNode(nodeData)" class="success white--text">
                      <v-icon size="18">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-hover>
          </template>
        </organization-chart>
      </v-col>

    </v-container>

    <AddStructureNode v-if="addNodeDialog" :backValueMethod="addStructureBackMethod" :dialog="addNodeDialog" :selectedNode="selectedNode"
      :company_id="company_id" :employees="employees" :managements="managements" />

    <EditStructureNode v-if="editNodeDialog" :backValueMethod="editStructureBackMethod" :dialog="editNodeDialog" :selectedNode="selectedNode"
      :employees="employees" :managements="managements" :company_id="company_id"/>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import AnimatedLoading from "@/components/ui/AnimatedLoading.vue";
import AddStructureNode from "./AddStructureNode.vue";
import EditStructureNode from "./EditStructureNode.vue";
export default {
  name: "AdminStructureEntity",

  data: () => ({
    pageData: {
      screen_code: "09-001",
      url: null,
      controlRoute: "codes/admin_structure-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?'
    },
    companies: [],
    employees: [],
    managements: [],
    company_id: null,
    show_employees: 0,
    isStructureLoading: false,
    dataSource: {},
    addNodeDialog: false,
    editNodeDialog: false,
    selectedNode: null,
  }),
  components: {
    EntityHeader,
    AddStructureNode,
    EditStructureNode,
    GenericInput,
    AnimatedLoading
  },
  computed: {},
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.dragScreen()
    this.company_id = this.$store.state.userData.user.current_company
    this.getData();
  },
  watch: {
    company_id() {
      if (this.company_id && !this.pageData.isLoading)  {
        this.datasource = null;
        this.pageData.queryParam = `?company_id=${this.company_id}&show_employees=${this.show_employees}`
        this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
      }
    },
    show_employees() {
      if (this.company_id) {
        this.datasource = null;
        this.pageData.queryParam = `?company_id=${this.company_id}&show_employees=${this.show_employees}`
        this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
      }
    },
    $route() {
      this.pageData.queryParam = '?';
      this.datasource = null;
      this.queryParams();
      this.pageMainData();
      this.getData();

    },
  },
  methods: {
    queryParams() {
      this.company_id = this.$route.query.company_id ?? '';
      this.show_employees = this.$route.query.show_employees ?? 0;
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [
        { text: "#", key: "id", type: 'text', classes: "" },
        { text: "name_ar", key: "name_ar", type: 'text', classes: "" },
        { text: "name_en", key: "name_en", type: 'text', classes: "" },
        { text: "relative account", key: "account_name", type: 'text', width: '200', classes: "" },
        { text: "credit date", key: "credit_date", type: 'text', classes: "" },
        { text: "grace period", key: "grace_period", type: 'text', classes: "" },
        { text: "actions", key: "id", type: 'actions', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch() {
      this.pageData.queryParam = `?company_id=${this.company_id}&show_employees=${this.show_employees}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.isStructureLoading = true;
        this.$api.GET_METHOD(`get_company_structure?company_id=${this.company_id}&show_employees=${this.show_employees}`).then((response) => {
          this.pageData.isLoading = false;
          this.isStructureLoading = false;
          if (response.check) {
            this.pageData.entityName = this.$store.state.activeScreen.sub_title;
            this.employees = response.data.employess || [];
            this.companies = response.data.companys || [];
            this.managements = response.data.managements || [];
            this.dataSource = {
              id: response.data.items[this.company_id].id,
              name: response.data.items[this.company_id].name,
              manager_id: response.data.items[this.company_id].manager_id,
              manager_name: response.data.items[this.company_id].manager_name,
              level: response.data.items[this.company_id].level,
              children: []
            }
            Object.keys(response.data.items[this.company_id].children).forEach(key => {
              this.dataSource.children.push(response.data.items[this.company_id].children[key])
            });
          }
        });
      } else {
        this.$router.push("/");
      }
    },

    dragScreen() {
      const slider = document.getElementById("dragScreen");
      let mouseDown = false;
      let startX, scrollLeft, startY, scrollTop;
      let startDragging = function (e) {
        mouseDown = true;
        startX = e.pageX - slider.offsetLeft;
        startY = e.pageY - slider.offsetTop;
        scrollLeft = slider.scrollLeft;
        scrollTop = slider.scrollTop;
      };
      let stopDragging = function () {
        mouseDown = false;
      };
      slider.addEventListener('mousemove', (e) => {
        e.preventDefault();
        if (!mouseDown) { return; }
        const x = e.pageX - slider.offsetLeft;
        const y = e.pageY - slider.offsetTop;
        const scroll = x - startX;
        const scrollY = y - startY;
        slider.scrollLeft = scrollLeft - scroll;
        slider.scrollTop = scrollTop - scrollY;
      });
      slider.addEventListener('mousedown', startDragging, false);
      slider.addEventListener('mouseup', stopDragging, false);
      slider.addEventListener('mouseleave', stopDragging, false);
    },
    addStructureBackMethod(status) {
      if (status) {
        this.getData();
      }
      this.addNodeDialog = false;
    },
    editStructureBackMethod(status, close) {
      if (status) {
        this.getData();
      }
      this.editNodeDialog = !close;
    },

    editNode(node) {
      switch (node.level) {
        case 1:
          this.$router.push(`/codes/company-control/${node.id}`)
          break;
        case 2:
        console.log('mangenet',node);
          this.selectedNode = node;
          this.editNodeDialog = true;
          break;
        case 3:
          console.log('derpartment',node);
          this.selectedNode = node;
          this.editNodeDialog = true;
          break;

        default:
          this.$router.push(`/codes/employee-control/${node.id}`)
          break;
      }

    },
    addNode(node) {
      this.selectedNode = node;
      console.log('add node', node);
      this.addNodeDialog = true;
    }
  },
};
</script>
<style lang="scss" scoped>
#dragScreen {
  display: flex;
  overflow-x: hidden !important;
  width: 100%;
  cursor: grab !important;

  &::-webkit-scrollbar {
    width: 0;
    opacity: 0;
  }

}

.controls {
  position: absolute;
  width: 100%;
}
</style>