<template>
    <v-dialog v-model="openDialogSug" persistent max-width="500">
        <v-form ref="form">
            <v-card>

                <v-card-actions>

                    <v-btn absolute class="mt-10" :style="$vuetify.rtl ? 'left: 10px' : 'right: 10px'" small fab
                        color="red5" text @click="closeDialog">
                        <v-icon color="red5">mdi-close</v-icon>
                    </v-btn>
                </v-card-actions>


                <v-card-text>
                    <v-row class="text-center">
                        <div class="text-h6 pa-4 blue1--text font-weight-bold">{{ $t("suggestion") }}</div>

                        <v-card-text class="text-start">

                            <!-- <div class="text-h8 pa-4 blue1--text ">{{$t('amount offered')}}</div> -->
                            <GenericInput class="" type="textarea" :rowsNumber="6" :value="form.notes"
                                @input="form.notes = $event" label="amount offered" :multi="false" :required="false"
                                selected_label="" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]">
                            </GenericInput>

                        </v-card-text>




                        <v-card-actions class="d-block mi-start-auto">
                            <v-btn class="error--text mr-10 ml-10" depressed @click="closeDialog">{{ $t("back") }}
                            </v-btn>

                            <v-btn color="success" depressed :width="120"
                                class="rounded-lg font-weight-bold white--text " @click="closeDialog">
                                {{ $t('send') }}
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import GenericInput from '@/components/ui/GenericInput.vue';
export default {
    name: "DialogSuggestion",
    data() {
        return {

            form: {



            },
        };
    },



    watch: {
        closeDialog() {
            this.$refs.form.reset();

        },

    },
    methods: {
        // edit() {
        //     this.$router.push('employees-control');
        // },
    },
    props: {
        openDialogSug: { default: false },
        closeDialog: { type: Function },
        rowDialogData: { type: Object },
        title: { type: String },
        dialog_id: { type: Number },
        tab: { type: Number },
    },
    components: { GenericInput }
}
</script>

<style lang="scss" scoped>

</style>
