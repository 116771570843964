<template>
    <v-dialog v-model="openInstallment" persistent max-width="450">
        <v-card class="pa-3">
            <v-card-actions>
                <v-card-text class="font-weight-bold text-h6 text-capitalize">
                    {{ handelHeader() }}
                </v-card-text>
                <v-btn absolute class="mt-2" :style="$vuetify.rtl ? 'left: 10px' : 'right: 10px'" small fab color="red5"
                    text @click="closeInstallment">
                    <v-icon color="red5">mdi-close</v-icon>
                </v-btn>
            </v-card-actions>
            <v-form ref="form" v-model="valid">
                <v-row class="text-center pa-5 justify-end">
                    <GenericInput type="number" :value="form.amount" @input="form.amount = $event" label="deserved amount"
                        :disabled="true" :isLoading="isLoading" :cols="[12, 6, 12]">
                    </GenericInput>
                    <GenericInput type="date" :minDate="$global.GetCurrentDate()" :value="date" @input="date = $event"
                        :label="rowInstallmentData.key == 'pay' ? 'date of payment' : 'postponed to date ?'"
                        :required="true" :isLoading="isLoading" :cols="[12, 6, 12]">
                    </GenericInput>
                    <v-col cols="auto">
                        <v-btn class="success white--text" @click="save" width="136" text elevation="0"
                            :loading="isLoading">
                            {{ this.rowInstallmentData.key == 'pay' ? $t("pay the installment") : $t("save delay") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import GenericInput from '@/components/ui/GenericInput.vue'
export default {
    name: "ManegeInstallmentDialog",
    data: () => ({
        form: {
            amount: null,
        },
        date: null,
        loanId: null,
        valid: false,
        isLoading: false,
        endPoint: ""
    }),
    methods: {
        save() {
            if (this.rowInstallmentData.key == 'pay') {
                this.form.payment_date = this.date;
                this.endPoint = 'employee_installment_paid'
            } else {
                this.form.deduction_date = this.date;
                this.endPoint = 'employee_installment_delay'
            }
            this.loansTransactionDetails.length > 1 ? this.loanId = this.$route.params.id : this.loanId = this.rowID
            // delete this.date
            this.isLoading = true;
            this.form._method = 'PUT';
            console.log("form", this.form);
            this.$api.POST_METHOD(`${this.endPoint}/${this.rowInstallmentData.id}?advance_request_id=${this.loanId}`, this.form).then((response) => {
                this.isLoading = false;
                if (response.check) {
                    console.log("response", response);
                    this.closeInstallment();
                    this.callBackMethod(response.data.data);
                    this.$refs.form.reset()
                }
            })
        },
        handelHeader() {
            if (this.rowInstallmentData.key == 'pay') {
                return `${this.rowInstallmentData.id}- ${this.$i18n.t("installment payment")} ${this.rowInstallmentData.deduction_date}`
            } else {
                return `${this.rowInstallmentData.id}- ${this.$i18n.t("Installment delay")} ${this.rowInstallmentData.deduction_date}`
            }
        }
    },
    watch: {
        rowInstallmentData() {
            if (Object.keys(this.rowInstallmentData).length > 0) {
                this.date = this.rowInstallmentData.key == 'pay' ? this.$global.GetCurrentDate() : this.rowInstallmentData.deduction_date
                this.form.amount = this.rowInstallmentData.amount
            }
        },
    },
    props: {
        openInstallment: { default: false },
        closeInstallment: { type: Function },
        callBackMethod: { type: Function },
        callGetData: { type: Function },
        rowInstallmentData: { default: Object },
        loansTransactionDetails: { default: Array },
        rowID: { type: Number }
    },
    components: { GenericInput }
}
</script>

<style lang="scss" scoped></style>
