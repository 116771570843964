<template>
    <section>
        <v-container fluid>

            <!-- Header -->
            <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" :showDeleted="false"
                :showSearch="false" :createBtn="false" />

            <!-- End Of Header -->

            <v-form ref="form" v-model="valid" class="py-3 mt-5">
                <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel class="blue11 rounded-xl">
                        <v-expansion-panel-header class="my-n2" expand-icon="mdi-menu-down" />
                        <v-expansion-panel-content>
                            <v-card class="overflow-hidden  transparent" elevation="0">

                                <!-- Inputs Row -->
                                <v-row class="align-center">

                                    <v-col cols="12">
                                        <p class="pa-1 px-4 ma-0 gray9--text font-weight-bold">
                                            {{ $t("filter") | capitalize }}
                                        </p>
                                    </v-col>

                                    <v-col cols="10" class="d-flex align-center">
                                        <GenericInput type="select" :lookups="filterCompany" :value="company_id"
                                            @input="company_id = $event" icon="mdi-chevron-down" selected_prop="id"
                                            label="company" :clearable="true" selected_label="name"
                                            :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]">
                                        </GenericInput>

                                        <GenericInput type="select" :lookups="filterManagement" :value="management_id"
                                            :clearable="true" @input="management_id = $event" icon="mdi-chevron-down"
                                            selected_prop="id" label="the management" selected_label="name"
                                            :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]">
                                        </GenericInput>

                                        <GenericInput type="select" :lookups="filterDepartment" :value="department_id"
                                            :clearable="true" @input="department_id = $event" icon="mdi-chevron-down"
                                            selected_prop="id" label="department" selected_label="name"
                                            :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]">
                                        </GenericInput>

                                        <GenericInput type="select" :lookups="filterEmployees" :value="employee_id"
                                            :clearable="true" :required="true" @input="employee_id = $event"
                                            label="choose the employee" selected_prop="id" selected_label="employee_name"
                                            :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]">
                                            <template v-slot:append>
                                                <v-icon>mdi-magnify</v-icon>
                                            </template>
                                        </GenericInput>
                                    </v-col>

                                    <!-- Search Btn -->
                                    <v-col cols="12" md="auto" lg="auto" class="pt-2">
                                        <v-btn @click="search" height="34" width="100" color="primary" class="white--text"
                                            elevation="0" :loading="pageData.isLoading">
                                            {{ $t("search") }}
                                        </v-btn>
                                    </v-col>

                                </v-row>
                                <!-- End Inputs Row -->
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-form>

            <!-- Animation -->
            <AnimatedLoading classes="mt-16" :hideText="false" :isLoading="pageData.isLoading" />

            <v-card class="mt-5 pa-5" elevation="2" v-if="!pageData.isLoading && Object.keys(employeeData).length">
                <v-row class="text-capitalize" justify="space-between">

                    <!-- Avatar -->
                    <v-col cols="12" md="2" lg="2" class="d-flex align-center justify-center">
                        <v-avatar size="130" class="employeeAvatar rounded-circle ma-5">
                            <LazyImage :src="$api.serverUrl + employeeData.image"
                                :error="require('@/assets/img/svg/default_profile.svg')"
                                :loading="require('@/assets/img/png/files/loading.gif')" />
                        </v-avatar>
                    </v-col>

                    <!-- Profile Details -->
                    <v-col cols="12" md="6" lg="6">
                        <v-card-text>
                            <div class="text-h8 "> <span>{{ employeeData.id }}</span> {{ employeeData.job_name }}</div>
                            <div class="text-h6 font-weight-bold mt-1">
                                {{ arabicAndEnglishName }}
                            </div>
                            <div class="text-h8 mt-1"> <span class="font-weight-bold">{{ $t('company') }}</span> :
                                <span>{{ employeeData.company_name }}</span>
                            </div>
                            <div class="text-h8 mt-1"> <span class="font-weight-bold">{{ $t('the management') }}</span> :
                                <span>{{ employeeData.management_name }}</span>
                            </div>
                            <div class="text-h8 mt-1"> <span class="font-weight-bold">{{ $t('the department') }}</span> :
                                <span>{{ employeeData.department_name }}</span>
                            </div>
                            <div class="text-h8 mt-1"> <span class="font-weight-bold">{{ $t('fingerprint code') }}</span> :
                                {{ employeeData.finger_print_id }}
                            </div>
                            <div class="text-h8 mt-1"> <span class="font-weight-bold">{{ $t('working since') }}</span> :
                                {{ employeeData.hiring_date }}
                            </div>
                        </v-card-text>
                    </v-col>

                    <!-- Payroll Calculation -->
                    <v-col cols="12" md="4" lg="4" class="mt-9">
                        <div class="text-h6 text-center mb-6">
                            <span class="font-weight-bold">{{ $t('payroll calculation') }}</span>
                        </div>

                        <div class="d-flex">
                            <GenericInput type="select" :value="month" @input="month = $event" icon="mdi-chevron-down"
                                label="" :lookups="accounting_month" selected_prop="accounting_month"
                                selected_label="accounting_month" :isLoading="pageData.editIsLoading" :cols="[6, 6, 6]">
                            </GenericInput>

                            <v-btn height="34" width="130" @click="printPdf" color="primary" class="white--text mt-1 mr-3"
                                :loading="pageData.isLoadingPdf" elevation="0">
                                {{ $t("print") }}
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>


                <!-- <v-row class="mt-10">
                    <v-col cols="12">
                        <SalaryReportTable :employeeData="employeeData" />
                    </v-col>
                </v-row> -->

                <!-- Details  -->
                <!-- <v-card class="pa-5 mt-3 table text-capitalize" width="100%" color="gray12" elevation="0">
                    <v-row class="justify-space-between text-center mx-5 mb-3">
                        <v-col cols="10" class="d-flex justify-space-between">
                            <v-col cols="2">
                                <p class="mb-3">{{ $t("number of allowances") }}</p>
                                <span class="font-weight-bold">{{ employeeDetails.allownces }}</span>
                            </v-col>

                            <v-col cols="auto">
                                <v-divider vertical class="gray8"></v-divider>
                            </v-col>

                            <v-col cols="2">
                                <p class="mb-3">{{ $t("total allowances") }}</p>
                                <span class="success--text font-weight-bold" v-if="employeeDetails.salary.length">
                                    {{ employeeDetails.salary[0].total_benefit_actions | float }}+
                                </span>
                            </v-col>

                            <v-col cols="auto">
                                <v-divider vertical class="gray8"></v-divider>
                            </v-col>

                            <v-col cols="2">
                                <p class="mb-3">{{ $t("days during the month") }}</p>
                                <span class="font-weight-bold">0</span>
                            </v-col>
                            <v-col cols="auto">
                                <v-divider vertical class="gray8"></v-divider>
                            </v-col>

                            <v-col cols="2">
                                <p class="mb-3">{{ $t("the number of days off") }}</p>
                                <span class="font-weight-bold">{{ employeeDetails.vacatiopns }}</span>
                            </v-col>

                            <v-col cols="auto">
                                <v-divider vertical class="gray8"></v-divider>
                            </v-col>
                        </v-col>

                        <v-col cols="2" class="pt-5">
                            <p class="mb-3">{{ $t("basic salary") }}</p>
                            <span class="font-weight-bold" v-if="employeeDetails.salary.length">
                                {{ employeeDetails.salary[0].salary_fixed | float }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class="gray8"></v-divider>

                    <v-row class="justify-space-between text-center mx-5 mt-3">
                        <v-col cols="10" class="d-flex justify-space-between">
                            <v-col cols="2">
                                <p class="mb-3">{{ $t("absent days") }}</p>
                                <span class="font-weight-bold">0</span>
                            </v-col>

                            <v-col cols="auto">
                                <v-divider vertical class="gray8"></v-divider>
                            </v-col>

                            <v-col cols="2">
                                <p class="mb-3">{{ $t("permanence") }}</p>
                                <span class="font-weight-bold">{{ employeeDetails.employee.shift_name }}</span>
                            </v-col>

                            <v-col cols="auto">
                                <v-divider vertical class="gray8"></v-divider>
                            </v-col>

                            <v-col cols="2">
                                <p class="mb-3">{{ $t("number of discounts") }}</p>
                                <span class="font-weight-bold">{{ employeeDetails.deductions }}</span>
                            </v-col>
                            <v-col cols="auto">
                                <v-divider vertical class="gray8"></v-divider>
                            </v-col>

                            <v-col cols="2">
                                <p class="mb-3">{{ $t("total discounts") }}</p>
                                <span class="font-weight-bold error--text" v-if="employeeDetails.salary.length">
                                    {{ employeeDetails.salary[0].total_deduction_actions | float }}-
                                </span>

                            </v-col>

                            <v-col cols="auto">
                                <v-divider vertical class="gray8"></v-divider>
                            </v-col>
                        </v-col>
                        <v-col cols="2">
                            <p class="font-weight-bold mb-3">{{ $t("reserved salary") }}</p>
                            <span class="font-weight-bold" v-if="employeeDetails.salary.length">
                                {{ employeeDetails.salary[0].total_amount | float }}
                            </span>
                            <span class="d-block mt-3" style="font-size:13px">{{ employeeDetails.default_month }}</span>
                        </v-col>
                    </v-row>
                </v-card> -->
                <!-- End Details -->
            </v-card>


            <!-- ----------will be remove------------ -->
            <!-- Details  -->
            <v-card class="pa-5 mt-3 table text-capitalize" width="100%" color="gray12" elevation="0"
                v-if="employee_id && !pageData.isLoading">
                <v-row class="text-capitalize" justify="space-between">

                    <!-- Avatar -->
                    <v-col cols="12" md="2" lg="2" class="d-flex align-center justify-center">
                        <v-avatar size="130" class="employeeAvatar rounded-circle ma-5">
                            <LazyImage :src="$api.serverUrl + employeeData.image"
                                :error="require('@/assets/img/svg/default_profile.svg')"
                                :loading="require('@/assets/img/png/files/loading.gif')" />
                        </v-avatar>
                    </v-col>

                    <!-- Profile Details -->
                    <v-col cols="12" md="6" lg="6">
                        <v-card-text>
                            <!-- <div class="text-h8 "> <span>{{ '-' }}</span> {{ '-' }}</div> -->
                            <div class="text-h6 font-weight-bold mt-1">
                                {{ employeeTempName }}
                            </div>
                            <div class="text-h8 mt-1"> <span class="font-weight-bold">{{ $t('company') }}</span> :
                                <span>{{ '-' }}</span>
                            </div>
                            <div class="text-h8 mt-1"> <span class="font-weight-bold">{{ $t('the management') }}</span> :
                                <span>{{ '-' }}</span>
                            </div>
                            <div class="text-h8 mt-1"> <span class="font-weight-bold">{{ $t('the department') }}</span> :
                                <span>{{ '-' }}</span>
                            </div>
                            <div class="text-h8 mt-1"> <span class="font-weight-bold">{{ $t('fingerprint code') }}</span> :
                                {{ '-' }}
                            </div>
                            <div class="text-h8 mt-1"> <span class="font-weight-bold">{{ $t('working since') }}</span> :
                                {{ '-' }}
                            </div>
                        </v-card-text>
                    </v-col>

                    <!-- Payroll Calculation -->
                    <v-col cols="12" md="4" lg="4" class="mt-9">
                        <div class="text-h6 text-center mb-6">
                            <span class="font-weight-bold">{{ $t('payroll calculation') }}</span>
                        </div>

                        <div class="d-flex">
                            <GenericInput type="select" :value="month" @input="month = $event" icon="mdi-chevron-down"
                                label="" :lookups="accounting_month" selected_prop="accounting_month"
                                selected_label="accounting_month" :isLoading="pageData.editIsLoading" :cols="[6, 6, 6]">
                            </GenericInput>

                            <v-btn height="34" width="130" @click="printPdfwillRemove" color="primary" class="white--text mt-1 mr-3"
                                :loading="pageData.isLoadingPdf" elevation="0">
                                {{ $t("print") }}
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="justify-space-between text-center mx-5 mb-3">
                    <v-col cols="10" class="d-flex justify-space-between">
                        <v-col cols="2">
                            <p class="mb-3">{{ $t("number of allowances") }}</p>
                            <span class="font-weight-bold">{{ 0 }}</span>
                        </v-col>

                        <v-col cols="auto">
                            <v-divider vertical class="gray8"></v-divider>
                        </v-col>

                        <v-col cols="2">
                            <p class="mb-3">{{ $t("total allowances") }}</p>
                            <span class="success--text font-weight-bold">
                                {{ 0 }}+
                            </span>
                        </v-col>

                        <v-col cols="auto">
                            <v-divider vertical class="gray8"></v-divider>
                        </v-col>

                        <v-col cols="2">
                            <p class="mb-3">{{ $t("days during the month") }}</p>
                            <span class="font-weight-bold">0</span>
                        </v-col>
                        <v-col cols="auto">
                            <v-divider vertical class="gray8"></v-divider>
                        </v-col>

                        <v-col cols="2">
                            <p class="mb-3">{{ $t("the number of days off") }}</p>
                            <span class="font-weight-bold">{{ 0 }}</span>
                        </v-col>

                        <v-col cols="auto">
                            <v-divider vertical class="gray8"></v-divider>
                        </v-col>
                    </v-col>

                    <v-col cols="2" class="pt-5">
                        <p class="mb-3">{{ $t("basic salary") }}</p>
                        <span class="font-weight-bold">
                            {{ 0 }}</span>
                    </v-col>
                </v-row>
                <v-divider class="gray8"></v-divider>

                <v-row class="justify-space-between text-center mx-5 mt-3">
                    <v-col cols="10" class="d-flex justify-space-between">
                        <v-col cols="2">
                            <p class="mb-3">{{ $t("absent days") }}</p>
                            <span class="font-weight-bold">0</span>
                        </v-col>

                        <v-col cols="auto">
                            <v-divider vertical class="gray8"></v-divider>
                        </v-col>

                        <v-col cols="2">
                            <p class="mb-3">{{ $t("permanence") }}</p>
                            <span class="font-weight-bold">{{ 0 }}</span>
                        </v-col>

                        <v-col cols="auto">
                            <v-divider vertical class="gray8"></v-divider>
                        </v-col>

                        <v-col cols="2">
                            <p class="mb-3">{{ $t("number of discounts") }}</p>
                            <span class="font-weight-bold">{{ 0 }}</span>
                        </v-col>
                        <v-col cols="auto">
                            <v-divider vertical class="gray8"></v-divider>
                        </v-col>

                        <v-col cols="2">
                            <p class="mb-3">{{ $t("total discounts") }}</p>
                            <span class="font-weight-bold error--text">
                                {{ 0 }}-
                            </span>

                        </v-col>

                        <v-col cols="auto">
                            <v-divider vertical class="gray8"></v-divider>
                        </v-col>
                    </v-col>
                    <v-col cols="2">
                        <p class="font-weight-bold mb-3">{{ $t("reserved salary") }}</p>
                        <span class="font-weight-bold">
                            {{ 0 }}
                        </span>
                        <span class="d-block mt-3" style="font-size:13px">{{ 0 }}</span>
                    </v-col>
                </v-row>

                <v-row class="mt-10">
                    <v-col cols="12">
                        <SalaryReportTable :employeeData="employeeData" :employeeTempName="employeeTempName" />
                    </v-col>
                </v-row>
            </v-card>
            <!-- End Details -->
            <!-- ----------will be remove------------ -->


            <!-- <v-row v-if="pageData.rows.length" class="mt-10">
                <v-col cols="6">
                    <div class="text-center font-weight-bold text-h6">{{ $t('benefits details') }}</div>
                    <DynamicTable :isLoading="pageData.isLoading" :data="benefitsRows"
                        :header="pageData.tableHeader_benefits" :option="pageData.options" :pageData="pageData"
                        :footerData="[footerBenefits]" :footer_style="{ 'background-color': 'var(--blue10) !important' }">
                        <template v-slot:td="{ index }">
                            {{ index + 1 }}
                        </template>
                    </DynamicTable>
                </v-col>

                <v-col cols="6">
                    <div class="text-center font-weight-bold text-h6">{{ $t('deductions details') }}</div>
                    <DynamicTable :isLoading="pageData.isLoading" :data="deductionsRows"
                        :header="pageData.tableHeader_deductions" :option="pageData.options" :pageData="pageData"
                        :footerData="[footerDeductions]" :footer_style="{ 'background-color': 'var(--blue10) !important' }">
                        <template v-slot:td="{ index }">
                            {{ index + 1 }}
                        </template>
                    </DynamicTable>
                </v-col>
            </v-row> -->



            <SalaryReportPrint :printAction="() => print = false" :print="print" :printData="printData"
                :printStaticData="printStaticData" :employeeData="employeeData" />

        </v-container>
    </section>
</template>

<script>
import AnimatedLoading from "@/components/ui/AnimatedLoading.vue";
import EntityHeader from "@/components/ui/EntityHeader.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import LazyImage from "@/components/ui/LazyImage.vue";
import SalaryReportPrint from "./SalaryReportPrint.vue";
import SalaryReportTable from "./SalaryReportTable.vue";

export default {
    name: "SalaryReport",

    data: () => ({
        pageData: {
            screen_code: "09-021",
            url: null,
            controlRoute: null,
            entityName: null,
            main: null,
            category: null,
            isLoading: true,
            isLoadingPdf: true,
            isTrashed: 1,
            rowsNumber: 15,
            tableHeader_benefits: [],
            tableHeader_deductions: [],
            search: "",
            rows: [],
            options: {},
            page: 1,
            pagination: {},
            controlDialog: false,
            controlID: null,
            queryParam: '?'
        },
        printData: [],
        printStaticData: Object,
        print: false,
        valid: false,
        panel: [0, 1],
        accounting_month: [],
        employeeDetails: [],
        arabicAndEnglishName: "",
        employeeData: {},
        month: "",
        company: [],
        company_id: "",
        management: [],
        management_id: "",
        department: [],
        department_id: "",
        employees: [],
        employee_id: "",
        benefitsRows: [],
        deductionsRows: [],
        employeeTempName: '',
    }),
    components: {
        EntityHeader,
        GenericInput,
        LazyImage,
        AnimatedLoading,
        SalaryReportTable,
        SalaryReportPrint,
    },
    computed: {
        btnSearchValidation() {
            if (this.company_id !== '' || this.management_id !== '' || this.department_id !== '' || this.employee_id !== '') {
                return false
            } else {
                return true
            }
        },
        filterCompany() {
            if (this.management_id) {
                return this.company.filter((company) =>
                    this.management.filter(management => management.id === this.management_id)
                        .some(management => management.company_id === company.id));
            } else {
                return this.company;
            }
        },
        filterManagement() {
            if (this.company_id) {
                return this.management.filter(management =>
                    this.company.filter(company => company.id === this.company_id)
                        .some(company => company.id === management.company_id));
            } else if (this.department_id) {
                return this.management.filter(management =>
                    this.department.filter(department => department.id === this.department_id)
                        .some(department => department.management_id === management.id));
            } else {
                return this.management
            }

        },
        filterDepartment() {
            if (this.management_id) {
                return this.department.filter(department =>
                    this.management.filter(management => management.id === this.management_id)
                        .some(management => management.id === department.management_id));
            } else {
                return this.department
            }
        },
        filterEmployees() {
            if (this.department_id) {
                return this.employees.filter(employees =>
                    this.department.filter(department => department.id === this.department_id)
                        .some(department => department.id === employees.department_id))
            } else {
                return this.employees
            }
        },
        footerBenefits() {
            return {
                name: this.$i18n.t("total number of benefits"),
                amount: this.benefitsRows.reduce((prev, current) => prev + +current.amount, 0).toFixed(2),
            }
        },
        footerDeductions() {
            return {
                name: this.$i18n.t("total number of deductions"),
                amount: this.deductionsRows.reduce((prev, current) => prev + +current.amount, 0).toFixed(2),
            }
        }
    },
    watch: {
        $route() {
            this.pageData.queryParam = '?';
            this.queryParams();
            this.pageMainData();
            this.getData();
        },
        employee_id() {
            if (this.employee_id) {
                this.search()
            }
        },

    },
    mounted() {
        this.queryParams();
        this.pageMainData();
        this.tableHeader()
        this.getData();
    },
    methods: {
        queryParams() {
            this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
            this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
            this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
            this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
            this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
        },
        pageMainData() {
            this.pageData.main = this.$store.getters.activeScreen.main_title || null;
            this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
            this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
            this.pageData.url = this.$store.getters.activeScreen.url || null;
            this.pageData.options = {
                print: this.$global.CheckAction(this.pageData.screen_code, 5),
                delete: this.$global.CheckAction(this.pageData.screen_code, 4),
                edit: this.$global.CheckAction(this.pageData.screen_code, 3),
                restore: this.$global.CheckAction(this.pageData.screen_code, 4),
                view: this.$global.CheckAction(this.pageData.screen_code, 1),
                switch: false,
                selectRow: false,
                sortBy: "id",
                tableHeader: false,
                searchInput: false,
                printButton: false,
                handleClickRow: false,
            };
        },
        getSearch(word) {
            this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
            this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
        },
        printPdf() {
            if (this.month) {
                this.pageData.isLoadingPdf = true;
                this.$api.POST_METHOD(`salary_report/${this.employee_id || ''}`, { month: this.month }).then((response) => {
                    if (response.check) {
                        console.log('response', response);
                        this.pageData.isLoadingPdf = false;
                        this.pageData.rows = response.data.salary;
                        this.benefitsRows = response.data.salary.filter(filter => filter.benefits_type !== ' ').map(benefitsRow => ({
                            id: benefitsRow.id,
                            name: benefitsRow.benefits_type,
                            amount: benefitsRow.benefits_amount
                        }))
                        this.deductionsRows = response.data.salary.filter(filter => filter.deductions_type !== ' ').map(deductionsRow => ({
                            id: deductionsRow.id,
                            name: deductionsRow.deductions_type,
                            amount: deductionsRow.deductions_amount
                        }))
                        console.log(this.benefitsRows, this.deductionsRows);
                        this.printStaticData.master = response.data;
                        this.printData = [...response.data.salary];
                        // this.printStaticData.details = [];
                        // this.printStaticData.details.push(...response.data.salary);
                        this.print = true;
                        setTimeout(() => {
                            this.printData = [];
                            this.printStaticData = Object;
                            this.print = false;
                        }, 1000);
                    }
                })
            }

        },
        printPdfwillRemove() {
            this.printStaticData.master ={};
            this.printData = [];
            this.print = true;
            setTimeout(() => {
                this.printData = [];
                this.printStaticData = Object;
                this.print = false;
            }, 1000);
        },
        search() {
            this.$refs.form.validate()
            if (this.valid || this.employee_id) {
                this.pageData.isLoading = true;
                this.$api.POST_METHOD(`salary_report/${this.employee_id || ''}`,null,false).then((response) => {
                    if (response.check) {
                        this.pageData.isLoading = false;
                        this.pageData.isLoadingPdf = false;
                        this.employeeData = response.data.employee;
                        this.employeeDetails = response.data;
                        this.accounting_month = response.data.accounting_month;
                        this.month = this.employeeDetails.default_month
                        this.printPdf()
                        this.arabicAndEnglishName = `${this.employeeData.employee_name_ar} ${this.employeeData.family_name_ar} - ${this.employeeData.employee_name_en} ${this.employeeData.family_name_en}`
                    } else {
                        // will remove
                        this.pageData.isLoadingPdf = false;
                        // will remove
                        this.employeeTempName = this.$global.FilterArrayOfObjectElement(this.filterEmployees, "id", this.employee_id, 'employee_name')

                        this.pageData.isLoading = false;
                    }
                })
            }

        },
        tableHeader() {
            this.pageData.tableHeader_benefits = [
                { text: "#", key: "id", type: 'slot', classes: "" },
                { text: "benefits", key: "name", type: 'text', classes: "" },
                { text: "the amount", key: "amount", type: 'float', classes: "" },
            ];
            this.pageData.tableHeader_deductions = [
                { text: "#", key: "id", type: 'slot', classes: "" },
                { text: "deductions", key: "name", type: 'text', classes: "" },
                { text: "the amount", key: "amount", type: 'float', classes: "" },
            ];
        },
        getData() {
            if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
                this.pageData.isLoading = false
                this.$api.GET_METHOD(`salary_report/create`).then((response) => {
                    if (response.check) {
                        this.company = response.data.company
                        this.management = response.data.managment
                        this.department = response.data.department
                        this.employees = response.data.employees
                    }
                })
            } else {
                this.$router.push('/')
            }

        },

    },
};
</script>

<style lang="scss" scoped>
.custom_shadow {
    box-shadow: 0px 0px 25px rgba(44, 48, 53, 0.06);
}

.table p {
    font-size: 13px !important;
}

@media (max-width:992px) {
    .search_input {
        margin-top: -10px
    }
}

@media (max-width:1200px) {
    .userAvatar {
        height: 90px !important;
        min-width: 90px !important;
        width: 90px !important;
        transition: all .5s ease-in-out;
    }

    .table p {
        font-size: 12px !important;
        margin-right: -20px !important;
    }


}

.container_table2 {
    thead td {
        background-color: #dcdcdc !important;

    }

    tr:nth-child(even) {
        background-color: white;
    }

    header .container {
        display: flex;
        justify-content: space-between;
        padding: 5px 25px;
        align-items: center;
        font-size: 10px;
        color: #354c72;
    }

    .bak_effect {
        background-color: #dcdcdc;
    }

    .border_bottom {
        // border-bottom: 2px solid black;
    }

    .sub_container1,
    .container_table1 {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: flex-start;
        margin-bottom: 15px;
        column-gap: 50px;
        align-items: center;
    }

    .table1 {
        width: 90%;
        margin: 0;
        border-radius: 0;
    }

    .table_sub2 {
        margin: 5px 0;
        border-radius: 0;
    }

    .table_sub3 {
        width: 60%;
        margin: 0;
        border-radius: 0;
    }

    .table_sub4 {
        width: 50%;
        margin: 0;
        border-radius: 0;
    }

    .table_sub5 {
        border-radius: 0;
    }

    /* .container_table2 {
    min-height: 600px;
    border-radius: 0;
} */
    table.table_sub1 {
        border: 2px solid;
    }

    .container_table3 table,
    .container_table2 {
        border: 2px solid black !important;
        border-radius: 0;
    }

    .container_table3 {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        column-gap: 20px;
    }


    .container_table2 .table_sub1 tbody tr:nth-child(1),
    .container_table2 .table_sub5 tbody tr:nth-child(3) td:nth-child(2),
    .container_table2 .table_sub5 tbody tr:nth-child(2) td:last-child,
    .container_table2 .table_sub5 tbody tr:nth-child(1),
    .container_table2 .sub_container1 .table_sub4 tbody tr td:nth-child(2),
    .container_table3 table tbody tr:nth-child(1) {
        background-color: #dcdcdc;
    }

    .gray1,
    .container_table1 .table1 tbody tr:nth-child(1) td:nth-child(even) {
        background-color: #f5f5f5;
    }

    /* .container_table3 tr,
.container_table2 tr,
.container_table3 td,
.container_table2 td {
    border: 1px solid;
    width: 0%;
} */

    /* html{
    direction: ltr ;
} */


    section.title .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin: 20px 0;
        font-size: 11px;
    }

    section.title .container .date {
        font-size: 17px;
        color: #354c72;
        font-weight: bold;
    }

    table {
        text-align: center;
        margin: auto;
        width: 100%;
        border-spacing: 0;
        overflow: hidden;
    }


    td {
        padding: 5px;
        border: 1px solid #8080804a;
    }

    .row {
        display: flex;
        justify-content: flex-start;
        margin: 15px 0;
    }

    .transaction_date .container {
        display: flex;
        justify-content: space-around;
        padding: 13px 0;
        margin: 28px 0;
        background: #f9f9f9;
    }

    .balance {
        border: 1px solid #8080804a;
        text-align: center;
        width: 206px;
        border-radius: 7px;
        padding: 15px 0;
        color: red;
    }

    .balance span {
        margin: 0 15px;
    }

    hr {
        background: #8080804a;
    }


    tfoot tr td {
        border-top: 2px solid;
        font-weight: bold;
        padding: 0px;
    }

    .divider {
        background: #354c72;
        width: 100%;
        height: 2px;
        margin-bottom: 3px;
    }

    footer {
        background-color: #dcdcdc;
    }

    footer div {
        display: flex;
        justify-content: space-between;
    }

    footer span {
        margin: 0px 8px;
    }

    #secondTable tr:nth-child(2) td,
    #secondTable tr:nth-child(1) td {
        border-bottom: 1px solid #8080804a;
    }



    @media print {

        html,
        body {
            width: 210mm;
            height: 297mm;
        }

        footer {
            position: absolute;
            bottom: 0;
            width: 100%;
        }

        /* td {
        min-width: 90px !important;
    } */
    }
}
</style>