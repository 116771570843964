<template>
   <div>
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
         :paginate-elements-by-height="$store.state.Settings.windowSize.x" :filename="'multi_voucher'" :pdf-quality="3"
         :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait"
         :pdf-content-width="$store.state.Settings.windowSize.y" ref="html2Pdf">
         <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">
            <PrintPDF :header="tableHeader" :maxPDFRows="10" :data="printData" :header_slot="false" :content_slot="false"
               :footer_slot="false" :showGroupHeader="false" :groupHeader="false" :footerData="[]"
               :customize_header_footer="false" :printStaticData="Object">
               <template v-slot:pdf_content="{ page }">

                  <div id="title" v-if="page == 1" style="text-align:center">
                     ({{ $t('loans') }} )
                  </div>

                  <div class="order_number">
                     {{ $t('order number') }} : {{ printStaticData.master.id }}
                  </div>

                  <table width="100%" id="LoanTransactionPrint_table">
                     <tr>
                        <td>{{ $t('employee name') }} </td>
                        <td colspan="3">{{ printStaticData.master.employee_name || ' ' }} </td>
                     </tr>
                     <tr>
                        <td>{{ $t('Date') }} </td>
                        <td colspan="3">{{ printStaticData.master.add_date || ' ' }} </td>
                     </tr>
                     <tr>
                        <td>{{ $t('loan amount') }} </td>
                        <td>{{ printStaticData.master.amount | float }} </td>
                        <td>{{ $t('previously deducted') }} </td>
                        <td>{{ printStaticData.master.previously_deducted || ' ' }} </td>
                     </tr>
                     <tr>
                        <td>{{ $t('monthly deduction') }} </td>
                        <td>{{ printStaticData.master.monthly_deducted | float }} </td>
                        <td>{{ $t('number of installments') }} </td>
                        <td>{{ printStaticData.master.installments_count || ' ' }} </td>
                     </tr>
                     <tr>
                        <td>{{ $t('loan start') }} </td>
                        <td>{{ printStaticData.master.starting_loan || ' ' }} </td>
                        <td>{{ $t('calculation start') }} </td>
                        <td>{{ printStaticData.master.start_date || ' ' }} </td>
                     </tr>
                     <tr>
                        <td>{{ $t('calculation end') }} </td>
                        <td colspan="3">{{ printStaticData.master.end_date || ' ' }} </td>
                     </tr>
                  </table>
                  <div class="LoanTransactionPrint_amount">
                     {{ printStaticData.master.amount || '' | tafqeet }}
                  </div>

                  <div >{{ $t('loans details') }}</div>
                  <table width="100%" >
                     <tr>
                        <td>{{ $t('serial') }} </td>
                        <td>{{ $t('Installment due date') }} </td>
                        <td>{{ $t('value of the installment') }} </td>
                        <td>{{ $t('discount date') }} </td>
                        <td>{{ $t('postponement number') }} </td>
                     </tr>

                     <tr v-for="loan in  printStaticData.details" :key="loan.id">
                        <td>{{ loan.id }} </td>
                        <td>{{ loan.deduction_date }} </td>
                        <td>{{ loan.amount }} </td>
                        <td>{{ loan.payment_date }} </td>
                        <td>{{ loan.delay_count }} </td>
                     </tr>
                  </table>
               </template>
            </PrintPDF>
         </section>
      </vue-html2pdf>


   </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
export default {
   name: "LoanTransactionPrint",
   props: {
      printAction: { type: Function },
      print: { default: false },
      printData: { default: [] },
      printStaticData: { default: Object },
   },
   computed: {

   },
   components: {
      VueHtml2pdf,
   },
   watch: {
      print() {
         if (this.print) {
            this.$refs.html2Pdf.generatePdf();
         }
      },
   },
   data: () => ({
      tableHeader: [],
   }),
   methods: {

   },
   mounted() {

   },

};
</script>

<style lang="scss" >
#LoanTransactionPrint_table {
   margin: 50px 0;
   -webkit-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
   -moz-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
   box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
   border-collapse: collapse;
   border: 1px solid #e9e8ee;
   border-bottom: 8px solid #28aae1;
   border-right: 8px solid #28aae1;

   tr {
      border-bottom: 1px solid #000 !important;

      td {
         padding: 5px !important;
         font-weight: 600;
         border-bottom: 1px solid #ddd !important;

         &:nth-of-type(odd) {
            color: #666 !important;
            background: #e6ecff !important;
            text-align: center !important;
            border-left: 0 !important;
            width: 25%;
            padding: 5px 10px !important;
         }

         &:nth-of-type(even) {
            color: #333 !important;
            background: #f8f9fd !important;
            //border-left: 1px solid #000 !important;
            text-align: center !important;
         }

         &:last-of-type {
            border-left: 0 !important;
         }
      }

      &:last-of-type {
         border-bottom: 0 !important;

         td {
            border-bottom: 0 !important;
         }
      }

   }
}

.order_number {
   font-size: 12px !important;
}

.LoanTransactionPrint_amount {
   margin-bottom: 40px !important;
}
</style>