// Codes Routes:-

import AdminStructureEntity from "@/views/HRModule/Codes/AdminStructure/AdminStructureEntity.vue";

import CompanyEntity from "@/views/HRModule/Codes/Company/CompanyEntity.vue";
import CompanyControl from "@/views/HRModule/Codes/Company/CompanyControl.vue";

import QualificationyEntity from "@/views/HRModule/Codes/Qualification/QualificationyEntity.vue";

import JobsEntity from "@/views/HRModule/Codes/Jobs/JobsEntity.vue";

import EmployeesControl from "@/views/HRModule/Codes/Employees/EmployeesControl.vue";
import EmployeesEntity from "@/views/HRModule/Codes/Employees/EmployeesEntity.vue";

import ShiftEntity from "@/views/HRModule/Codes/Shift/ShiftEntity.vue";

import VacationControl from "@/views/HRModule/Codes/Vacation/VacationControl.vue";
import VacationEntity from "@/views/HRModule/Codes/Vacation/VacationEntity.vue";

import AllowancesEntity from "@/views/HRModule/Codes/Allowances/AllowancesEntity.vue";

import DeductionEntity from "@/views/HRModule/Codes/Deduction/DeductionEntity.vue";

import BenefitCodesEntity from "@/views/HRModule/Codes/BenefitCodes/BenefitCodesEntity.vue";

import ContractsControl from "@/views/HRModule/Codes/Contracts/ContractsControl.vue";
import ContractsEntity from "@/views/HRModule/Codes/Contracts/ContractsEntity.vue";

import FingerPrintControl from "@/views/HRModule/Codes/FingerPrint/FingerPrintControl.vue";
import FingerPrintEntity from "@/views/HRModule/Codes/FingerPrint/FingerPrintEntity.vue";

// Routes
export const CodesRoutes = [
  // Admin Structure Entity
  {
    path: "/codes/admin_structure",
    name: "AdminStructureEntity",
    component: AdminStructureEntity,
    meta: {
      screen_code: "09-001",
    },
  },


  //  Company
  {
    path: "/codes/company",
    name: "CompanyEntity",
    component: CompanyEntity,
    meta: {
      screen_code: "09-022",
    },
  },
  {
    path: "/codes/company-control",
    name: "CompanyControlAdd",
    component: CompanyControl,
    meta: {
      screen_code: "09-022",
    },
  },
  {
    path: "/codes/company-control/:id",
    name: "CompanyControlEdit",
    component: CompanyControl,
    meta: {
      screen_code: "09-022",
    },
  },

  //  Qualify
  {
    path: "/codes/qualification",
    name: "QualificationyEntity",
    component: QualificationyEntity,
    meta: {
      screen_code: "09-002",
    },
  },

  //  Jobs
  {
    path: "/codes/job",
    name: "JobsEntity",
    component: JobsEntity,
    meta: {
      screen_code: "09-003",
    },
  },

  //  Employees
  {
    path: "/codes/employee",
    name: "EmployeesEntity",
    component: EmployeesEntity,
    meta: {
      screen_code: "09-004",
    },
  },
  {
    path: "/codes/employee-control",
    name: "EmployeesControlAdd",
    component: EmployeesControl,
    meta: {
      screen_code: "09-004",
    },
  },
  {
    path: "/codes/employee-control/:id",
    name: "EmployeesControlEdit",
    component: EmployeesControl,
    meta: {
      screen_code: "09-004",
    },
  },
  // Vacation
  {
    path: "/codes/vacation",
    name: "VacationEntity",
    component: VacationEntity,
    meta: {
      screen_code: "09-006",
    },
  },
  {
    path: "/codes/vacation-control",
    name: "VacationControlAdd",
    component: VacationControl,
    meta: {
      screen_code: "09-006",
    },
  },
  {
    path: "/codes/vacation-control/:id",
    name: "VacationControlEdit",
    component: VacationControl,
    meta: {
      screen_code: "09-006",
    },
  },

  //  Shift
  {
    path: "/codes/shift",
    name: "ShiftEntity",
    component: ShiftEntity,
    meta: {
      screen_code: "09-005",
    },
  },

  //  allowances
  {
    path: "/codes/allowance",
    name: "AllowancesEntity",
    component: AllowancesEntity,
    meta: {
      screen_code: "09-007",
    },
  },

  //  Deduction
  {
    path: "/codes/deduction",
    name: "DeductionEntity",
    component: DeductionEntity,
    meta: {
      screen_code: "09-008",
    },
  },

  //  Intitlement
  {
    path: "/codes/benefit",
    name: "BenefitCodesEntity",
    component: BenefitCodesEntity,
    meta: {
      screen_code: "09-009",
    },
  },
  //  Contracts
  {
    path: "/codes/contract",
    name: "ContractsEntity",
    component: ContractsEntity,
    meta: {
      screen_code: "09-023",
    },
  },
  {
    path: "/codes/contract-control",
    name: "ContractsControlAdd",
    component: ContractsControl,
    meta: {
      screen_code: "09-023",
    },
  },
  {
    path: "/codes/contract-control/:id",
    name: "ContractsControlEdit",
    component: ContractsControl,
    meta: {
      screen_code: "09-023",
    },
  },
  //  FingerPrint
  {
    path: "/codes/fingerprint",
    name: "FingerPrintEntity",
    component: FingerPrintEntity,
    meta: {
      screen_code: "09-024",
    },
  },
  {
    path: "/codes/fingerprint-control",
    name: "FingerPrintlAdd",
    component: FingerPrintControl,
    meta: {
      screen_code: "09-024",
    },
  },
  {
    path: "/codes/fingerprint-control/:id",
    name: "FingerPrintlEdit",
    component: FingerPrintControl,
    meta: {
      screen_code: "09-024",
    },
  },
];