

// HR Module Routes:-

import MainPageVue from "@/views/HRModule/MainPage/MainPage.vue";
import Codes from "@/views/HRModule/Codes/Codes.vue";
import Transactions from "@/views/HRModule/Transactions/Transactions.vue";
import Requests from "@/views/HRModule/Requests/Requests.vue";
import Reports from "@/views/HRModule/Reports/Reports.vue";

// Child's
import { CodesRoutes } from "@/router/CodesRoutes";
import { TransactionsRoutes } from "@/router/TransactionsRoutes";
import { RequestsRoutes } from "@/router/RequestsRoutes";
import { ReportsRoutes } from "@/router/ReportsRoutes";
import SettingsModule from "../views/SettingsModule/SettingsModule.vue";
import SettingsHrModule from "../views/HRModule/SettingsHR/SettingsHrModule.vue";
import { SettingsModuleChildrens } from "./SettingsModuleRoutes";
import { SettingsHrModuleRoutes } from "./SettingsHrModuleRoutes";


export const HRModuleRoutes = [
  {
    path: "/settings",
    name: "SettingsModule",
    component: SettingsModule,
    children: [...SettingsModuleChildrens],
  },
  {
    path: "/settings_hr",
    name: "SettingsHrModule",
    component: SettingsHrModule,
    children: [...SettingsHrModuleRoutes],
  },
  {
    path: "/",
    name: "MainPage",
    component: MainPageVue,
    meta: {
      title: "home",
    },
  },
  {
    path: "/codes",
    name: "Codes",
    component: Codes,
    children: [...CodesRoutes],
    meta: {
      title: "codes",
    },
  },

  // Request
  {
    path: "/requests",
    name: "Requests",
    component: Requests,
    children: [...RequestsRoutes],
    meta: {
      title: "codes",
    },
  },

  {
    path: "/transactions",
    name: "Transactions",
    component: Transactions,
    children: [...TransactionsRoutes],
    meta: {
      title: "Transactions",
    },
  },



  // reports
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    children: [...ReportsRoutes],
    meta: {
      title: "reports",
    },
  },
];
