<template>
   <section>
      <v-container fluid>


         <!-- Header -->
         <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" actionBtnText="sort"
            :actionBtnClick="openSort" :actionBtn="true" :actionBtnValid="true" actionBtnColor="white"
            actionBtnClass="secondary--text" actionBtnIcon="mdi-sort-numeric-ascending" />
         <!-- End Of Header -->

         <v-row>
            <v-col cols="12" sm="12">
               <DataTableVue :isLoading="pageData.isLoading" :options="pageData.options" :data="pageData.rows"
                  :header="pageData.tableHeader" :backValueMethod="setClickRow" :editValueMethod="edit"
                  :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod" :pageData="pageData">
               </DataTableVue>
            </v-col>
         </v-row>
         <v-row v-if="!pageData.isLoading">
            <v-col cols="12" sm="12">
               <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
                  :isLoading="pageData.isLoading"></Pagination>
            </v-col>
         </v-row>

         <MainMenuRearrangementDialog v-if="pageData.rearrangementDialog == true" :dialog="pageData.rearrangementDialog"
            :backValueMethod="rearrangement" :isLoading="pageData.isLoading"></MainMenuRearrangementDialog>
      </v-container>
   </section>
</template>



<script>
import DataTableVue from "@/components/DataTable.vue";
import Pagination from "@/components/ui/Pagination.vue";
import MainMenuRearrangementDialog from "./MainMenuRearrangementDialog.vue";
import EntityHeader from "@/components/ui/EntityHeader.vue";
export default {
   name: "MainMenuEntity",

   data: () => ({
      pageData: {
         screen_code: "01-001",
         url: null,
         controlRoute: "main/screen_main-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isTrashed: 1,
         rowsNumber: 15,
         tableHeader: [],
         search: '',
         rows: [],
         options: {},
         page: 1,
         pagination: {},
         rearrangementDialog: false,
      },
   }),
   components: {
      DataTableVue,
      Pagination,
      MainMenuRearrangementDialog,
      EntityHeader
   },
   computed: {

   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.entityName = this.$store.state.activeScreen.sub_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.tableHeader = [
            {
               text: '#',
               sortable: true,
               value: 'id',
            },
            {
               text: this.$i18n.t('image'),
               sortable: true,
               value: 'image',
            },
            {
               text: this.$i18n.t('title'),
               sortable: true,
               value: 'title',
            },

            { text: this.$i18n.t('actions'), value: 'actions', sortable: false },

         ]
         this.pageData.options = {
            print: this.$global.CheckAction(this.pageData.screen_code, 5),
            delete: this.$global.CheckAction(this.pageData.screen_code, 4),
            edit: this.$global.CheckAction(this.pageData.screen_code, 3),
            restore: this.$global.CheckAction(this.pageData.screen_code, 4),
            view: this.$global.CheckAction(this.pageData.screen_code, 1),
            switch: false,
            selectRow: false,
            sortBy: 'id',
            tableHeader: false,
            searchInput: false,
            printButton: false,
            handleClickRow: false,
         }
      },
      getData() {
         if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
            this.pageData.isLoading = true;
            this.$api.GET_METHOD(`screen_main?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search}`).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  this.pageData.entityName = this.pageData.isTrashed == 0 ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title : this.$store.state.activeScreen.sub_title;
                  this.pageData.rows = response.data.items.data;
                  this.pageData.page = response.data.items.current_page;
                  this.pageData.pagination = {
                     page: response.data.items.current_page,
                     totalPages: response.data.items.last_page,
                     per_page: response.data.items.per_page,
                     totalRows: response.data.items.total,
                  }

               }
            })

         }
         else {
            this.$router.push('/')
         }

      },
      changePage(page, limit) {
         this.pageData.page = page;
         this.pageData.rowsNumber = limit;
         this.getData()
      },
      deleteMethod(id) {
         this.$api.POST_METHOD(`screen_main/${id}`, { _method: 'delete' }).then((response) => {
            if (response.check) {
               this.getData()
            }
         })
      },
      restoreMethod(row) {
         this.$api.POST_METHOD(`screen_main_toggle_active/${row.id}`, null).then((response) => {
            if (response.check) {
               this.getData()
            }
         })
      },
      setClickRow() {
         // this.$router.push(`/main/network_machine/${row.id}`)
      },
      openSort() {
         this.pageData.rearrangementDialog = true
      },
      getSearch(word) {
         this.pageData.search = word ? word : "";
         this.getData();
      },
      edit(row) {
         this.$router.push(`/${this.pageData.controlRoute}/` + row.id)
      },
      rearrangement(newDataOrder, status) {


         if (status == true) {
            console.log("rearrangement data", newDataOrder);
            this.$api.POST_METHOD(`screen_main_sort`, { items: newDataOrder }).then((response) => {
               if (response.check) {
                  this.getData()
                  this.pageData.rearrangementDialog = false
               }
            })
         } else {
            this.pageData.rearrangementDialog = false
         }

      }
   },
};
</script>
