<template>
    <section>
        <v-container fluid>
            <v-card class="mt-2 shadow-none">
                <v-row>

                    <v-col cols="12" sm="4" class="">
                        <div class="pt-8  text-start font-weight-bold ">
                            {{ $t("salary increase requests") | capitalize }} </div>
                    </v-col>

                    <v-col cols="12" sm="6" class="mt-7">
                        <v-tabs class="custom_tab" center-active v-model="status">
                            <div class="tab-parent d-flex">

                                <v-tab class="tab-child font-weight-bold gray2--text ">
                                    <strong>{{ $t('current orders') }}</strong>
                                </v-tab>

                                <v-tab class="tab-child font-weight-bold gray2--text">
                                    <strong>{{ $t('previous orders') }}</strong>
                                </v-tab>
                            </div>
                        </v-tabs>
                    </v-col>

                    <GenericInput class="mt-9" type="select" :lookups="selectState" :value="selectState_id"
                        @input="selectState_id = $event" label="" :multi="false" :required="false" selected_label="name"
                        selected_prop="id" :isLoading="false" :cols="[2, 2, 2]" icon="mdi-chevron-down"
                        v-if="status == 1" />
                </v-row>

                <v-row v-if="status == 0" @click="dialogData('', 11)">
                    <v-col cols="12" sm="12">
                        <DynamicTable class="shadow-none" :isLoading="pageData.isLoading" :data="pageData.rows"
                            :header="pageData.tableHeader" :option="pageData.options" :pageData="pageData"
                            :rowClicked="rowClicked">
                            <template v-slot:th="{ header }">
                                {{ $t(header.text) }}
                            </template>

                            <template v-slot:td="{ row, header, index }">

                                <div v-if="header.key == 'printSlot'">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" x-small v-on="on" :loading="row.printLoading"
                                                @click.stop="printMethod(row, index, 'increase request')" icon>
                                                <img src="@/assets/img/svg_icons/fi_printer.svg" height="18" />
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('print') }}</span>
                                    </v-tooltip>
                                </div>

                                <div v-if="header.key == 'attachments'">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" x-small v-on="on" :loading="row.printLoading"
                                                @click.stop="openDialogAttach = true, rowDialogData = row" icon>
                                                <img src="@/assets/img/svg_icons/attach.svg" height="18" />
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('attachments') }}</span>
                                    </v-tooltip>
                                </div>

                                <div v-if="header.key == 'employee_name'">
                                    <a @click="profileId(row)" class="text-decoration-underline" text color="info">
                                        {{ row.employee_name }}
                                    </a>
                                </div>

                                <div v-if="header.key == 'slot_key'" class="d-flex" style="cursor: default;">
                                    <div class="body-2 ml-5 mr-5  font-weight-bold" style="cursor: default; color:#E6C84C"
                                        v-if="row.status == 1">
                                        {{ $t('pending') }}
                                    </div>
                                </div>
                            </template>
                        </DynamicTable>


                    </v-col>
                </v-row>

                <v-row v-if="status == 1" @click="dialogData('', 12)">
                    <v-col cols="12" sm="12">
                        <DynamicTable class="shadow-none" :isLoading="pageData.isLoading" :data="pageData.rows"
                            :header="pageData.tableHeader" :option="pageData.options" :pageData="pageData"
                            :rowClicked="() => rowClickedDialogInfo">
                            <template v-slot:th="{ header }">
                                {{ $t(header.text) }}
                            </template>

                            <template v-slot:td="{ row, header, index }">
                                <div v-if="header.key == 'printSlot'">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" x-small v-on="on" :loading="row.printLoading"
                                                @click.stop="printMethod(row, index, 'increase request')" icon>
                                                <img src="@/assets/img/svg_icons/fi_printer.svg" height="18" />
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('print') }}</span>
                                    </v-tooltip>
                                </div>

                                <div v-if="header.key == 'attachments'">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" x-small v-on="on" :loading="row.printLoading"
                                                @click.stop="openDialogAttach = true, rowDialogData = row" icon>
                                                <img src="@/assets/img/svg_icons/attach.svg" height="18" />
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('attachments') }}</span>
                                    </v-tooltip>
                                </div>

                                <div v-if="header.key == 'employee_name'">
                                    <a @click="profileId(row)" class="text-decoration-underline" text color="info">
                                        {{ row.employee_name }}
                                    </a>
                                </div>

                                <div v-if="header.key == 'slot_key'" class="d-flex" style="cursor: default;">

                                    <div class="body-2 ml-5 mr-5 green5--text font-weight-bold" style="cursor: default;"
                                        v-if="row.status == 2">{{ $t('approved') }}
                                    </div>

                                    <div class="body-2 ml-5 mr-5  red5--text font-weight-bold" v-if="row.status == 3"
                                        style="cursor: default;">
                                        {{ $t('Rejected') }}
                                    </div>

                                    <span v-if="row.status == 3" @click="rowClickedDialogInfo(row)">
                                        <img src="@/assets/img/svg/reason.svg" alt="" style="cursor: pointer;">

                                    </span>

                                </div>
                            </template>
                        </DynamicTable>
                    </v-col>
                </v-row>

            </v-card>

            <!-- Pagination -->
            <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
                <v-col class="mt-5" cols="12" sm="12" style="position: absolute;">
                    <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
                        :isLoading="pageData.isLoading"></Pagination>
                </v-col>
            </v-row>

            <DialogInfo :openDialogInfo="openDialogInfo" :closeDialog="() => openDialogInfo = false"
                :rowDialogData="rowDialogData" />

            <AgreeDialog :openDialogAction="openDialogAction" :closeDialogAction="() => openDialogAction = false"
                :dialog_id="dialog_id" :tab="tab" :status="status" :rowDialogData="rowDialogData"
                :backValueMethod="getData" />

            <FollowRequestPrint :printAction="() => print = false" :print="print" :printData="printData"
                :printStaticData="printStaticData" />

            <FollowRequestAttach :openDialogAttach="openDialogAttach" :closeDialog="() => openDialogAttach = false"
                :rowDialogData="rowDialogData" :request_type="request_type" :callBackMethod="() => getData()" />

        </v-container>
    </section>
</template>
  
<script>

import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import AgreeDialog from "./AgreeDialog.vue";
import DialogInfo from "./DialogInfo.vue";
import Pagination from "@/components/ui/Pagination.vue";
import FollowRequestPrint from "./FollowRequestPrint.vue";
import FollowRequestAttach from "./FollowRequestAttach.vue";

export default {
    name: "SalaryIncreaseToggle",

    data: () => ({
        pageData: {
            screen_code: "09-011",
            url: null,
            entityName: null,
            main: null,
            category: null,
            isLoading: true,
            isTrashed: 1,
            printLoading: {
                loading: false,
                id: null,
                key: 'id'
            },
            rowsNumber: 15,
            tableHeader: [],
            search: "",
            rows: [],
            options: {},
            page: 1,
            pagination: {},
            queryParam: new URLSearchParams(),
        },
        printData: [],
        printStaticData: Object,
        print: false,
        rowDialogData: {},
        selectState: [],
        selectState_id: 1,
        tab: null,
        status: null,
        dialog_title: "",
        dialog_id: 0,
        openDialogAction: false,
        openDialogInfo: false,
        openDialogAttach: false,
    }),
    props: {
        request_type: { default: Number },
    },
    components: {
        DynamicTable,
        GenericInput,
        AgreeDialog,
        DialogInfo,
        Pagination,
        FollowRequestPrint,
        FollowRequestAttach
    },
    computed: {},
    mounted() {
        this.queryParams();
        this.pageMainData();
        this.getData();
    },

    watch: {
        $route() {
            this.queryParams();
            this.pageMainData();
            this.getData();
        },

        selectState_id() {
            !this.pageData.isLoading && this.getData();
        },
        status() {
            switch (this.status) {
                // current orders
                case 0:
                    this.selectState_id = 1;
                    break;
                // previous orders

                case 1:
                    // Select accepted and rejected request
                    this.selectState_id = 0
                    this.pageData.tableHeader = [
                        { text: "serial", key: "id", type: 'text', classes: "" },
                        { text: "date of request", key: "add_date", type: 'date', classes: "" },
                        { text: "employee name", key: "employee_name", type: 'slot', classes: "" },
                        { text: "status", key: "slot_key", type: 'slot', classes: "cursor_pointer" },
                        { text: "attachments", key: "attachments", type: 'slot', classes: "" },
                        { text: "actions", key: "printSlot", type: 'slot', classes: "" },
                    ];
                    break;
            }
            !this.pageData.isLoading && this.getData();
        }
    },
    methods: {
        queryParams() {
            this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
            this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
            this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
            this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
            this.pageData.queryParam = new URLSearchParams(this.$store.state.activeScreen.requestParam || '');
        },
        pageMainData() {
            this.pageData.main = this.$global.FilterPermissions(
                this.pageData.screen_code
            ).main_title;
            this.pageData.category = this.$global.FilterPermissions(
                this.pageData.screen_code
            ).cat_title;
            this.pageData.entityName = this.$global.FilterPermissions(
                this.pageData.screen_code
            ).sub_title;
            this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;

            this.pageData.options = {
                print: this.$global.CheckAction(this.pageData.screen_code, 5),
                delete: this.$global.CheckAction(this.pageData.screen_code, 4),
                edit: this.$global.CheckAction(this.pageData.screen_code, 3),
                restore: this.$global.CheckAction(this.pageData.screen_code, 4),
                view: this.$global.CheckAction(this.pageData.screen_code, 1),
                switch: false,
                selectRow: false,
                sortBy: "id",
                tableHeader: false,
                searchInput: false,
                printButton: false,
                handleClickRow: false,
            };

            this.pageData.tableHeader = [
                { text: "serial", key: "id", type: 'text', classes: "cursor_pointer" },
                { text: "date of request", key: "add_date", type: 'date', classes: "cursor_pointer" },
                { text: "employee name", key: "employee_name", type: 'slot', classes: "cursor_pointer" },
                { text: "status", key: "slot_key", type: 'slot', classes: "cursor_pointer" },
                { text: "attachments", key: "attachments", type: 'slot', classes: "" },
                { text: "actions", key: "printSlot", type: 'slot', classes: "" },
            ];
        },

        getSearch(word) {
            this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
            this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString()).catch(() => { });
        },
        getData() {
            if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
                this.pageData.isLoading = true;
                this.pageData.queryParam.set("request_type", this.tab = 3 || 0);
                this.pageData.queryParam.delete("status");
                this.pageData.queryParam.delete("status[]");
                if (this.selectState_id == 0) {
                    this.pageData.queryParam.set("status[]", 2);
                    this.pageData.queryParam.append("status[]", 3);
                } else {
                    this.pageData.queryParam.append("status", this.selectState_id);
                }
                this.$api.GET_METHOD(`follow_request?${this.pageData.queryParam.toString()}`)
                    .then((response) => {
                        this.pageData.isLoading = false;
                        if (response.check) {
                            this.pageData.entityName =
                                this.pageData.isTrashed == 0
                                    ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                                    : this.$store.state.activeScreen.sub_title;
                            this.pageData.rows = response.data.items.data;
                            this.pageData.page = response.data.items.current_page;
                            this.pageData.pagination = {
                                page: response.data.items.current_page,
                                totalPages: response.data.items.last_page,
                                per_page: response.data.items.per_page,
                                totalRows: response.data.items.total,
                            };

                        }
                    });


            } else {
                this.$router.push("/");
            }
            this.selectState = [
                {
                    id: 0,
                    name: this.$i18n.t('view all')
                },
                {
                    id: 2,
                    name: this.$i18n.t('accepted requests')
                },
                {
                    id: 3,
                    name: this.$i18n.t('rejected requests')
                },
            ]
        },
        changePage(page, limit) {
            // this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
            // this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
            this.pageData.queryParam.set("page", page || 1);
            this.pageData.queryParam.set("rows", limit || 15)
            this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString()).catch(() => { });
        },
        rowClicked(row) {
            console.log('rowClicked', row)
            this.openDialogAction = true
            this.rowDialogData = row
        },
        rowClickedDialogInfo(row) {
            console.log('rowClickedDialogInfo', row)
            this.openDialogInfo = true
            this.rowDialogData = row

        },
        setClickRow() {
            // this.$router.push(`/main/shift/${row.id}`)
        },
        profileId(row) {
            console.log("profileId",);
            this.$router.push(`/codes/employee-control/${row.employee_id}`);
            // this.$router.push(`/codes/employee-control/` + data.id);
        },
        dialogData(param, id) {
            this.dialog_title = param
            this.dialog_id = id
            this.openDialog = true
        },
        printMethod(row, index, requestName) {
            row.printLoading = true
            row.request_name = requestName
            this.printData = [this.pageData.rows];
            this.printStaticData.master = row;
            this.printStaticData.contract = row;
            this.print = true;
            setTimeout(() => {
                this.pageData.rows[index].printLoading = false
                this.pageData.rows = [...this.pageData.rows]
                this.printData = [];
                this.printStaticData = Object;
                this.print = false;
            }, 1000);
        },
    }
};
</script>
  
<style lang="scss" scoped>
// Custom Tab
.tab-parent {
    background: #ECEEF0;
    height: 37.8px;
    border-radius: 7px;
    padding: 1.3px;
}

.tab-child {
    // background: #FFF;
    // border: 0.5px solid rgba(0, 0, 0, 0.04);
    // border-radius: 7px;
    background: #FFFFFF;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0);
    border-radius: 7px;
    height: 34px;
    z-index: 1;

}


.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active) {
    background: transparent;
    border-radius: 0px;
    border: 0px solid transparent;
    z-index: 0;


}

.theme--light.v-tabs>.v-tabs-bar .v-tab--active {

    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);

}

// Custom Tab
</style>