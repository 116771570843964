<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid"
            :deleteMethod="deleteMethod" :actionBtn="false" actionBtnClass="light text-decoration-underline blue5--text"
            :actionBtnClick="() => attachmentDialog = true" :actionBtnValid="true" actionBtnText="attachments"
            :editForm="pageData.isEdit && !viewForm" :viewForm="() => viewForm = true">
         </ControlHeader>
         <!-- End Of Header -->
         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading"
            v-if="pageData.isEdit && !viewForm">
         </ControlSummery>
         <!-- End of Summery -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3" v-if="viewForm">
            <v-card class="transparent  overflow-hidden py-5" elevation="0">
               <v-row class="align-center">

                  <GenericInput v-if="!pageData.isEdit" type="date"
                     :value="form.current_date = $global.GetCurrentDate()" @input="form.current_date = $event" label="date"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]" :disabled="true">
                  </GenericInput>

                  <GenericInput type="select" :value="form.employee_id" @input="form.employee_id = $event"
                     label="choose the employee" :lookups="employee"
                     selected_label="employee_name" selected_prop="id" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 4]">
                     <template v-slot:append>
                        <v-icon>mdi-magnify</v-icon>
                     </template>
                  </GenericInput>

                  <GenericInput type="select" :value="form.salary_effect_id" @input="form.salary_effect_id = $event"
                     icon="mdi-chevron-down" label="deduction type" :lookups="deduction_type" selected_label="name"
                     selected_prop="id" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="select" :value="form.value_type" @input="form.value_type = $event"
                     icon="mdi-chevron-down" label="discount type" :lookups="value_type" selected_label="name"
                     selected_prop="id" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="number" :value="form.value" @input="form.value = $event" label="value"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="date" :value="form.action_month" @input="form.action_month = $event"
                     label="date of starting" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="textarea" :value="form.notes" @input="form.notes = $event" label="details"
                     :rowsNumber="4" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                  </GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlSummery from "@/components/ui/ControlSummery.vue";

export default {
   name: "PublicHolidaysControl",

   data: () => ({
      pageData: {
         screen_code: "01-007",
         url: null,
         controlRoute: "/settings_hr/public_holidays-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      viewForm: false,
      summery: [],
      valid: false,
      tab: 0,
      purchases_vendor_types: [],
      openDialog: false,
      attachmentDialog: false,
      employee: [],
      deduction_type: [],
      value_type: [],
      employee_profile_id: "",
      form: {
         value_type: null,
         current_date: null,
         employee_id: null,
         action_month: null,
         salary_effect_id: null,
         notes: null,
         value: null,
      },
   }),
   components: {
      GenericInput,
      ControlHeader,
      ControlSummery,
   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.pageData.isLoading = false;
      this.viewForm = true;
      // this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id ) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`deduction_actions/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);

                     this.summery = [
                        {
                           label: 'date',
                           value: response.data.data.add_date || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: null, xl: null,
                        },
                        {
                           label: 'value',
                           value: response.data.data.value || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: null, xl: null,
                        },
                        {
                           label: 'details',
                           value: response.data.data.notes || '-',
                           class: '',
                           cols: 12, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'execution date of the deduction',
                           value: response.data.data.action_month || '-',
                           class: '',
                           cols: 12, sm: null, md: null, lg: 7, xl: null,
                        },


                     ]
                     this.viewSummery = true;
                     // this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }


         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`deduction_actions/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.employee = response.data.employees;
                     this.deduction_type = response.data.deductions;

                  }
               })
            } else {
               this.$router.push('/')
            }

         }

         this.value_type = [

            {
               id: 1,
               name: this.$i18n.t('hour')
            },
            {
               id: 2,
               name: this.$i18n.t('work days')
            },
            {
               id: 3,
               name: this.$i18n.t('value')
            },

         ]

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`deduction_actions/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`deduction_actions`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`deduction_actions/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
   props: {
   },
   watch: {
   }
};
</script>

<style lang="scss" scoped>
div#divider_ver {
   height: 200px !important;
}
</style>
