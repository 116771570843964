<template>
    <section>
        <v-container fluid>
            <!-- Header -->
            <EntityHeader :pageData="pageData" :createBtn="false" :showSearch="false" :getData="getData"
                actionBtnText="re search" :showDeleted="false" :actionBtn="!showFilter" :actionBtnValid="true"
                actionBtnColor="transparent" :actionBtnClick="() => { showFilter = true }" :actionBtnOutline="true"
                actionBtnClass="primary--text" />
            <!-- End Of Header -->

            <!-- Filter -->
            <v-row justify="center" v-if="showFilter" class="mt-1">
                <v-col cols="12" class="mt-1 pb-0">
                    <v-card class="shadow py-5 px-4">
                        <v-row align="center" justify="space-between" class="">

                            <GenericInput type="date" :clearable="true" :value="start_date" @input="start_date = $event"
                                label="from" :required="false" :hide-details="true" :isLoading="pageData.isLoading"
                                :cols="[12, 6, 3]">
                            </GenericInput>

                            <GenericInput type="date" :clearable="true" :value="end_date" :minDate="start_date"
                                @input="end_date = $event" label="to" :required="false" :hide-details="true"
                                :isLoading="pageData.isLoading" :cols="[12, 6, 3]">
                            </GenericInput>

                            <GenericInput type="select" :clearable="true" :lookups="employees" :value="employee_id"
                                @input="employee_id = $event" label="employee name" :multi="false" :required="false"
                                selected_label="employee_name" selected_prop="id" :hide-details="true"
                                :isLoading="pageData.isLoading" :cols="[12, 6, 3]">
                            </GenericInput>

                            <v-col cols="auto" md="6" lg="3" class="d-flex justify-end">
                                <v-btn depressed color="error" class="mx-1" v-if="pageData.rows.length > 0"
                                    @click="showFilter = false" text>
                                    <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                                </v-btn>
                                <v-btn depressed width="120" color="blue4" outlined :disabled="!employee_id"
                                    :loading="pageData.isLoading" @click="getSearch(), showFilter = false"
                                    class="mx-1 white--text">
                                    {{ $t("Search") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" sm="12">
                    <v-col cols="12" class="pa-0" v-if="pageData.rows.length">
                        <v-card class="elevation-0 justify-center my-2 pa-0 overflow-hidden">
                            <DynamicTable :isLoading="pageData.isLoading" deleteProperty="id" :data="pageData.rows"
                                :header="pageData.tableHeader" :option="pageData.options" :pageData="pageData">
                            </DynamicTable>
                        </v-card>
                    </v-col>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>
  
<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
export default {
    name: "EmployeeStatement",

    data: () => ({
        pageData: {
            screen_code: "09-025",
            url: null,
            controlRoute: "contract/employee_statement-control",
            entityName: null,
            main: null,
            category: null,
            isLoading: true,
            detailIsLoading: false,
            isTrashed: 1,
            rowsNumber: 15,
            tableHeader: [],
            search: "",
            rows: [],
            options: {},
            page: 1,
            pagination: {},
            queryParam: '?'
        },
        customers: [],
        employees: [],
        start_date: '',
        end_date: '',
        employee_id: '',
        showFilter: true,
        totalAmount: 0,
    }),
    components: {
        EntityHeader,
        DynamicTable,
        GenericInput,
    },
    watch: {
        $route() {
            this.queryParams();
            this.pageMainData();
            this.getData();
        },
        employee_id() {
            if (this.employee_id) {
                this.pageData.rows = []
                this.showFilter = false
                this.getSearch();
            } else {
                this.pageData.rows = []
            }

        }
    },
    computed: {

    },
    mounted() {
        this.queryParams();
        this.pageMainData();
        this.start_date = this.$global.GetFirstDayOfMonth();
        this.end_date = this.$global.GetCurrentDate();
        this.getData();
    },
    methods: {
        queryParams() {
            this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
            this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
            this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
            this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
            this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
        },
        pageMainData() {
            this.pageData.main = this.$store.getters.activeScreen.main_title || null;
            this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
            this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
            this.pageData.url = this.$store.getters.activeScreen.url || null;
            this.pageData.tableHeader = [
                { text: "movement number", key: "id", type: 'text', classes: "" },
                { text: "document date", key: "action_month", type: 'date', classes: "" },
                { text: "movement type", key: "type", type: 'text', classes: "" },
                { text: "Statement", key: "notes", type: 'text', classes: "" },
                { text: "debit", key: "debit", type: 'non_zero_float', classes: "" },
                { text: "credit", key: "credit", type: 'non_zero_float', classes: "" },
                { text: "balance", key: "total", type: 'non_zero_float', classes: "" },
            ];
            this.pageData.options = {
                print: this.$global.CheckAction(this.pageData.screen_code, 5),
                delete: this.$global.CheckAction(this.pageData.screen_code, 4),
                edit: this.$global.CheckAction(this.pageData.screen_code, 3),
                restore: this.$global.CheckAction(this.pageData.screen_code, 4),
                view: this.$global.CheckAction(this.pageData.screen_code, 1),
                switch: false,
                selectRow: false,
                sortBy: "id",
                tableHeader: false,
                searchInput: false,
                printButton: false,
                handleClickRow: false,
            };
        },
        getSearch() {
            this.pageData.isLoading = true;
            this.$api.POST_METHOD(`employee_statement/${this.employee_id || ""}`, { from: this.start_date, to: this.end_date }).then((response) => {
                this.pageData.isLoading = false;
                if (response.check) {
                    // add massage [رصيد اول المدة]
                    response.data.first[0] = {
                        id: this.$i18n.t("the balance of the first duration"),
                        ...response.data.first[0]
                    }
                    // calculation
                    response.data.actions.forEach((element, index) => {
                        element.debit = +element.debit
                        element.credit = +element.credit
                        if (index === 0) {
                            this.totalAmount = response.data.first[0].total
                            element.total = (Number(element.debit) + Number(this.totalAmount)) - Number(element.credit)
                            this.totalAmount = element.total
                        } else {
                            element.total = (Number(element.debit) + Number(this.totalAmount)) - Number(element.credit)
                            this.totalAmount = element.total
                        }
                    });
                    this.pageData.rows = response.data.actions;
                    this.pageData.rows.splice(0, 0, response.data.first[0]);
                }
            });
        },
        getData() {
            console.log("2");
            if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
                this.pageData.isLoading = true;
                this.$api.GET_METHOD(`employee_statement`).then((response) => {
                    this.pageData.isLoading = false;
                    if (response.check) {
                        this.employees = response.data.employees;
                    }
                });
            } else {
                this.$router.push("/");
            }
        },
        changePage(page, limit) {
            this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
            this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
        },
    },
};
</script>
  