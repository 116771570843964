<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader v-if="!openProfileForm" :pageData="pageData" :form="form" :save="save" :valid="valid"
            :deleteMethod="deleteMethod" actionBtnClass="light text-decoration-underline blue5--text"
            :actionBtnValid="true" actionBtnText="attachments" :editForm="pageData.isEdit && !viewForm"
            :viewForm="() => viewForm = true">
            <template v-slot:leftSide>
               <v-btn @click="printMethod" v-if="pageData.isEdit" :loading="pageData.printLoading.loading" color="primary"
                  depressed height="40">{{
                     $t('print') }}</v-btn>
            </template>
         </ControlHeader>
         <!-- End Of Header -->

         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading"
            v-if="pageData.isEdit && !viewForm && !openProfileForm">
            <template v-slot:summerySlot="{ item }">
               <div v-if="item.key === 'allowance_type'">
                  <span class="font-weight-bold">{{ $t(item.label) }}:</span>
                  {{ $global.FilterArrayOfObjectElement(allowance_type, "id", item.value, 'name') }} 
               </div>
            </template>
         </ControlSummery>
         <!-- End of Summery -->

         <!-- Form -->
         <v-spacer class="mb-5" v-if="!openProfileForm"></v-spacer>
         <v-form ref="form" v-model="valid" class="pa-1 mt-5" v-if="viewForm || openProfileForm">
            <v-card class="transparent  overflow-hidden py-3 px-1" elevation="0">
               <v-row>
                  <GenericInput type="date" v-if="!pageData.isEdit || openProfileForm"
                     :value="current_date = $global.GetCurrentDate()" @input="current_date = $event" label="created_at"
                     :required="true" :disabled="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="select" v-if="!openProfileForm" :value="form.employee_id"
                     @input="form.employee_id = $event" label="choose the employee" :lookups="employees"
                     selected_label="employee_name" :required="true" selected_prop="id" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 4]">
                     <template v-slot:append>
                        <v-icon>mdi-magnify</v-icon>
                     </template>
                  </GenericInput>

                  <!-- Date -->
                  <GenericInput type="date" datePickerType="month" :value="form.date" @input="form.date = $event"
                     label="accounting month" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]" />
                  <!-- Date -->

                  <GenericInput type="select" :value="form.allowance_id" @input="form.allowance_id = $event"
                     icon="mdi-chevron-down" label="allowance name" selected_prop="id" :lookups="allowances"
                     selected_label="name" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="!pageData.isEdit ? [12, 6, 4] : [12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="select" :value="form.one_time" @input="form.one_time = $event"
                     icon="mdi-chevron-down" selected_prop="id" label="allowance type" :lookups="allowance_type"
                     selected_label="name" :required="false" :isLoading="pageData.editIsLoading"
                     :cols="openProfileForm ? [12, 6, 4] : [12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="number" :value="form.amount" @input="form.amount = $event" label="the amount"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="textarea" :value="form.notes" @input="form.notes = $event" label="details"
                     :rowsNumber="4" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 12]">
                  </GenericInput>

               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>

      <AllowancesTransactionPrint :printAction="() => print = false" :print="print" :printData="printData"
         :printStaticData="printStaticData" />
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlSummery from "@/components/ui/ControlSummery.vue";
import AllowancesTransactionPrint from "./AllowancesTransactionPrint.vue";
export default {
   name: "AllowancesTransactionsControl",

   data: () => ({
      pageData: {
         screen_code: "09-017",
         url: null,
         controlRoute: "/transactions/allowance_transaction-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
         printLoading: {
            loading: false,
            id: null,
            key: 'id'
         },
      },
      viewForm: false,
      summery: [],
      allowance_type: [],
      valid: false,
      menuDate: false,
      menu: false,
      modal: false,
      employees: [],
      allowances: [],
      employee_profile_id: "",
      current_date: null,
      form: {
         amount: null,
         one_time: 1,
         allowance_id: null,
         notes: null,
         employee_id: null,
         date: null,
      },
      rules: {
         required: value => !!value || 'Required.',
      },
      printData: [],
      printStaticData: Object,
      print: false,
      printResponse: {},
   }),
   components: {
      GenericInput,
      ControlHeader,
      ControlSummery,
      AllowancesTransactionPrint
   },
   computed: {


   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id && !this.openProfileForm) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`allowance_actions/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.printResponse = response.data;
                     this.allowances = response.data.allowances;
                     this.employees = response.data.employees;

                     // this.form = response.data.data;
                     !this.openProfileForm ? this.form = response.data.data : ""

                     if (this.openProfileForm) {
                        this.form.employee_id = +this.$route.params.id
                     }
                     this.summery = [
                        {
                           label: 'employee name',
                           value: response.data.data.employee_name || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'date',
                           value: response.data.data.add_date || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'the amount',
                           value: response.data.data.amount || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'allowance type',
                           value: response.data.data.one_time,
                           type: 'slot',
                           key: 'allowance_type',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'allowance name',
                           value: response.data.data.allowance_name || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'details',
                           value: response.data.data.notes || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },


                     ]
                     this.viewSummery = true;
                     // this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`allowance_actions/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.allowances = response.data.allowances;
                     this.employees = response.data.employees;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

         this.allowance_type = [
            {
               id: 0,
               name: this.$i18n.t('monthly')
            },
            {
               id: 1,
               name: this.$i18n.t('one time')
            },
         ]

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`allowance_actions/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`allowance_actions`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`allowance_actions/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      printMethod() {
         this.pageData.printLoading.loading = true;
         this.pageData.printLoading.id = this.$route.params.id;
         this.printData = [this.printResponse.data];
         this.printStaticData.master = this.printResponse.data;
         this.print = true;
         setTimeout(() => {
            this.printData = [];
            this.printStaticData = Object;
            this.print = false;
            this.pageData.printLoading.loading = false;
         }, 1000);
      },
   },
   watch: {
      valid() {
         if (this.openProfileForm) {
            return this.saveEmployeeData(this.form, this.valid)
         }
      }
   },
   props: {
      openProfileForm: { default: false },
      saveEmployeeData: { type: Function }
   }
};
</script>

<style lang="scss" scoped>
div#divider_ver {
   height: 200px !important;
}
</style>
