<template>
  <v-container fluid class="overflow-hidden backgroundW ma-auto ma-md-0 ">
    <v-row class="align-center">
      <v-col cols="12" md="6" lg="5" xl="4" class="px-5 px-md-15">
        <v-form ref="form" class="px-5 " v-model="valid" lazy-validation>
          <div class="d-block d-md-none">
            <img class="d-block ma-auto pb-5" src="@/assets/img/svg/logo.png" height="110" alt="">
          </div>
          <h1>{{$t("Login Page") | capitalize}}</h1>
          <!-- username -->
          <GenericInput type="text" classes="border-0"  name="username" paddingY="py-3" icon="mdi-account" :keydownEnter="login" :dense="false"
            :solo="false" :value="form.username" background="#f8f8f8" @input="form.username = $event" label="username" :required="true"
            :isLoading="false" :cols="[12, 12, 12]"></GenericInput>
          <!-- password -->
          <GenericInput type="password" classes="border-0"  name="password" paddingY="py-3" :keydownEnter="login" :solo="false" :dense="false"
            :value="form.password" background="#f8f8f8" @input="form.password = $event" label="password" :required="true" :isLoading="false"
            :cols="[12, 12, 12]">
          </GenericInput>
          <v-row class="mb-7 px-5">
            <!-- <v-col cols="12" class="d-flex justify-end py-3">
              <v-btn depressed :height="40" width="150" text plain to="/forget-password" color="primary">
                {{ $t('forget password') }} {{ $t('?') }}
              </v-btn>
            </v-col> -->
            <v-col class="pa-1 mt-3">
              <v-btn depressed :height="50" width="100%" :disabled="!(valid)" :loading="loading" @click="login"
                color="primary">{{ $t('login') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mx-4"></v-divider>
          <div class="lang text-center mt-10 ">
            <v-btn  outlined color="primary" width="234" @click="changeLanguage">
              <img class="mx-2" v-if="$vuetify.rtl" src="@/assets/img/svg/USA.svg" alt=""> 
              <img class="mx-2" v-else src="@/assets/img/svg/KSA.svg" alt="">
              <div>
                {{ $vuetify.rtl  ? $t("english") : $t("arabic") }}
              </div>
            </v-btn>

          </div>
        </v-form>
      </v-col>

      <v-col class="pa-0 d-none d-md-block" cols="12" md="6" lg="7" xl="8">
        <v-row class="auth_bg pa-5 align-center align-content-center">
          <v-col cols="12">
            <!-- <img class="d-block ma-auto" src="@/assets/img/png/MacBook.png" /> -->
          </v-col>
          <v-col cols="12" class="mt-12">
            <div class="text-h4 white--text text-center font-weight-bold">{{$t('amwaj for Human Resources')}}</div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
import GenericInput from '@/components/ui/GenericInput.vue';

export default {
  name: "LoginPage",
  computed: {},
  watch: {
    'form.company_number'() {
      this.$store.commit('UPDATE_CLIENT', this.form.company_number || null)
    },
  },
  mounted() {
    this.$api.LOGOUT(false);
    this.$store.commit('UPDATE_CLIENT', "amwajhr")
  },
  components: {
    GenericInput
  },
  data: () => ({
    valid: false,
    loading: false,
    form: {
      email: null,
      password: null,
      company_number: null,
    }
  }),

  methods: {
    login() {
      this.loading = true;
      this.$refs.form.validate()
      if (this.valid == true && this.$store.state.clientID) {
        this.$api.LOGIN_AUTH(this.form).then((response) => {
          this.loading = false;
          if (response.check == true) {
            this.$router.push('/');
            localStorage.setItem("clientID", this.$store.state.clientID)
          }
        })
      }
      else {
        this.loading = false;
      }
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
  },
};
</script>

<style scoped lang="scss">
.auth_bg {
  background: url("../../assets/img/png/auth_bg.png");
  height: 103.4vh;
  background-size: cover;

  img {
    max-height: 358px;
    max-width: 100%;
  }
}

.lang {
  img {
    height: 17px;
  }
}
</style>