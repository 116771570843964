<template>
  <div>
    <div class="image-input" :style="`width:${width}px;height:${height}px;`" @click="chooseImage">
      <div class="image-container">
        <!-- default image -->
        <img v-if="!img && !image_is_changed" :src="defaultImag" />
        <!-- Live Image -->
        <img v-if="img" :src="image_is_changed || isBase64(img) ? img : $api.serverUrl + img" />
      </div>
      <input class="file-input" ref="fileInput" type="file" @input="image_method">
    </div>
    <p class="mt-5" v-if="showText">{{ $t(text) | capitalize }}</p>
  </div>
</template>


<script>
export default {
  name: "AvatarProfile",
  data: () => ({
    image_is_changed: false,
  }),
  props: {
    width: { default: 112 },
    height: { default: 112 },
    showText: { default: true },
    text: { default: 'personal picture' },
    img: { default: null },
    defaultImag: { default: require('@/assets/img/svg/profileBig.svg') },
    callBackMethod: { type: Function }
  },
  computed: {
    isBase64() {
      return (data)=> data.substring(0, 4) === 'data'
    }
  },
  methods: {
    image_method() {
      this.image_is_changed = true;
      const input = this.$refs.fileInput
      const files = input.files
      if (files && files[0]) {
        const reader = new FileReader
        reader.onload = e => {
          this.callBackMethod(e.target.result, this.image_is_changed)
        }
        reader.readAsDataURL(files[0])
        this.$emit('input', files[0])
      }
    },
    chooseImage() {
      this.$refs.fileInput.click()
    },

  }


};
</script>



<style scoped lang="scss">
.image-input div img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.image-input {
  display: block;
  cursor: pointer;
  border-radius: 50%;
  background-size: 100% 100%;
  background-position: center center;
  margin: auto;
  z-index: 1;
}

.image-container {
  width: 100%;
  height: 100%;
}

.file-input {
  display: none
}
</style>
