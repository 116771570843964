<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid && !is_paid && form.details.length"
            createBtnText='calculation' :editForm="false" :showDeleted="false" :viewForm="() => viewForm = true" />
         <!-- End Of Header -->

         <AnimatedLoading :isLoading="first_loading" :height="100" class="mt-16" />

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="py-3 mt-6" v-if="!first_loading">
            <v-expansion-panels v-model="panel" multiple>
               <v-expansion-panel class="blue11 rounded-xl">
                  <v-expansion-panel-header expand-icon="mdi-menu-down" class="text-left">
                     <span>
                        <PrintBtn btnText="" :backValueMethod="downloadFiles" :image="false" classes="transparent" />
                     </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                     <v-card class="overflow-hidden pa-5 transparent" elevation="0">
                        <v-row class="align-center">
                           <v-col cols="7">
                              <v-row class="align-center" >
                                 <v-col cols="auto" class="font-weight-bold">
                                    {{ $t("company") }}: {{ revData.data.company_name }}
                                 </v-col>
                                 <v-col cols="auto" class="font-weight-bold">
                                    {{ $t("department") }}: {{ revData.data.department_name }}</v-col>
                                 <v-col cols="auto" class="font-weight-bold">
                                    {{ $t("value") }}: {{ revData.data.total_amount_master | float }} {{ $t("ryal") }}
                                 </v-col>
                                 <v-col cols="auto" class="font-weight-bold">
                                    {{ $t("management") }}: {{ revData.data.management_name }}
                                 </v-col>
                                 <v-col cols="auto" class="font-weight-bold">
                                    {{ $t("accounting month") }}: {{ revData.data.accounting_month }}
                                 </v-col>
                              </v-row>
                           </v-col>
                           <v-col cols="5">
                              <v-card class="pa-5" width="500" color="green9" elevation="0">
                                 <v-row class="justify-space-between text-center mx-5 ">
                                    <v-col cols="12">
                                       <h3 class="mb-3">
                                          {{ $t("the number") }}: {{ revData.data.employee_count }} {{ $t("employee") }}
                                       </h3>
                                    </v-col>
                                    <v-col cols="auto">
                                       <p class="mb-3">{{ $t("my bank") }}</p>
                                       <span>{{ revData.pay_methods.bank }}</span>
                                    </v-col>
                                    <v-col cols="1">
                                       <v-divider vertical class="white"></v-divider>
                                    </v-col>
                                    <v-col cols="auto">
                                       <p class="mb-3">{{ $t("transactions") }}</p>
                                       <span>{{ revData.pay_methods.transfer }}</span>
                                    </v-col>
                                    <v-col cols="1">
                                       <v-divider vertical class="white"></v-divider>
                                    </v-col>
                                    <v-col cols="auto">
                                       <p class="mb-3">{{ $t("cash money") }}</p>
                                       <span>{{ revData.pay_methods.cach }}</span>
                                    </v-col>
                                 </v-row>
                              </v-card>
                           </v-col>
                        </v-row>
                     </v-card>
                  </v-expansion-panel-content>
               </v-expansion-panel>
            </v-expansion-panels>
         </v-form>

         <v-row v-if="!first_loading">
            <v-col cols="12" sm="12">
               <v-tabs color="secondary" active-class="blue12" v-model="tab" background-color="transparent"
                  class="font-weight-bold mb-n2">
                  <v-tab class="transparent"><strong>{{ $t('all exchange') }}</strong></v-tab>
                  <v-tab class="transparent"><strong>{{ $t('bank exchange employees') }}</strong>
                  </v-tab>
                  <v-tab class="transparent"><strong>{{ $t('cash exchange employees') }}</strong>
                  </v-tab>
                  <v-tab class="transparent"><strong>{{ $t('transactions exchange staff') }}</strong>
                  </v-tab>
               </v-tabs>
               <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
                  :option="pageData.options" :pageData="pageData" :checkbox="!is_paid" :selectRow="!is_paid"
                  :checkboxMethod="checkboxMethod">
                  <template v-slot:td="{ header, row }">
                     <div class="input_container" v-if="header.key === 'payment_method'">
                        <GenericInput v-if="!is_paid" type="select" :value="row.payment_method"
                           @input="row.payment_method = $event" icon="mdi-chevron-down"
                           :label="!is_paid ? 'exchange type' : null" :lookups="payment_method" selected_prop="id"
                           selected_label="name" :disabled="is_paid" :isLoading="pageData.editIsLoading"
                           :cols="[12, 12, 12]">
                        </GenericInput>

                        <div v-else>{{ $global.FilterArrayOfObjectElement(payment_method, "id", row.payment_method, 'name') }}
                        </div>
                     </div>
                  </template>
               </DynamicTable>
            </v-col>
         </v-row>
         <!-- End of Form -->
      </v-container>
   </section>
</template>

<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import PrintBtn from "@/components/ui/PrintBtn.vue";
import AnimatedLoading from "@/components/ui/AnimatedLoading.vue";

export default {
   name: "DisbursementControl",

   data: () => ({
      pageData: {
         screen_code: "09-020",
         url: null,
         rows: [],
         tableHeader: [],
         options: {},
         controlRoute: "/transactions/disbursement_transaction-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      panel: [0, 1],
      viewForm: false,
      summery: [],
      valid: false,
      tab: 0,
      payment_method: [],
      revData: Object,
      is_paid: false,
      first_loading: true,
      form: {
         details: []
      },
   }),
   components: {
      ControlHeader,
      DynamicTable,
      GenericInput,
      PrintBtn,
      AnimatedLoading
   },
   mounted() {
      this.queryParams();
      this.pageMainData();
      this.getData();
      console.log("route", this.$route.params);
   },
   watch: {
      $route() {
         this.pageData.queryParam = '?';
         this.queryParams();
         this.pageMainData();
         this.getData();
      },
      tab() {
         switch (this.tab) {
            case 0:
               this.getData()
               break;
            case 1:
               this.getData(1)
               break;
            case 2:
               this.getData(2)
               break;
            case 3:
               this.getData(3)
               break;
         }

      }
   },
   methods: {
      queryParams() {
         this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
         this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
         this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
         this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
         this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
      },
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).main_title;
         this.pageData.category = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).cat_title;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.tableHeader = [
            { text: "#", key: "id", type: 'text', classes: "" },
            { text: "employee name", key: "employee_name", type: 'text', classes: "" },
            { text: "basic salary", key: "salary_fixed", type: 'float', classes: "" },
            { text: "total deductions", key: "total_deduction_actions", type: 'float', classes: "" },
            { text: "total benefits", key: "total_benefit_actions", type: 'float', classes: "" },
            { text: "the total amount", key: "total_amount", type: 'float', classes: "" },
            { text: "exchange type", key: "payment_method", type: 'slot', classes: "" },
         ];
         this.pageData.options = {
            print: this.$global.CheckAction(this.pageData.screen_code, 5),
            delete: this.$global.CheckAction(this.pageData.screen_code, 4),
            edit: this.$global.CheckAction(this.pageData.screen_code, 3),
            restore: this.$global.CheckAction(this.pageData.screen_code, 4),
            view: this.$global.CheckAction(this.pageData.screen_code, 1),
            switch: false,
            selectRow: false,
            sortBy: "id",
            tableHeader: false,
            searchInput: false,
            printButton: false,
            handleClickRow: false,
         };
      },
      getSearch(word) {
         this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
         this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
      },
      getData(tab_change = "") {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`payment_salary/${this.$route.params.id}?payment_method=${tab_change}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  this.first_loading = false;
                  if (response.check) {
                     console.log("response", response);

                     this.pageData.entityName =
                        this.pageData.isTrashed == 0
                           ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                           : this.$store.state.activeScreen.sub_title;
                     this.pageData.isLoading = false;
                     this.revData = response.data;
                     this.pageData.rows = response.data.details;
                     // this.is_paid = this.pageData.rows.filter(e => e.is_fund === 1).length === this.pageData.rows.length
                     this.is_paid = this.pageData.rows.every(e => e.is_fund === 1)
                     console.log("this.pageData.rows", this.pageData.rows);
                     this.pageData.page = response.data.details.current_page;
                     this.pageData.pagination = {
                        page: response.data.details.current_page,
                        totalPages: response.data.details.last_page,
                        per_page: response.data.details.per_page,
                        totalRows: response.data.details.total,
                     };
                     // this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         this.payment_method = [
            {
               id: 1,
               name: this.$i18n.t('Bank')
            },
            {
               id: 2,
               name: this.$i18n.t('cash')
            },
            {
               id: 3,
               name: this.$i18n.t('the transfer')
            }
         ]
      },
      downloadFiles(param) {
         // this.printLoading = true;
         // const src = `${this.downloadResource}/${this.data[this.dataKey]}`;
         // const download_file_name = `${this.$i18n.t('attach')}_${this.$i18n.t(this.entityName)}_${this.data[this.dataKey]}.${this.data.attach_ext}`
         // this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
         //     this.printLoading = false;
         // })
         console.log(param);
         // this.$api.DOWNLOAD_METHOD_GET(`download_employee_document/${this.$route.params.id}`, { type: fileName }).then(() => {
         //    this.printLoading = false;
         // })
      },
      changePage(page, limit) {
         this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
         this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
      },

      restoreMethod(row) {
         this.$api.POST_METHOD(`purchases_vendor_toggle_active/${row.id}`, null).then((response) => {
            if (response.check) {
               this.getData();
            }
         });
      },
      setClickRow() {
         // this.$router.push(`/main/purchases_vendor/${row.id}`)
      },
      save() {
         this.form.salary_calculator_m_id = +this.$route.params.id
         this.form = { ...this.revData.data, ...this.form }
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               // this.form._method = 'PUT';
               this.$api.POST_METHOD(`payment_salary`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`payment_salary`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      edit(row) {
         console.log("edit", row);
         this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
      },
      checkboxMethod(row) {
         console.log('selected row', row);
         this.form.details = [...row]
      },

   },
};
</script>

<style lang="scss" scoped>
.input_container {
   max-width: 137px;
}
</style>
