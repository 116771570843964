<template>
    <v-dialog v-model="openDialogInfo" persistent max-width="500">
        <v-card>
            <v-card-actions>
                <v-btn absolute class="mt-10" :style="$vuetify.rtl ? 'left: 10px' : 'right: 10px'" small fab
                    color="red5" text @click="closeDialog">
                    <v-icon color="red5">mdi-close</v-icon>
                </v-btn>
            </v-card-actions>

            <v-card-text>
                <v-row class="text-center">
                    <div class="text-h6 pa-4 blue1--text font-weight-bold">{{ $t("the reason for not agreeing") }}</div>
                    <v-col class="pt-5 pb-5" cols="12" v-if="dialog_id !== 11">
                        {{ rowDialogData.refuse_reason }}
                    </v-col>

                    <v-col cols="12" class="ma-auto d-block">
                        <!-- <v-btn color="success" depressed :width="120" :disabled="!valid" @click="save"
                                        class="rounded-lg font-weight-bold white--text " :loading="saveDataLoading">
                                        {{isEdit ? $t('save') : $t('move')}}
                                    </v-btn> -->
                        <v-btn class="error--text" depressed @click="closeDialog">{{ $t("back") }}</v-btn>
                    </v-col>

                </v-row>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
// import GenericInput from '@/components/ui/GenericInput.vue';
export default {
    name: "DialogInfo",
    data() {
        return {

        };
    },

    methods: {

    },
    props: {
        openDialogInfo: { default: false },
        closeDialog: { type: Function },
        rowDialogData: { type: Object },
        dialog_id: { type: Number },
        backValueMethod: { type: Function },
    },
    // components: { GenericInput }
}
</script>

<style lang="scss" scoped>

</style>
